import {Setting} from '@emporos/api-enterprise';
import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  ScrollContainer,
  SegmentSlider,
  Select,
  Stack,
  Text,
  TextInput,
  TextVariant as Variant,
  Illustration,
  Variant as BV,
} from '@emporos/components';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavigateFn} from '@reach/router';
import moment from 'moment';
import {memo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {
  Transaction,
  isPatientPayTransaction,
  round,
  useBetaFeatures,
  useTotals,
} from '../../../../../';
import {EmailForm} from './';
import {useConsoleLogger} from '../../../../../contexts/ConsoleLoggingProvider';
import {mapComplianceIndicators} from '../';

interface Props {
  transaction: Transaction;
  settings: Setting[];
  navigate: NavigateFn;
  onPrint(): void;
  onNoReceipt(): void;
  onEmailReceipt(data: EmailForm): void;
  onBack(onBackLocation: string): void;
}

const schema = yup.object().shape({email: yup.string().email().required()});

const ReceiptsChild = ({
  transaction,
  settings,
  onNoReceipt,
  onPrint,
  onEmailReceipt,
  onBack,
}: Props): JSX.Element => {
  const printingEnabled = useBetaFeatures().printing;
  const availableItems = printingEnabled
    ? ['Email', 'Print', 'None']
    : ['Email', 'None'];
  const EMAIL_INDEX = 0;
  const PRINT_INDEX = printingEnabled ? 1 : -1;
  const [active, setActive] = useState(EMAIL_INDEX);
  const {control, formState, getValues} = useForm<EmailForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {serverTransactionID, saleDate} = transaction;
  const {logError} = useConsoleLogger();

  const {totals} = useTotals();
  const isPtPayTransaction = isPatientPayTransaction(transaction);

  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const showCompliancePage = showCounsel || showHipaa || showRelationship;
  const showPaymentsPage = totals.totalDue != 0;
  const isInBalance = () => {
    const sumAmount = transaction.payments
      .filter(p => p.recordStatus === 'Active')
      .reduce((preValue, currValue) => {
        preValue += currValue.amount - (currValue.amountReturned ?? 0);
        return preValue;
      }, 0);

    return round(sumAmount) == totals.transactionTotal;
  };

  const getOnBackLocation = () => {
    return isPtPayTransaction
      ? showCompliancePage
        ? '../compliance'
        : showPaymentsPage
        ? '../customer-payment'
        : '../../'
      : '../customer-payment';
  };

  const PrintReceipt = () => {
    return (
      <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="Receipt" />
            <Select
              label="Printer"
              options={['Front Desk']}
              value={null}
              onChangeValue={() => logError('Receipt - Printer Unimplemented')}
              style={{width: 372, flex: 0}}
            />
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button
            disabled={!isInBalance()}
            onClick={onPrint}
            variant={BV.Secondary}
            flex
          >
            Print Receipt
          </Button>
        </FooterGroup>
      </Stack>
    );
  };

  const NoReceipt = () => {
    return (
      <Stack
        style={{height: '100%', width: '100%', minHeight: 0}}
        data-testid="no-receipt"
      >
        <ScrollContainer>
          <Stack
            gutter={Gutter.None}
            align="center"
            style={{flex: 1, marginTop: 36}}
          >
            {serverTransactionID && (
              <Stack justify="center" align="center" gutter={Gutter.XS}>
                <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                <Text variant={Variant.Main}>
                  {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                </Text>
              </Stack>
            )}
            <Illustration illustration="TaskNotDone" />
            <Text variant={Variant.T3}>No receipt</Text>
          </Stack>
        </ScrollContainer>
        <FooterGroup>
          <Button
            data-testid="none-back"
            type="button"
            onClick={() => onBack(getOnBackLocation())}
            variant={BV.Secondary}
            flex
          >
            Back
          </Button>
          <Button
            type="button"
            disabled={!isInBalance()}
            onClick={onNoReceipt}
            flex
          >
            Complete Transaction
          </Button>
        </FooterGroup>
      </Stack>
    );
  };
  return (
    <Stack gutter={Gutter.L} align="center" style={{flex: 1}}>
      <Header title="Receipt Delivery" style={{alignSelf: 'stretch'}}></Header>

      <SegmentSlider
        onSelectIndex={setActive}
        active={active}
        items={availableItems}
      />

      {active === EMAIL_INDEX && (
        <Stack style={{height: '100%', width: '100%', minHeight: 0}}>
          <ScrollContainer>
            <Stack
              align="center"
              gutter={Gutter.None}
              style={{flex: 1, marginTop: 36}}
            >
              {serverTransactionID && (
                <Stack justify="center" align="center" gutter={Gutter.XS}>
                  <Text variant={Variant.T2}>#{serverTransactionID}</Text>
                  <Text variant={Variant.Main}>
                    {moment(saleDate).format('MM/DD/YYYY - hh:mmA')}
                  </Text>
                </Stack>
              )}
              <Illustration illustration="NoEmails" />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <TextInput
                    onChange={field.onChange}
                    value={field.value}
                    autoComplete="off"
                    label="Email"
                    style={{width: 422}}
                  />
                )}
              />
            </Stack>
          </ScrollContainer>

          <FooterGroup>
            <Button
              data-testid="email-back"
              type="button"
              onClick={() => onBack(getOnBackLocation())}
              variant={BV.Secondary}
              flex
            >
              Back
            </Button>
            <Button
              type="submit"
              onClick={() => {
                onEmailReceipt(getValues());
              }}
              disabled={!formState.isValid || !isInBalance()}
              flex
            >
              Complete Transaction
            </Button>
          </FooterGroup>
        </Stack>
      )}
      {active === PRINT_INDEX && <PrintReceipt />}
      {active !== PRINT_INDEX && active !== EMAIL_INDEX && <NoReceipt />}
    </Stack>
  );
};

export const Receipts = memo(ReceiptsChild);
