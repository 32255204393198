import {PaymentType, TransactionPayment} from '@emporos/api-enterprise';
import {
  CayanClient,
  CayanConfig,
  CayanTransactionResponse,
  DeviceBusy,
  DeviceNotReady,
  DeviceUnreachable,
  IdleScreen,
  SKUDisplay,
  UnableToCancelTransaction,
  UnableToReturnOrder,
  VoidResponse,
} from '@emporos/card-payments';
import {useCallback, useEffect, useState} from 'react';
import {
  getPaymentTypeIdByDescriptor,
  mapTransactionPayment,
  TransactionResponse,
  transformCayanToTransactionResponse,
  transformCayanToVoidResponse,
  transformCayanVaultToTransactionResponse,
  useCardReaderCredentials,
  useCreditCardProcessing,
  useGlobalData,
  useTransaction,
  VoidRequest,
} from '../';
import {useApi} from '../contexts/ApiProvider';

export interface PaymentProvider {
  checkDevice: () => Promise<void>;
  stageTransaction: (request: PaymentRequest) => Promise<TransactionPayment>;
  initiateTransaction: (
    transportKey: string,
    amount: number,
  ) => Promise<TransactionResponse>;
  cancelTransaction: () => Promise<string>;
  voidTransaction: (request: VoidRequest) => Promise<VoidResponse>;
  getTransactionDetails: (
    payment: TransactionPayment,
  ) => Promise<TransactionResponse>;
  vaultTransaction: (request: PaymentRequest) => Promise<TransactionPayment>;
}

export interface PaymentRequest {
  totalAmount: number;
  qhpAmount: number;
  cardToken?: string;
  paymentTypeId?: number | null;
  paymentTypeDesc?: string;
  last4OfCard?: string;
  paymentInformationId?: number;
}

export const usePaymentProvider = (): PaymentProvider => {
  const api = useApi();
  const {credentials} = useCardReaderCredentials();
  const {transaction, updateTransaction} = useTransaction();
  const {paymentTendersResult} = useGlobalData();
  const {setLoadingPendingTransactionPaymentId} = useCreditCardProcessing();
  const {run: postVoid} = api.VoidPayment();
  const {run: postDetails} = api.PostPaymentDetails();
  const {run: postPayment} = api.PostTransactionPayment();

  // when the session opens onto the payment screen, payment tenders may not be loaded yet
  // this ensures that we don't crash in that instance while still getting the proper data when loading is complete
  const [paymentTenders, setPaymentTenders] = useState<PaymentType[]>(
    paymentTendersResult?.data ?? [],
  );
  useEffect(() => {
    if (paymentTendersResult?.data)
      setPaymentTenders(paymentTendersResult.data);
  }, [paymentTendersResult]);

  const checkDevice = useCallback(async () => {
    try {
      //send a cancel signal to prevent any pending transactions from being processed
      await client().cancel();
      const status = await client().checkStatus();
      if (status instanceof Error) {
        throw status;
      }
      if (status.CurrentScreen === SKUDisplay) {
        throw Error(DeviceBusy);
      } else if (status.CurrentScreen !== IdleScreen) {
        throw Error(DeviceNotReady);
      }
    } catch (err) {
      throw Error(DeviceUnreachable);
    }
  }, [JSON.stringify(credentials)]);

  const stageTransaction = useCallback(
    async (request: PaymentRequest): Promise<TransactionPayment> => {
      const pendingPayment = await postPayment({
        sessionId: transaction.sessionId,
        transactionId: transaction.transactionId,
        transactionPaymentRequest: mapTransactionPayment(
          transaction.transactionId,
          getPaymentTypeIdByDescriptor('UnknownCard', paymentTenders ?? []) ||
            0,
          '',
          request.totalAmount,
          {
            recordStatus: 'Pending',
            qhpRxAmount: request.qhpAmount,
            qhpOtherAmount: 0,
          },
        ),
      });
      updateTransaction(prevTransaction => ({
        payments: prevTransaction.payments.concat(pendingPayment),
      }));
      setLoadingPendingTransactionPaymentId(
        pendingPayment.transactionPaymentId,
      );
      return pendingPayment;
    },
    [postPayment, paymentTendersResult],
  );

  const initiateTransaction = useCallback(
    async (transportKey: string, amount: number) => {
      const initiated = await client().initiateTransaction(transportKey);
      setLoadingPendingTransactionPaymentId(undefined);
      /* istanbul ignore next */
      if (initiated instanceof Error) {
        throw initiated;
      } else {
        return transformCayanToTransactionResponse(
          initiated as CayanTransactionResponse,
          amount,
        );
      }
    },
    [JSON.stringify(credentials)],
  );

  const getTransactionDetails = useCallback(
    async (payment: TransactionPayment) => {
      if (!payment.cardToken) {
        throw Error(DeviceUnreachable);
      }
      const result = await postDetails({
        siteId: Number(credentials.config.siteId),
        paymentDetailRequest: {
          siteId: Number(credentials.config.siteId),
          transportKey: payment.cardToken,
        },
      });
      if (!result?.data) {
        throw Error();
      }
      return transformCayanVaultToTransactionResponse(result?.data);
    },
    [postDetails],
  );

  const cancelTransaction = useCallback(async () => {
    try {
      const result = await client().cancel();
      if (result.Status === 'Denied') {
        throw Error(UnableToCancelTransaction);
      }
    } catch (err) {
      throw Symbol.for('TransactionAbortSignal');
    }
    return 'Okay';
  }, [JSON.stringify(credentials)]);

  const vaultTransaction = useCallback(
    async (request: PaymentRequest) => {
      if (!request.paymentTypeId) throw request;

      return postPayment({
        sessionId: transaction.sessionId,
        transactionId: transaction.transactionId,
        transactionPaymentRequest: mapTransactionPayment(
          transaction.transactionId,
          request.paymentTypeId,
          request.paymentTypeDesc || '',
          request.totalAmount,
          {
            recordStatus: 'Active',
            qhpRxAmount: request.qhpAmount,
            qhpOtherAmount: 0,
            cardToken: request.cardToken,
            paymentNumber: request.last4OfCard,
            ccofPaymentInfoID: request.paymentInformationId,
          },
        ),
      });
    },
    [postPayment],
  );

  const voidTransaction = useCallback(
    async (request: VoidRequest) => {
      const voidRequest = {
        siteId: Number(credentials.config.siteId),
        transactionId: request.orderId,
        referenceNumber: request.transactionId,
        terminalId: '',
        userId: request.clerkId,
      };

      const stagedRaw = await postVoid({
        siteId: Number(credentials.config.siteId),
        paymentVoidRequest10: voidRequest,
      });

      if (stagedRaw.data?.status === 'Unknown') {
        throw new Error(UnableToReturnOrder);
      }
      return transformCayanToVoidResponse(stagedRaw);
    },
    [postVoid],
  );

  const client = () => CayanClient.create(credentials.config as CayanConfig);

  return {
    checkDevice,
    stageTransaction,
    initiateTransaction,
    getTransactionDetails,
    vaultTransaction,
    cancelTransaction,
    voidTransaction,
  };
};
