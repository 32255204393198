import {ClientJS} from 'clientjs';

export const getDeviceFingerprint = (): string => {
  const client = new ClientJS();

  const userAgent = window.navigator.userAgent;
  const screenPrint = client.getScreenPrint();
  const pluginList = client.getPlugins();
  const timeZone = client.getTimeZone();
  const language = client.getLanguage();
  const systemLanguage = client.getSystemLanguage();
  const canvasPrint = client.getCanvasPrint();
  const cpu = client.getCPU();

  const fingerprint = client.getCustomFingerprint(
    userAgent,
    screenPrint,
    pluginList,
    timeZone,
    language,
    systemLanguage,
    canvasPrint,
    cpu,
  );
  return fingerprint.toString();
};
