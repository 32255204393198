import {NotificationAlert} from '@emporos/components';
export interface AlertsMessages {
  [key: string]: {
    [key: string]: Omit<NotificationAlert, 'id'>;
  };
}

export const alertsMessages: AlertsMessages = {
  GET: {
    '(.*)(\\/client\\/customers\\?q=)(.*)': {
      title: 'Will Call Error',
      description:
        'Customer prescription info could not be pulled down. Please try again.',
      icon: 'Warning',
      type: 'warning',
    },
    '(.*)(\\/client\\/eligibility\\?q=)(.*)': {
      title: 'Searching Failed',
      description:
        'Something happened while searching eligibility. Please try searching again.',
      icon: 'Warning',
      type: 'warning',
    },
    '(.*)(\\/client\\/sites)(.*)': {
      title: 'Failed To Get Sites',
      description:
        'Something happened while loading sites. Please check your internet connection and reload the app.',
      icon: 'Warning',
      type: 'warning',
    },
  },
  POST: {
    '(.*)(\\/accounts\\/ar)': {
      title: 'Create AR Account',
      description: 'The new AR Account could not be created. Please try again.',
      icon: 'Warning',
      type: 'warning',
    },
  },
  PUT: {},
  DELETE: {},
};
