import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Stack} from './Layout';
import {DisplayUser} from './auth';
import {isEqual} from 'lodash';

type Type = 'create' | 'login';
export interface AccessCodeProps {
  mode: Type;
  codeLength: number;
  setHeaderTitle: (headerTitle: string) => void;
  setAccessCode: (accessCode: string) => Promise<boolean>;
  selectedUser?: DisplayUser | null;
  setSecondPassForParent?: (secondPass: boolean) => void;
  onWeakAccessCode?: () => void;
}

interface SavedState {
  inputArray: Array<number>;
  savedArray: Array<number>;
  isSecondPass: boolean;
}

const buttonWidth = 62;

const NumberButton = styled.button`
  width: ${buttonWidth}px;
  height: ${buttonWidth}px;
  padding: 0;
  background: #f1f3f6;
  border-radius: 50%;
  border: 0;

  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  color: #106fe5;

  cursor: pointer;

  &:active {
    background: rgba(16, 111, 229, 0.2);
    border: 2px solid #106fe5;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.disabled:active {
    background: #f1f3f6;
    border: 0;
  }
`;

const BackButton = styled.button`
  width: ${buttonWidth}px;
  height: ${buttonWidth}px;
  background: transparent;
  border-radius: 50%;
  border: 0;

  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ShakyBorder = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 8px;

  &.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const BackArrow = () => (
  <svg
    width="40"
    height="25"
    viewBox="0 0 40 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 0.333374H12.829C12.3036 0.333269 11.7833 0.436712 11.298 0.637788C10.8126 0.838865 10.3716 1.13363 10.0002 1.50524L0.585933 10.9189C-0.195311 11.7002 -0.195311 12.9664 0.585933 13.747L10.0002 23.1613C10.7502 23.9113 11.7677 24.3332 12.8283 24.3332H36C38.2094 24.3332 40 22.5426 40 20.3332V4.33334C40 2.12399 38.2094 0.333374 36 0.333374ZM30.7069 16.212C31.0976 16.6026 31.0976 17.2357 30.7069 17.6264L29.2932 19.0401C28.9026 19.4307 28.2695 19.4307 27.8788 19.0401L24.0001 15.1614L20.1214 19.0401C19.7308 19.4307 19.0977 19.4307 18.707 19.0401L17.2933 17.6264C16.9027 17.2357 16.9027 16.6026 17.2933 16.212L21.172 12.3333L17.2933 8.45456C16.9027 8.06394 16.9027 7.43082 17.2933 7.0402L18.707 5.62646C19.0977 5.23584 19.7308 5.23584 20.1214 5.62646L24.0001 9.50518L27.8788 5.62646C28.2695 5.23584 28.9026 5.23584 29.2932 5.62646L30.7069 7.0402C31.0976 7.43082 31.0976 8.06394 30.7069 8.45456L26.8282 12.3333L30.7069 16.212Z"
      fill="#106FE5"
    />
  </svg>
);

interface IndicatorProps {
  codeLength: number;
}

const IndicatorRow = styled.div`
  display: inline-flex;
  justify-content: center;

  & > div {
    margin-left: 20px;
  }

  & > div:first-of-type {
    margin: 0;
  }
`;

const CharacterIndicator = styled.div<IndicatorProps>`
  box-sizing: border-box;
  width: ${props => (props.codeLength <= 6 ? '24px' : '16px')};
  height: ${props => (props.codeLength <= 6 ? '24px' : '16px')};
  background: #ffffff;
  border: 3px solid #106fe5;
  border-radius: 50%;

  &.filled {
    background: #106fe5;
  }

  &.error {
    background: #dc143c;
    border: 3px solid #dc143c;
  }
`;

const AccessCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NumberPadWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  max-width: calc((62px * 3) + (24px * 2));
  justify-items: center;
  margin-top: 24px;
`;

export const AccessCode = memo(
  ({
    mode,
    codeLength,
    setHeaderTitle,
    setAccessCode,
    setSecondPassForParent,
    selectedUser,
    onWeakAccessCode,
  }: AccessCodeProps) => {
    const [inputIndicators, setInputIndicators] = useState(
      new Array<JSX.Element>(),
    );
    const [savedState, setSavedState] = useState<SavedState>({
      inputArray: new Array<number>(),
      savedArray: new Array<number>(),
      isSecondPass: false,
    } as SavedState);
    const [shake, setShake] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [backspaceIsDisabled, setBackspaceIsDisabled] = useState(true);

    useEffect(() => {
      let itemList: Array<JSX.Element> = [];
      for (let i = 1; i <= codeLength; i++)
        itemList.push(
          <CharacterIndicator
            key={i}
            codeLength={codeLength}
          ></CharacterIndicator>,
        );

      setInputIndicators(itemList);
      if (mode === 'create') {
        setHeaderTitle('Create an Access Code');
      } else if (mode === 'login') {
        if (selectedUser) {
          setHeaderTitle('Welcome ' + selectedUser.name);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeLength, selectedUser, mode]);

    useEffect(() => {
      const inputIndicators = new Array<JSX.Element>();
      const numbersEntered = savedState.inputArray.length;
      for (let i = 1; i <= codeLength; i++) {
        const className = i <= numbersEntered ? 'filled' : '';

        inputIndicators.push(
          <CharacterIndicator
            className={className}
            key={i}
            codeLength={codeLength}
          ></CharacterIndicator>,
        );
      }
      setInputIndicators(inputIndicators);

      if (numbersEntered > 0) {
        setBackspaceIsDisabled(false);
        setTimeout(() => {
          if (numbersEntered === codeLength) {
            if (mode === 'create' && !savedState.isSecondPass) {
              if (isWeakAccessCode([...savedState.inputArray])) {
                showErrorAndReset();
                onWeakAccessCode?.();
                return;
              }
              setHeaderTitle('Confirm your Access Code');
              setSavedState(
                state =>
                  ({
                    inputArray: new Array<number>(),
                    savedArray: state.inputArray,
                    isSecondPass: true,
                  } as SavedState),
              );
              if (setSecondPassForParent) setSecondPassForParent(true);
            } else if (mode === 'create' && savedState.isSecondPass) {
              if (isEqual(savedState.inputArray, savedState.savedArray)) {
                setIsDisabled(true);
                setAccessCode(savedState.inputArray.join(''));
              } else {
                showErrorAndReset();
              }
            } else if (mode === 'login') {
              setAccessCode(savedState.inputArray.join('')).then(response => {
                if (!response) {
                  showErrorAndReset();
                }
              });
            }
          }
        }, 200);
      } else {
        setBackspaceIsDisabled(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedState.inputArray, savedState.isSecondPass]);

    const addNumberToAccessCode = (input: number) => {
      const existingArray = [...savedState.inputArray];
      existingArray.push(input);
      if (existingArray.length > codeLength) return;

      setSavedState(
        state =>
          ({
            inputArray: existingArray,
            savedArray: state.savedArray,
            isSecondPass: state.isSecondPass,
          } as SavedState),
      );
    };

    const removeLastNumberFromAccessCode = () => {
      const existingArray = [...savedState.inputArray];
      existingArray.pop();
      setSavedState(
        state =>
          ({
            inputArray: existingArray,
            savedArray: state.savedArray,
            isSecondPass: state.isSecondPass,
          } as SavedState),
      );
    };

    const showErrorAndReset = () => {
      let errorIndicatorList: Array<JSX.Element> = [];

      for (let i = 1; i <= codeLength; i++)
        errorIndicatorList.push(
          <CharacterIndicator
            className={'error'}
            key={i}
            codeLength={codeLength}
          ></CharacterIndicator>,
        );
      setInputIndicators(errorIndicatorList);
      setShake(state => !state);
      setTimeout(() => {
        setShake(state => !state);
        setSavedState(
          state =>
            ({
              inputArray: state.savedArray,
              savedArray: new Array<number>(),
              isSecondPass: false,
            } as SavedState),
        );
        if (setSecondPassForParent) setSecondPassForParent(false);
      }, 500);
    };

    const isWeakAccessCode = (accessCode: Array<number>): boolean => {
      const accessCodeStr = accessCode.join('');

      // Check for consecutive numbers
      const consecutiveNumbers = '12345678901234567890';
      const reverseConsecutiveNumbers = '09876543210987654321';
      if (
        consecutiveNumbers.includes(accessCodeStr) ||
        reverseConsecutiveNumbers.includes(accessCodeStr)
      ) {
        return true;
      }

      // Check if all numbers are the same
      for (let i = 1; i < accessCode.length; i++) {
        if (accessCode[i] !== accessCode[0]) {
          return false;
        }
      }

      return true;
    };

    return (
      <ShakyBorder className={shake ? 'shake' : ''}>
        <Stack>
          <AccessCodeContainer>
            <IndicatorRow>{inputIndicators}</IndicatorRow>
            <NumberPadWrapper>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, 'backspace'].map(value => {
                // number buttons
                if (typeof value === 'number')
                  return (
                    <NumberButton
                      name={value.toString()}
                      key={value}
                      onClick={() => addNumberToAccessCode(value)}
                      className={isDisabled ? 'disabled' : ''}
                      disabled={isDisabled}
                    >
                      {value}
                    </NumberButton>
                  );

                // backspace button
                if (value === 'backspace')
                  return (
                    <BackButton
                      key={value}
                      onClick={removeLastNumberFromAccessCode}
                      className={backspaceIsDisabled ? 'disabled' : ''}
                      disabled={backspaceIsDisabled}
                      data-testid="backspace-button"
                    >
                      <BackArrow></BackArrow>
                    </BackButton>
                  );

                // empty button
                return <div key="empty" className="spacer" />;
              })}
            </NumberPadWrapper>
          </AccessCodeContainer>
        </Stack>
      </ShakyBorder>
    );
  },
);
