import {
  OfflineEndpointParams,
  getEndpointsConfig,
  getOfflineEndpointsConfig,
} from '../api/endpoints-config';

export const endpointConfig = (url: string, method?: string) => {
  const endpointsConfiguration = getEndpointsConfig();
  const methodMappings = endpointsConfiguration[method || 'GET'];
  const match = Object.keys(methodMappings).find(exp => url.match(exp));
  if (match) {
    return methodMappings[match];
  }
};

export const offlineEndpointConfig = (params: OfflineEndpointParams) => {
  const endpointsConfiguration = getOfflineEndpointsConfig(params);
  const methodMappings = endpointsConfiguration[params.method.toString()];
  return methodMappings;
};

export const formatDateForUrl = (compareDate: Date): string => {
  const stringified = compareDate.toISOString();
  const formatted = stringified.replace(new RegExp(':', 'g'), '%3A');
  return formatted;
};
