import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Gutter,
  Icon,
  IconSize,
  Row,
  Stack,
  Text,
  TextVariant as Variant,
} from './';
import {
  PaymentVisa,
  PaymentAmex,
  PaymentMastercard,
  PaymentDiscover,
} from './IconsPayment';
import {ButtonShape, ButtonSize} from './';

export interface CardOnFileProps {
  ccofId?: number; //paymentInformationId
  cardToken?: string;
  cardHolder?: string;
  expirationDate?: string;
  lastFour?: string;
  cardType?: string;
  nickName?: string;
  isExpired?: boolean;
  isSelected?: boolean;
  showLowerRow?: boolean;
  isDefault?: boolean;
  onEdit?: (cardToken: string | undefined) => void;
  onDelete?: (cardToken: string | undefined) => void;
  online?: boolean;
}

const CardContainer = styled.div`
  position: relative;
  padding: 10px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 385px;
`;

const BaseCard = styled(Stack)`
  display: inline-flex;
  padding: 24px;
  height: 233px;
  width: 385px;
  background-color: rgba(247, 252, 255, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  align-content: space-between;
  position: relative;
`;

const ExpiredLabel = styled(Row)`
  position: absolute;
  font-size: 18px;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  color: red;
  z-index: 1;
  top: 34px;
  left: 19px;
`;

const DefaultLabel = styled(Row)`
  position: absolute;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  color: #0ee93e;
  z-index: 1;
  top: 16px;
  left: 20px;
`;

const LastFourText = styled(Text)`
  font-size: 28px;
  font-family: Ubuntu, sans-serif;
  font-weight: 400;
  color: white;
  margin-top: 30px;
  z-index: 1;
  align-self: flex-start;
`;

const HolderRow = styled(Row)`
  width: 100%;
  margin-top: 30px;
`;

const IconRow = styled(Row)`
  flex-direction: row-reverse;
  width: 100%;
  height: 80px;
  z-index: 1;
`;

const ExpirationDate = styled.div`
  font-size: 12px;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  color: black;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & .expText {
    color: rgba(200, 210, 225, 1);
    align-self: center;
  }

  & .expDigits {
    font-size: 17px;
    margin-top: 5px;
    align-self: center;
  }
`;

const GradientBox = styled.div`
  width: 385px;
  height: 155px;
  background-image: linear-gradient(
    90deg,
    rgba(16, 111, 229, 1) 0%,
    rgba(193, 133, 254, 1) 100%
  );
  background-color: black;
  position: absolute;
  top: 0;
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const LowerRow = styled(Row)`
  width: 385px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NicknameText = styled.p`
  font-size: 18px;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  color: grey;
  margin: 0;
  margin-left: 10px;
`;

const ActionIconsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const HolderNameText = styled(Text)`
  font-size: 17px;
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  color: black;
  position: relative;
  top: 5px;
`;

const mapCardLogo = (cardType: string | undefined) => {
  switch (cardType) {
    case 'Amex':
      return (
        <PaymentAmex width={80} height={60} data-testid="cardonfile-cardLogo" />
      );
    case 'Discover':
    case 'Disc':
      return (
        <PaymentDiscover
          width={80}
          height={60}
          data-testid="cardonfile-cardLogo"
        />
      );
    case 'MasterCard':
    case 'MC':
      return (
        <PaymentMastercard
          width={80}
          height={60}
          data-testid="cardonfile-cardLogo"
        />
      );
    case 'Visa':
      return (
        <PaymentVisa width={80} height={60} data-testid="cardonfile-cardLogo" />
      );
    default:
      return (
        <Icon
          icon="CreditCard"
          variant={'inverted'}
          size={IconSize.CardOnFile}
          data-testid="cardonfile-cardLogo"
        />
      );
  }
};

const mapCardStyles = (
  isExpired: boolean | undefined,
  isSelected: boolean | undefined,
) => {
  let cardStyles = {};
  if (isExpired) {
    cardStyles = {...cardStyles, opacity: '.5'};
  }
  if (isSelected) {
    cardStyles = {
      ...cardStyles,
      boxShadow: '0px 0px 4px 5px rgba(16,111,229,1)',
    };
  }
  return cardStyles;
};

export const CardOnFile = memo(
  ({
    ccofId,
    cardToken,
    cardHolder,
    expirationDate,
    lastFour,
    cardType,
    nickName = 'No Nickname',
    isExpired,
    isSelected,
    isDefault,
    onEdit,
    onDelete,
    online,
  }: CardOnFileProps & React.PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    const cardLogo = mapCardLogo(cardType);

    const [cardStyles, setCardStyles] = useState(
      mapCardStyles(isExpired, isSelected),
    );

    useEffect(() => {
      setCardStyles(mapCardStyles(isExpired, isSelected));
    }, [isExpired, isSelected]);

    return (
      <CardContainer>
        {isExpired && (
          <ExpiredLabel data-testid="cardonfile-expiredlabel">
            EXPIRED
          </ExpiredLabel>
        )}
        {isDefault && (
          <DefaultLabel data-testid="cardonfile-defaultlabel">
            DEFAULT
          </DefaultLabel>
        )}
        <BaseCard
          style={cardStyles}
          gutter={Gutter.S}
          align="center"
          data-testid="cardonfile-basecard"
        >
          <IconRow
            align="start"
            justify="space-between"
            gutter={Gutter.XL}
            noWrap
          >
            {cardLogo}
          </IconRow>
          <LastFourText data-testid="cardonfile-lastfour">
            XXXX - XXXX - {lastFour}
          </LastFourText>
          <HolderRow align="end" justify="space-between" noWrap>
            <HolderNameText
              variant={Variant.T3}
              data-testid="cardonfile-cardholder"
            >
              {cardHolder}
            </HolderNameText>
            <ExpirationDate>
              <div className="expText">Exp</div>
              <div
                className="expDigits"
                data-testid="cardonfile-expirationdate"
              >
                {expirationDate}
              </div>
            </ExpirationDate>
          </HolderRow>
          <GradientBox />
        </BaseCard>
        <LowerRow>
          <NicknameText data-testid="cardonfile-nickname">
            {nickName}
          </NicknameText>
          <ActionIconsContainer>
            <ButtonShape
              size={ButtonSize.Small}
              icon="Pencil"
              onClick={event => {
                event.preventDefault();
                if (onEdit) {
                  onEdit(String(ccofId));
                }
              }}
              data-testid="edit-card"
              disabled={isExpired || !online}
            />
            <ButtonShape
              size={ButtonSize.Small}
              icon="Trash"
              onClick={event => {
                event.preventDefault();
                if (onDelete) {
                  onDelete(cardToken);
                }
              }}
              data-testid="delete-card"
              disabled={!online}
            />
          </ActionIconsContainer>
        </LowerRow>
      </CardContainer>
    );
  },
);
