import {
  BaseAPI,
  Configuration,
  HTTPHeaders,
  HTTPMethod,
  HTTPQuery,
} from '@emporos/api-enterprise';
import {base64DecToArr, base64EncArr, UTF8ArrToStr} from '../utils/base64';
import {decryptToString} from '../utils/crypto';

export class EncryptedBaseApi extends BaseAPI {
  constructor(configuration: Configuration, private _key: string) {
    super(configuration);
  }

  GenerateHeaders(sessionId: string, encrypted = true): HTTPHeaders {
    const headerParameters: HTTPHeaders = {};
    headerParameters['X-Hilo-Encrypted'] = encrypted ? 'true' : 'false';
    headerParameters['X-Hilo-SessionId'] = sessionId;
    headerParameters['Content-Type'] = 'application/json; v=1.5';
    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === 'function') {
        headerParameters['Authorization'] = this.configuration.accessToken(
          'oauth2',
          ['clientapi'],
        );
      } else {
        headerParameters['Authorization'] = this.configuration.accessToken;
      }
    }
    return headerParameters;
  }

  ConvertToEncodedString(buffer: Uint8Array): string {
    const cipherText = encodeURIComponent(base64EncArr(buffer));
    return cipherText;
  }

  async MakeRequest(
    path: string,
    headerParameters: HTTPHeaders,
    queryParameters: HTTPQuery,
    method: HTTPMethod,
    body?: string,
  ): Promise<Response> {
    const response = await this.request({
      path: path,
      method: method,
      headers: headerParameters,
      query: queryParameters,
      body: body,
    }).then(async response => {
      const hasHeader = response.headers.has('X-Hilo-Encrypted');

      if (hasHeader) {
        const headerValue = response.headers.get('X-Hilo-Encrypted');
        if (headerValue) {
          const utf8HeaderVal = base64DecToArr(headerValue, 1);
          const decodedHeaderVal = UTF8ArrToStr(utf8HeaderVal);
          if (decodedHeaderVal.startsWith('true')) {
            const bodyString = await response.text();
            const headerValueSplitIndex = decodedHeaderVal.indexOf('|');

            if (headerValueSplitIndex > -1) {
              const headerValueB = decodedHeaderVal.substring(
                headerValueSplitIndex + 1,
              );
              const decryptedBody = decryptToString(headerValueB, bodyString);

              return new Response(decryptedBody, response);
            } else {
              const decryptedBody = decryptToString(this._key, bodyString);

              return new Response(decryptedBody, response);
            }
          }
        }
      }

      return response;
    });

    return response;
  }
}
