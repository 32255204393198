import {TransactionSignature} from '@emporos/api-enterprise';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  mapTransactionSignature,
  mapSignatureImage,
  OfflineTransaction,
  OfflineSynced,
  useTransaction,
  useSessionData,
  useSignatures,
  withChildPage,
} from '../../../../../';
import {Acknowledgements} from './';

export const AcknowledgementsIntegration = memo(
  withChildPage(({navigate}: RouteComponentProps) => {
    const {transaction, updateTransaction} = useTransaction();
    const signatureTypes = useSignatures();
    const {NODE_ENV} = process.env;
    const {settingsResult} = useSessionData();

    assert(
      navigate,
      '<Acknowledgements /> must have a `navigate` prop.' + String(NODE_ENV) ===
        'production'
        ? ''
        : ' This likely means that you need to have it as a direct child of a <Router />',
    );
    assert(settingsResult && settingsResult.data, 'Missing settingsResult');

    const _signatureImage = (transaction as OfflineTransaction).signatureImage;
    const signatureImage = _signatureImage
      ? _signatureImage
      : mapSignatureImage();

    return (
      <Acknowledgements
        settings={settingsResult.data}
        onBack={onBackLocation => navigate(onBackLocation)}
        onAccept={(imageData, pointData, touchless) => {
          updateTransaction({
            signatureImage: {
              ...signatureImage,
              pointData: pointData.map(group =>
                group.map(({x, y}) => ({x, y})),
              ),
              // if touchless don't sync signatureImage
              isSynced: touchless,
            },
            signatures: transaction.signatures
              .map(
                s =>
                  ({
                    ...s,
                    isDeleted: true,
                    isSynced: false,
                  } as TransactionSignature & OfflineSynced),
              )
              .concat(
                signatureTypes.map(type =>
                  mapTransactionSignature(
                    transaction.transactionId,
                    touchless,
                    type,
                    touchless ? undefined : signatureImage.signatureImageId,
                  ),
                ),
              ),
          } as Partial<OfflineTransaction>);
          return navigate('../receipts');
        }}
        navigate={navigate}
      />
    );
  }),
);
