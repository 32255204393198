import {
  StoredUser,
  DisplayUser,
  generateStoredUserString,
  parseStoredUser,
} from '@emporos/components';
import {User} from '@emporos/hilo-auth';
import {IAuthStorageService} from './IAuthStorageService';
import {Session} from '@emporos/api-enterprise';
import {
  StoredSession,
  generateStoredSessionString,
  parseStoredSession,
} from '../utils/session';

// instantiate with DIFactory.getUserStorageService to use in base react classes (uses a hook)
export class AuthLocalStorageService implements IAuthStorageService {
  storeUser = (user: User, accessCode: string) => {
    localStorage.setItem(
      'user:' + user.profile['sub'],
      generateStoredUserString(user, accessCode),
    );
  };

  storeSession = (user: User, session: Session) => {
    localStorage.setItem(
      'session:' + user.profile['sub'],
      generateStoredSessionString(session),
    );
  };

  storeAuthInfo = (user: User, accessCode: string, session: Session | null) => {
    this.storeUser(user, accessCode);
    if (session) this.storeSession(user, session);
  };

  removeUser = (user: User) => {
    this.removeUserBySub(user.profile['sub']);
  };

  removeUserBySub = (sub: string) => {
    localStorage.removeItem('user:' + sub);
  };

  removeSession = (user: User) => {
    this.removeSessionBySub(user.profile['sub']);
  };

  removeSessionBySub = (sub: string) => {
    localStorage.removeItem('session:' + sub);
  };

  removeAuthInfo = (user: User) => {
    this.removeUser(user);
    this.removeSession(user);
  };

  removeAuthInfoBySub = (sub: string) => {
    this.removeUserBySub(sub);
    this.removeSessionBySub(sub);
  };

  getStoredUser = (key: string, accessCode?: string): StoredUser | null => {
    const storedUserString = localStorage.getItem(key);
    if (storedUserString) {
      const storedUser = parseStoredUser(storedUserString, accessCode);
      return storedUser;
    }
    return null;
  };

  getStoredSession = (
    key: string,
    accessCode: string,
  ): StoredSession | null => {
    const storedSessionString = localStorage.getItem(key);
    if (storedSessionString) {
      const storedSession = parseStoredSession(storedSessionString, accessCode);
      return storedSession;
    }
    return null;
  };

  getAllStoredUsers = (): DisplayUser[] => {
    const userKeys = this.getKeysWithRegex('user:*');
    const sessionKeys = this.getKeysWithRegex('session:*');
    const users = new Array<DisplayUser>();
    userKeys.forEach(userKey => {
      const storedUser = this.getStoredUser(userKey);
      const sessionKey = userKey.replace('user:', 'session:');
      if (storedUser && sessionKeys.includes(sessionKey)) {
        // we only want to return users if the StoredUser and StoredSession are available
        users.push({...storedUser, userKey: userKey, sessionKey: sessionKey});
      }
    });
    return users;
  };

  getUser = (key: string, accessCode: string): User | null => {
    const storedUser = this.getStoredUser(key, accessCode);
    if (storedUser) {
      try {
        return User.fromStorageString(storedUser.user);
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  private getKeysWithRegex = (regexString: string): string[] => {
    const regex = new RegExp(regexString);
    const keys = Object.keys(localStorage).filter(function (key) {
      return regex.test(key);
    });
    return keys;
  };
}
