import {startCase, toLower} from 'lodash';
import React, {memo, PropsWithoutRef} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {
  ColorType,
  DividerLine,
  Gutter,
  Icons,
  IndicatorBadge,
  IndicatorCircle,
  Row,
  Stack,
  Text,
  TextVariant as TVar,
} from './';

export type RowSidebarStatus = 'synced' | 'unsynced' | 'error';

export interface RowSidebarProps {
  title?: string;
  subtitle?: string;
  icon: keyof typeof Icons;
  iconColor?: ColorType;
  badgeColor?: ColorType;
  badgeText?: string | number;
  selected?: boolean;
  synced?: RowSidebarStatus;
}

const StyledRowSidebar = styled(Row)<RowSidebarProps>`
  padding: 16px;
  background-color: ${({theme, selected}) => backgroundColor(theme, selected)};
  cursor: pointer;
  border-left: ${({theme, synced}) => {
    switch (synced) {
      case 'synced':
        return `6px solid ${theme.colors.success}`;
      case 'unsynced':
        return `6px solid ${theme.colors.warning}`;
      case 'error':
        return `6px solid ${theme.colors.error}`;
      default:
        return '6px solid transparent';
    }
  }};
`;

const backgroundColor = (theme: DefaultTheme, selected?: boolean) =>
  selected ? theme.colors.blue_20 : theme.colors.gray_100;

export const RowSidebar = memo(
  ({
    icon,
    badgeText,
    title,
    subtitle,
    synced,
    iconColor = 'primary',
    badgeColor = 'indigo',
    ...props
  }: RowSidebarProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    return (
      <>
        <StyledRowSidebar
          align="center"
          gutter={Gutter.S}
          data-testid="row-sidebar"
          icon={icon}
          synced={synced}
          {...props}
        >
          <IndicatorCircle icon={icon} variant={iconColor} />
          <Stack gutter={Gutter.XXS} style={{marginRight: 'auto'}}>
            <Text variant={TVar.Main}>{startCase(toLower(title))}</Text>
            {subtitle && <Text variant={TVar.SubMainLight}>{subtitle}</Text>}
            {badgeText && (
              <IndicatorBadge variant={badgeColor} text={badgeText} />
            )}
          </Stack>
        </StyledRowSidebar>
        <DividerLine />
      </>
    );
  },
);
