import Dexie, {Table} from 'dexie';
import {TransactionConsolidate, TransactionPaymentConsolidate} from '../api';
import {CryptoSettings} from 'dexie-encrypted/dist/types';
import {applyMiddlewareWithCustomEncryption} from 'dexie-encrypted/dist/applyMiddleware';
import {
  generateKey,
  encryptionMiddlewareFunction,
  passthroughMiddlewareFunction,
} from '../utils/crypto';
import {base64DecToArr} from '../utils/base64';

export class HiloDb extends Dexie {
  public TransactionLocaldb!: Table<TransactionConsolidate>;
  public TransactionPaymetLocaldb!: Table<TransactionPaymentConsolidate>;

  doNothing = (): Promise<void[]> => {
    return Promise.all([]);
  };

  constructor(sessionId: string, accessCode: string) {
    super('HiloDb');

    const encryptionKey = base64DecToArr(generateKey(accessCode, sessionId), 1);

    this.version(5).stores({
      TransactionLocaldb:
        'transactionId, sessionId, recordStatus, status, serverTransactionID, isSynced, isDeleted, isCompleted, userSub',
      TransactionPaymetLocaldb:
        'transactionPaymentId,transactionId,transactionPaymentStatus,recordStatus, isSynced, isDeleted, customerId, siteId, stationId',
    });

    applyMiddlewareWithCustomEncryption({
      db: this,
      encryptionKey: encryptionKey,
      tableSettings: {
        TransactionLocaldb: 'NON_INDEXED_FIELDS',
        TransactionPaymetLocaldb: 'NON_INDEXED_FIELDS',
      } as CryptoSettings<typeof this>,
      encrypt: encryptionMiddlewareFunction,
      decrypt: passthroughMiddlewareFunction,
      onKeyChange: this.doNothing, //previously clearAllTables, unsure if this change is recommended but it is, at least, temporarily needed to ensure that the db is not cleared
    });
  }
}

export const deleteDatabase = async (sessionId: string): Promise<void> => {
  const db = new HiloDb(sessionId, '');
  await db.delete();
};

export const getDatabase = async (sessionId: string): Promise<HiloDb> => {
  const db = new HiloDb(sessionId, '');
  await db.open();
  return db;
};
