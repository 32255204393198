/* tslint:disable */
/* eslint-disable */
/**
 * Emporos Hilo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerCreditCard
 */
export interface CustomerCreditCard {
    /**
     * 
     * @type {number}
     * @memberof CustomerCreditCard
     */
    paymentInformationId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreditCard
     */
    cardType: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreditCard
     */
    member: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerCreditCard
     */
    expiration: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCreditCard
     */
    isPrimary: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerCreditCard
     */
    paymentTypeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreditCard
     */
    last4OfCard: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreditCard
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreditCard
     */
    nickName: string;
}

export function CustomerCreditCardFromJSON(json: any): CustomerCreditCard {
    return CustomerCreditCardFromJSONTyped(json, false);
}

export function CustomerCreditCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerCreditCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentInformationId': json['paymentInformationId'],
        'cardType': json['cardType'],
        'member': json['member'],
        'expiration': (new Date(json['expiration'])),
        'isPrimary': json['isPrimary'],
        'paymentTypeId': !exists(json, 'paymentTypeId') ? undefined : json['paymentTypeId'],
        'last4OfCard': json['last4OfCard'],
        'token': json['token'],
        'nickName': json['nickName'],
    };
}

export function CustomerCreditCardToJSON(value?: CustomerCreditCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentInformationId': value.paymentInformationId,
        'cardType': value.cardType,
        'member': value.member,
        'expiration': (value.expiration.toISOString()),
        'isPrimary': value.isPrimary,
        'paymentTypeId': value.paymentTypeId,
        'last4OfCard': value.last4OfCard,
        'token': value.token,
        'nickName': value.nickName,
    };
}


