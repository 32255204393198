import {
  PaymentTypeListApiResponse,
  RelationshipListApiResponse,
  SignatureType,
  TaxGroupListApiResponse,
} from '@emporos/api-enterprise';
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {useApi} from './ApiProvider';

interface GlobalDataProviderProps {
  loading: boolean;
  isError: boolean;
  taxGroupsResult?: TaxGroupListApiResponse | void;
  relationshipsResult?: RelationshipListApiResponse | void;
  paymentTendersResult?: PaymentTypeListApiResponse | void;
  signatureTypesResult?: Array<SignatureType> | void;
}

const GlobalDataContext = createContext<GlobalDataProviderProps>({
  loading: false,
  isError: false,
  taxGroupsResult: undefined,
  relationshipsResult: undefined,
  paymentTendersResult: undefined,
  signatureTypesResult: undefined,
});

const GlobalDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const api = useApi();

  const {
    loading: loadingTaxGroups,
    error: errorTaxGroups,
    data: taxGroupsResult,
  } = api.GetTaxGroups([{}]);
  const {
    loading: loadingRelationships,
    error: errorRelationships,
    data: relationshipsResult,
  } = api.GetRelationships([{}]);
  const {
    loading: loadingPaymentTenders,
    error: errorPaymentTenders,
    data: paymentTendersResult,
  } = api.GetPaymentTenders([{}]);
  const {
    loading: loadingSignatureTypes,
    error: errorSignatureTypes,
    data: signatureTypesResult,
  } = api.GetSignatureTypes([]);

  useEffect(() => {
    setLoading(
      loadingTaxGroups ||
        loadingRelationships ||
        loadingPaymentTenders ||
        loadingSignatureTypes,
    );
  }, [
    loadingTaxGroups,
    loadingRelationships,
    loadingPaymentTenders,
    loadingSignatureTypes,
  ]);

  useEffect(() => {
    setError(
      !!errorTaxGroups ||
        !!errorRelationships ||
        !!errorPaymentTenders ||
        !!errorSignatureTypes,
    );
  }, [
    errorTaxGroups,
    errorRelationships,
    errorPaymentTenders,
    errorSignatureTypes,
  ]);

  return (
    <GlobalDataContext.Provider
      value={{
        loading,
        isError: Boolean(error),
        taxGroupsResult,
        relationshipsResult,
        paymentTendersResult,
        signatureTypesResult,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

const useGlobalData = (): GlobalDataProviderProps =>
  useContext(GlobalDataContext);
export {GlobalDataProvider, useGlobalData};
