import {ExposedAppSettings} from '@emporos/api-enterprise';
import {ReactNode, createContext, useContext, useMemo} from 'react';
import {useApi} from './ApiProvider';

export interface GlobalSettings {
  tenantId?: string;
  exposedAppSettings?: ExposedAppSettings;
}

const GlobalSettingsContext = createContext<GlobalSettings>({
  tenantId: undefined,
});

export const GlobalSettingsProvider = ({children}: {children: ReactNode}) => {
  const api = useApi();
  const {data: tenantIdResult} = api.GetTenantId([]);
  const {data: exposedAppSettings} = api.GetExposedAppSettings([]);

  const value = useMemo(
    () => ({
      tenantId: tenantIdResult?.data,
      exposedAppSettings: exposedAppSettings?.data,
    }),
    [tenantIdResult, exposedAppSettings],
  );

  return (
    <GlobalSettingsContext.Provider value={value}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export const useGlobalSettings = (): GlobalSettings =>
  useContext(GlobalSettingsContext);
