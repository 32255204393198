import {
  Transaction,
  TransactionItem,
  TransactionPayment,
} from '@emporos/api-enterprise';
import {Dispatch, SetStateAction} from 'react';
import {
  TransactionUpdates,
  mapTransaction,
  OfflineTransaction,
  Session,
  useTransactionsState,
} from '../';
import {filterDeletedTransactionChildren} from './';

export type UpdateTransactionFn = (
  updates: TransactionUpdates,
  isCompleted?: boolean,
) => Promise<void>;
export type UpdateTransactionItemFn = (
  item: TransactionItem,
  updates: Partial<TransactionItem>,
) => void;

export const useTransaction = (): {
  transaction: Transaction;
  processedPayments: TransactionPayment[];
  voidedPayments: TransactionPayment[];
  pendingPayments: TransactionPayment[];
  updateTransaction: UpdateTransactionFn;
  updateTransactionItem: UpdateTransactionItemFn;
  canDelete: boolean;
} => {
  const {session, setSession, currentTransactionId} = useTransactionsState();

  const transaction =
    session.transactions.find(i => i.transactionId === currentTransactionId) ||
    mapTransaction(session.sessionId, session.siteId);

  const _updateTransaction = async (
    updates: TransactionUpdates,
    isCompleted?: boolean,
  ): Promise<void> => {
    setSession(prevSession => ({
      ...prevSession,
      triggerSync: true,
      transactions: prevSession.transactions.map(iv => ({
        ...iv,
        ...(iv.transactionId === transaction.transactionId && {
          isSynced: true, //Todo return from save session a true or false if the sync happend ok
          isCompleted: isCompleted,
          ...(typeof updates === 'function' ? updates(iv) : updates),
        }),
      })),
    }));
    //Todo find the correct place to call the update session once the invoce is updated
  };

  const _updateTransactionItem = (
    item: TransactionItem,
    updates: Partial<TransactionItem>,
  ) => {
    updateTransactionItem(setSession, transaction, item, updates);
  };

  const transactionWithoutDeleted = filterDeletedTransactionChildren(
    transaction as OfflineTransaction,
  );
  const processedPayments = filterPaymentsByRecordStatus(
    transactionWithoutDeleted,
    'Active',
  );
  const voidedPayments = filterPaymentsByRecordStatus(
    transactionWithoutDeleted,
    'Voided',
  );
  const pendingPayments = [
    ...filterPaymentsByRecordStatus(transactionWithoutDeleted, 'Pending'),
    ...filterPaymentsByRecordStatus(transactionWithoutDeleted, 'OverCharged'),
  ];
  const canDelete = processedPayments.concat(pendingPayments).length === 0;

  return {
    transaction: transactionWithoutDeleted,
    processedPayments,
    voidedPayments,
    pendingPayments,
    updateTransaction: _updateTransaction,
    updateTransactionItem: _updateTransactionItem,
    canDelete,
  };
};

function updateTransactionItem(
  setSession: Dispatch<SetStateAction<Session>>,
  transaction: Transaction,
  transactionItem: TransactionItem,
  updates: Partial<TransactionItem>,
): void {
  setSession(prevSession => ({
    ...prevSession,
    triggerSync: true,
    transactions: prevSession.transactions.map(iv =>
      iv.transactionId === transaction.transactionId
        ? {
            ...iv,
            items: iv.items.map(i =>
              i.transactionItemId === transactionItem?.transactionItemId
                ? {...i, isSynced: true, ...updates}
                : i,
            ),
          }
        : iv,
    ),
  }));
}

const filterPaymentsByRecordStatus = (
  transaction: Transaction,
  rs: string,
): TransactionPayment[] =>
  transaction.payments.filter(({recordStatus}) => recordStatus === rs);
