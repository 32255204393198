import {Table} from 'dexie';
import {TransactionPaymentConsolidate} from '../api';
import {HiloDb} from './dbcontext';
import {ITransactionPaymentsDatabaseAccess} from './IDatabaseAccess';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
import {
  EncryptedTransactionPayment,
  TransactionPaymentDbPackage,
  generateTransactionPaymentPackage,
  generateTransactionPaymentPackages,
} from '../utils/crypto';

// instantiate with DIFactory.getTransactionPaymentsDatabaseAccess to use in base react classes (uses a hook)
export class TransactionPaymentLocaldb
  implements ITransactionPaymentsDatabaseAccess
{
  db!: HiloDb;
  table!: Table<TransactionPaymentConsolidate>;
  private _sessionId!: string;
  private _accessCode!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  initialize = (sessionId: string, accessCode: string): void => {
    this._sessionId = sessionId;
    this._accessCode = accessCode;
    this.db = new HiloDb(this._sessionId, this._accessCode);
    this.table = this.db.TransactionPaymetLocaldb;
  };

  async getAll(
    transactionId: string,
  ): Promise<TransactionPaymentConsolidate[]> {
    const encryptedTransactionPayments = await this.getPaymentsForTransaction(
      transactionId,
    );
    const transactionPackages = generateTransactionPaymentPackages(
      encryptedTransactionPayments as EncryptedTransactionPayment[],
    );
    return transactionPackages.map(
      transactionPackage => transactionPackage.payment,
    );
  }

  async getPaymentsForTransaction(
    transactionId: string,
  ): Promise<EncryptedTransactionPayment[]> {
    const encryptedTransactionPayments = await this.table
      .where({transactionId: transactionId})
      .toArray();
    return encryptedTransactionPayments as EncryptedTransactionPayment[];
  }

  async get(
    transactionPaymentId: string,
  ): Promise<TransactionPaymentConsolidate> {
    const transactionPaymentresult = await this.table.get(transactionPaymentId);

    if (!transactionPaymentresult) {
      return {} as TransactionPaymentConsolidate;
    }

    const transactionPaymentPackage = generateTransactionPaymentPackage(
      transactionPaymentresult as EncryptedTransactionPayment,
    );
    if (transactionPaymentPackage?.payment) {
      return transactionPaymentPackage.payment;
    } else {
      return {} as TransactionPaymentConsolidate;
    }
  }

  async getDbPackage(
    transactionPaymentId: string,
  ): Promise<TransactionPaymentDbPackage | null> {
    const transactionPaymentResult = await this.table.get(transactionPaymentId);
    if (!transactionPaymentResult) {
      return null;
    }

    const transactionPaymentPackage = generateTransactionPaymentPackage(
      transactionPaymentResult as EncryptedTransactionPayment,
    );
    if (!transactionPaymentPackage) {
      return null;
    }
    return transactionPaymentPackage;
  }

  async add(
    transactionPayment: TransactionPaymentConsolidate,
  ): Promise<TransactionPaymentConsolidate> {
    await this.table.put(transactionPayment);

    return transactionPayment;
  }

  async update(
    transactionPayment: TransactionPaymentConsolidate,
  ): Promise<TransactionPaymentConsolidate> {
    await this.table.update(transactionPayment.transactionPaymentId, {
      ...transactionPayment,
      secretByteArray: null,
      cipherTextByteArray: null,
    });

    return transactionPayment;
  }

  async delete(transactionPaymentId: string): Promise<void> {
    return this.table.delete(transactionPaymentId);
  }

  async deleteAll(): Promise<void> {
    this._consoleLogger.styledLog(
      'Delete All Transaction Payments',
      ConsoleLoggerVariant.PURPLE,
    );
    return this.table.clear();
  }
}
