import {TransactionExtra, TransactionItem} from '@emporos/api-enterprise';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  COMPLIANCE_DATA_KEY,
  OfflineSynced,
  useSessionData,
  useTransaction,
  withChildPage,
  useGlobalData,
  isPatientPayTransaction,
} from '../../../../../';
import {Compliance, COUNSEL_LATER, NO_COUNSELING, YES_INDEX} from './';

export interface ComplianceForm {
  counseling?: string | null;
  hipaa?: number | null;
  relationship?: string | null;
}

export const EMPTY_COMPLIANCE = {
  counseling: null,
  hipaa: null,
  relationship: null,
};

export enum NavigateTo {
  IDCheck,
  Transactions,
}

const mapExtrasFromCompliance = (
  extras: Array<TransactionExtra>,
  form: ComplianceForm,
): Array<TransactionExtra> => {
  return extras.map(extra =>
    extra.dataKey === COMPLIANCE_DATA_KEY
      ? {
          ...extra,
          isSynced: false,
          dataValue: JSON.stringify({...EMPTY_COMPLIANCE, ...form}),
        }
      : extra,
  );
};

export const ComplianceIntegration = memo(
  withChildPage(
    ({
      navigate,
      location,
    }: RouteComponentProps<{
      location: {
        state: {
          animatePanel?: boolean;
        };
      };
    }>): JSX.Element => {
      const {relationshipsResult} = useGlobalData();
      const {settingsResult} = useSessionData();
      const {NODE_ENV} = process.env;

      const {transaction, updateTransaction} = useTransaction();

      const isPtPayTransaction = isPatientPayTransaction(transaction);

      assert(
        navigate,
        '<ComplianceIntegration /> must have a `navigate` prop.' +
          String(NODE_ENV) ===
          'production'
          ? ''
          : ' This likely means that you need to have it as a direct child of a <Router />',
      );
      assert(transaction, 'Missing transaction');
      assert(settingsResult && settingsResult.data, 'Missing settingsResult');
      assert(
        relationshipsResult && relationshipsResult.data,
        'Missing relationshipsResult',
      );

      return (
        <Compliance
          transaction={transaction}
          defaultAnimatePanel={!location?.state.animatePanel}
          settings={settingsResult.data}
          relationships={relationshipsResult.data}
          navigate={navigate}
          onBack={navigateTo => {
            switch (navigateTo) {
              case NavigateTo.IDCheck:
                navigate('../id-check');
                break;
              case NavigateTo.Transactions:
                // time for animation to complete, then navigate
                setTimeout(() => navigate('../../'), 400);
            }
          }}
          onSubmit={(rawForm: ComplianceForm) => {
            // remap hipaa from the form index values to actual values
            const form = {
              ...rawForm,
              hipaa: rawForm.hipaa === YES_INDEX ? 1 : 0,
            };

            const {counseling, hipaa, relationship} = form;

            updateTransaction({
              extras: mapExtrasFromCompliance(transaction.extras, form),
              items: transaction.items.map(item =>
                item.rx
                  ? ({
                      ...item,
                      isSynced: false,
                      counselIndicator:
                        counseling && counseling !== NO_COUNSELING,
                      counselPromptIndicator: !!counseling,
                      counselLater: counseling === COUNSEL_LATER,
                      hipaaCompliant: hipaa,
                      hipaaRelationship: relationship,
                      rxRelationship: relationship,
                    } as TransactionItem & OfflineSynced)
                  : item,
              ),
            });

            return navigate(
              isPtPayTransaction
                ? '../acknowledgements'
                : '../customer-payment',
            );
          }}
        />
      );
    },
  ),
);
