import {User} from '@emporos/hilo-auth';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {IdleTimerProvider} from 'react-idle-timer';
import {
  getDeviceFingerprint,
  PreLogin,
  refreshAuthIfNecessary,
  TEST_USER_KEY,
} from '../';
import {useNetworkAvailable, useSettings} from './';
import {useOidcAuth} from './OidcAuthProvider';
import {DIFactory} from '../DIFactory';

interface Context {
  user: User | null;
  login: () => Promise<void>;
  logout: () => void;
  deviceId: string;
}

export const SESSION_TIMEOUT = 1000 * 60 * 10; // 10 minutes

export const AuthenticationContext = createContext<Context>({
  user: null,
  login: () => Promise.reject('Unable to find AuthenticationProvider.'),
  logout: () => () => {
    return;
  },
  deviceId: '',
});

interface Props {
  children: JSX.Element;
}

export function AuthenticationProvider({children}: Props): JSX.Element {
  const [deviceId, setDeviceId] = useState('');
  const {online, userNeedsRefreshed, setUserNeedsRefreshed} =
    useNetworkAvailable();
  const auth = useOidcAuth();
  const authStorageService = DIFactory.getAuthStorageService();
  const user: User = useMemo(() => {
    const serializedTestUser = localStorage.getItem(TEST_USER_KEY);
    return (
      auth.user || (serializedTestUser ? JSON.parse(serializedTestUser) : null)
    );
  }, [auth.user]);
  const refreshingUser = useRef(false);
  const {timeoutInMinutes} = useSettings();
  const timeoutInMiliseconds = timeoutInMinutes * 60 * 1000;

  useEffect(() => {
    function onVisibilityChange() {
      //When we go to payments domain or lock the device, this event is being triggered. So we want to avoid the issue of timers not being triggered when they should.
      if (window.document.visibilityState === 'visible') {
        const lastActiveLSTimeDate = localStorage.getItem(
          'Hilo_lastActiveTime',
        );
        if (lastActiveLSTimeDate) {
          const lastActiveTimeDate = new Date(lastActiveLSTimeDate);
          const currentTime = new Date();
          const diff = currentTime.getTime() - lastActiveTimeDate.getTime();
          if (diff > timeoutInMiliseconds) {
            logout();
          }
        }
      } else {
        localStorage.setItem('Hilo_lastActiveTime', new Date().toUTCString());
      }
    }
    localStorage.removeItem('Hilo_lastActiveTime');
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setDeviceId(getDeviceFingerprint());
  }, []);

  const login = useCallback(async () => {
    if (online) {
      await auth.signinRedirect();
    }
  }, [user, online]);

  const logout = useCallback(async () => {
    localStorage.removeItem('Hilo_lastActiveTime');
    localStorage.removeItem(TEST_USER_KEY);
    setUserNeedsRefreshed(null);
    if (online) (async () => await auth.signoutRedirect())();
    else auth.removeUser();
  }, [user, online]);

  const api = useMemo(
    () => ({
      user,
      login,
      logout,
      deviceId,
    }),
    [user, login, logout, deviceId],
  );

  useEffect(() => {
    if (online && userNeedsRefreshed && user && !refreshingUser.current) {
      refreshingUser.current = true;
      refreshAuthIfNecessary(
        online,
        user,
        userNeedsRefreshed,
        setUserNeedsRefreshed,
        auth.signinRefresh,
        authStorageService.storeUser,
        authStorageService.removeAuthInfo,
        logout,
      );
    }
  }, [logout, userNeedsRefreshed]);

  return (
    <AuthenticationContext.Provider value={api}>
      {user ? (
        <IdleTimerProvider
          // TODO: make timeout configurable
          timeout={timeoutInMiliseconds}
          onIdle={logout}
          debounce={250}
          crossTab={true}
        >
          {children}
        </IdleTimerProvider>
      ) : (
        <PreLogin onRedirect={login} />
      )}
    </AuthenticationContext.Provider>
  );
}
export const useAuthentication = (): Context =>
  useContext(AuthenticationContext);
