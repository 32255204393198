import {User} from '@emporos/hilo-auth';
import {IAuthStorageService} from '../services/IAuthStorageService';
import {DisplayUser, StoredUser, parseStoredUser} from '@emporos/components';
import {MockStore} from './MockStore';
import {
  StoredSession,
  generateStoredSessionString,
  parseStoredSession,
} from '../utils/session';
import {Session} from '@emporos/api-enterprise';
import {encryptPrependIV} from '../utils/crypto';

export class MockAuthLocalStorageService implements IAuthStorageService {
  storeUser = (user: User, accessCode: string) => {
    MockStore['user:' + user.profile['sub']] = this.genStoredUserString(
      user,
      accessCode,
    );
  };

  storeSession = (user: User, session: Session) => {
    MockStore['session:' + user.profile['sub']] =
      generateStoredSessionString(session);
  };

  storeAuthInfo = (user: User, accessCode: string, session: Session | null) => {
    this.storeUser(user, accessCode);
    if (session) this.storeSession(user, session);
  };

  removeUser = (user: User) => {
    this.removeUserBySub(user.profile['sub']);
  };

  removeUserBySub = (sub: string) => {
    delete MockStore['user:' + sub];
  };

  removeSession = (user: User) => {
    this.removeSessionBySub(user.profile['sub']);
  };

  removeSessionBySub = (sub: string) => {
    delete MockStore['session:' + sub];
  };

  removeAuthInfo = (user: User) => {
    this.removeUser(user);
    this.removeSession(user);
  };

  removeAuthInfoBySub = (sub: string) => {
    this.removeUserBySub(sub);
    this.removeSessionBySub(sub);
  };

  getStoredUser = (key: string, accessCode?: string): StoredUser | null => {
    const storedUserString = MockStore[key];
    if (storedUserString) {
      const storedUser = parseStoredUser(storedUserString, accessCode);
      return storedUser;
    }
    return null;
  };

  getStoredSession = (
    key: string,
    accessCode: string,
  ): StoredSession | null => {
    const storedSessionString = MockStore[key];
    if (storedSessionString) {
      const storedSession = parseStoredSession(storedSessionString, accessCode);
      return storedSession;
    }
    return null;
  };

  getAllStoredUsers = (): DisplayUser[] => {
    const userKeys = this.getKeysWithRegex('user:*');
    const sessionKeys = this.getKeysWithRegex('session:*');
    const users = new Array<DisplayUser>();
    userKeys.forEach(userKey => {
      const storedUser = this.getStoredUser(userKey);
      const sessionKey = userKey.replace('user:', 'session:');
      if (storedUser && sessionKeys.includes(sessionKey)) {
        users.push({...storedUser, userKey: userKey, sessionKey: sessionKey});
      }
    });
    return users;
  };

  getUser = (key: string, accessCode: string): User | null => {
    const storedUser = this.getStoredUser(key, accessCode);
    if (storedUser) {
      try {
        return User.fromStorageString(storedUser.user);
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  static testsetup_addItem = (key: string, value: string) => {
    MockStore[key] = value;
  };

  static testsetup_clearStore = () => {
    const keys = Object.keys(MockStore).filter(function (key) {
      return key;
    });
    keys.forEach(key => {
      delete MockStore[key];
    });
  };

  private getKeysWithRegex = (regexString: string): string[] => {
    const regex = new RegExp(regexString);
    const keys = Object.keys(MockStore).filter(function (key) {
      return regex.test(key);
    });
    return keys;
  };

  private genStoredUser = (user: User, accessCode: string): StoredUser => {
    return {
      user: encryptPrependIV(
        JSON.stringify({
          id_token: user.id_token,
          session_state: user.session_state,
          access_token: user.access_token,
          refresh_token: user.refresh_token,
          token_type: user.token_type,
          scope: user.scope,
          profile: user.profile,
          expires_at: user.expires_at,
        }),
        accessCode,
      ),
      name: user.profile[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ],
      iat: user.profile.iat,
    } as StoredUser;
  };

  private genStoredUserString = (user: User, accessCode: string): string => {
    const storedUser = this.genStoredUser(user, accessCode);
    return JSON.stringify(storedUser);
  };
}
