import CryptoJS from 'crypto-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {base64DecToArr, base64EncArr} from './base64';
import {TransactionConsolidate, TransactionPaymentConsolidate} from '../api';
import {Transaction} from '@emporos/api-enterprise';

export type EncryptedTransaction = TransactionConsolidate & {
  secretByteArray?: Uint8Array;
  cipherTextByteArray?: Uint8Array;
};

export type EncryptedTransactionPayment = TransactionPaymentConsolidate & {
  secretByteArray?: Uint8Array;
  cipherTextByteArray?: Uint8Array;
};

export type TransactionDbPackage = {
  transaction: TransactionConsolidate;
  secretByteArray: Uint8Array;
  cipherTextByteArray: Uint8Array;
};

export type TransactionPaymentDbPackage = {
  payment: TransactionPaymentConsolidate;
  secretByteArray: Uint8Array;
  cipherTextByteArray: Uint8Array;
};

/**
 * Encrypt a plain text string.
 *
 * @param plainText The plain-text string to be encrypted.
 * @param secret The plain-text Key.
 * @param iv The Base64 formatted IV.
 *
 * @return The resulting CipherParams object
 */
export function encrypt(
  plainText: string,
  secret: string,
  iv?: string,
): CryptoJS.lib.CipherParams {
  if (typeof iv !== 'undefined') {
    const iv_wordArray = CryptoJS.enc.Base64.parse(iv);
    return CryptoJS.AES.encrypt(plainText, secret, {iv: iv_wordArray});
  }

  return CryptoJS.AES.encrypt(plainText, secret);
}

/**
 * Decrypt an encrypted string that is in a Base64 format.
 *
 * @param cipherText The Base64 formatted encrypted string.
 * @param secret The Base64 formatted Key.
 * @param iv The Base64 formatted IV.
 *
 * @return The resulting plain-text string when decrypting with the specified Key and IV
 */
export function decrypt(
  cipherText: string,
  secret: string,
  iv: string,
): string {
  const iv_wordArray = CryptoJS.enc.Base64.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(cipherText, secret, {
    iv: iv_wordArray,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

/**
 * Encrypt a plain text string. IV is automatically generated and prepended to the Base64 encoded encrypted message.
 *
 * @param plainText The plain-text string to be encrypted.
 * @param secret The plain-text Key.
 *
 * @return The resulting Base64 encoded encrypted string.
 */
export function encryptPrependIV(plainText: string, secret: string): string {
  const encrypted = CryptoJS.AES.encrypt(plainText, secret);

  const ivBase64ConvertedToArray = base64DecToArr(
    CryptoJS.enc.Base64.stringify(encrypted.iv),
    1,
  );
  const ctBase64ConvertedToArray = base64DecToArr(encrypted.toString(), 1);

  //Prepend the IV to the encrypted message
  const data = new Uint8Array(
    ivBase64ConvertedToArray.length + ctBase64ConvertedToArray.length,
  );
  data.set(ivBase64ConvertedToArray);
  data.set(ctBase64ConvertedToArray, ivBase64ConvertedToArray.length);
  const returnValue = base64EncArr(data);
  return returnValue;
}

/**
 * Decrypt an encrypted string that is in a Base64 format. IV is expected to be prepended in the encrypted string.
 *
 * @param cipherText The Base64 formatted encrypted string.
 * @param secret The plain-text Key.
 *
 * @return The resulting plain-text string when decrypting with the specified Key.
 */
export function decryptPrependedIV(
  encryptedMessage: string,
  secret: string,
): string {
  const cipherTextByteArray = base64DecToArr(encryptedMessage, 1);

  //Split IV from the actual encrypted message
  const iv = cipherTextByteArray.slice(0, 16);
  const message = cipherTextByteArray.slice(16, cipherTextByteArray.length);

  //Convert IV to a CryptoJS WordArray so it can be used
  const iv_wordArray = CryptoJS.enc.Base64.parse(base64EncArr(iv));
  const cipherText = base64EncArr(message);

  const decryptedMessage = CryptoJS.AES.decrypt(cipherText, secret, {
    iv: iv_wordArray,
  }).toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}

export function encryptionMiddlewareFunction(
  key: Uint8Array,
  // eslint-disable-next-line
  objectToEncrypt: any,
): Uint8Array {
  const stringified = JSON.stringify(objectToEncrypt);
  const keyWordArray = CryptoJS.enc.Base64.parse(base64EncArr(key));

  //Specify an IV so CryptoJS will accept the key as a
  //WordArray, which prevents the key from being hashed again.
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const configOptions = {
    keySize: 256 / 32,
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };

  const encrypted = CryptoJS.AES.encrypt(
    stringified,
    keyWordArray,
    configOptions,
  );

  const ivBase64ConvertedToArray = base64DecToArr(
    CryptoJS.enc.Base64.stringify(encrypted.iv),
    1,
  );
  const ctBase64ConvertedToArray = base64DecToArr(encrypted.toString(), 1);

  //Prepend the IV to the encrypted message
  const data = new Uint8Array(
    ivBase64ConvertedToArray.length + ctBase64ConvertedToArray.length,
  );
  data.set(ivBase64ConvertedToArray);
  data.set(ctBase64ConvertedToArray, ivBase64ConvertedToArray.length);

  return data;
}

export function passthroughMiddlewareFunction(
  secretByteArray: Uint8Array,
  cipherTextByteArray: Uint8Array,
) {
  return {secretByteArray, cipherTextByteArray};
}

export function generateTransactionPackage(
  encryptedTransaction: EncryptedTransaction,
): TransactionDbPackage | null {
  const {secretByteArray, cipherTextByteArray} = encryptedTransaction;
  const decryptedTransaction = decryptTransaction(encryptedTransaction);
  if (!decryptedTransaction) return null;
  const transactionDbPackage: TransactionDbPackage = {
    transaction: decryptedTransaction,
    secretByteArray: secretByteArray,
    cipherTextByteArray: cipherTextByteArray,
  } as TransactionDbPackage;
  return transactionDbPackage;
}

export function generateTransactionPackages(
  encryptedTransactions: EncryptedTransaction[],
): TransactionDbPackage[] {
  const transactionPackages: TransactionDbPackage[] = [];
  encryptedTransactions.forEach(encryptedTransaction => {
    const transactionPackage = generateTransactionPackage(encryptedTransaction);
    if (transactionPackage) {
      transactionPackages.push(transactionPackage);
    }
  });
  return transactionPackages;
}

export function generateTransactionPaymentPackage(
  encryptedTransactionPayment: EncryptedTransactionPayment,
): TransactionPaymentDbPackage | null {
  const {secretByteArray, cipherTextByteArray} = encryptedTransactionPayment;
  const decryptedTransactionPayment = decryptTransactionPayment(
    encryptedTransactionPayment,
  );
  if (!decryptedTransactionPayment) return null;
  const transactionPaymentDbPackage: TransactionPaymentDbPackage = {
    payment: decryptedTransactionPayment,
    secretByteArray: secretByteArray,
    cipherTextByteArray: cipherTextByteArray,
  } as TransactionPaymentDbPackage;
  return transactionPaymentDbPackage;
}

export function generateTransactionPaymentPackages(
  encryptedTransactionPayments: EncryptedTransactionPayment[],
): TransactionPaymentDbPackage[] {
  const transactionPaymentPackages: TransactionPaymentDbPackage[] = [];
  encryptedTransactionPayments.forEach(encryptedTransactionPayment => {
    const transactionPaymentPackage = generateTransactionPaymentPackage(
      encryptedTransactionPayment,
    );
    if (transactionPaymentPackage) {
      transactionPaymentPackages.push(transactionPaymentPackage);
    }
  });
  return transactionPaymentPackages;
}

export function decryptTransaction(
  encryptedTransaction: TransactionConsolidate,
): TransactionConsolidate | null {
  const secretByteArray = (encryptedTransaction as EncryptedTransaction)
    .secretByteArray as Uint8Array;
  const cipherTextByteArray = (encryptedTransaction as EncryptedTransaction)
    .cipherTextByteArray as Uint8Array;
  if (!secretByteArray || !cipherTextByteArray) return null;
  const decryptedTransaction = decryptToObject<TransactionConsolidate>(
    secretByteArray,
    cipherTextByteArray,
  );
  if (decryptedTransaction) {
    const constructedTransaction = {
      ...(decryptedTransaction as Transaction),
      // these are the keys not encrypted according to app-pos\src\localDb\dbcontext.ts
      transactionId:
        encryptedTransaction?.transactionId ??
        decryptedTransaction.transactionId,
      saleDate: new Date(decryptedTransaction.saleDate),
      sessionId:
        encryptedTransaction?.sessionId ?? decryptedTransaction.sessionId,
      status: encryptedTransaction?.status ?? decryptedTransaction.status,
      isDeleted:
        encryptedTransaction?.isDeleted ?? decryptedTransaction.isDeleted,
      isSynced: encryptedTransaction?.isSynced ?? decryptedTransaction.isSynced,
      recordStatus:
        encryptedTransaction?.recordStatus ?? decryptedTransaction.recordStatus,
      serverTransactionID:
        encryptedTransaction?.serverTransactionID ??
        decryptedTransaction.serverTransactionID,
      secretByteArray: null,
      cipherTextByteArray: null,
    } as TransactionConsolidate;
    if (constructedTransaction.signatures)
      constructedTransaction.signatures.forEach(signature => {
        signature.createdOn = new Date(signature.createdOn);
      });
    return constructedTransaction;
  } else {
    return null;
  }
}

export function decryptTransactionPayment(
  encryptedTransactionPayment: TransactionPaymentConsolidate,
): TransactionPaymentConsolidate | null {
  const secretByteArray = (
    encryptedTransactionPayment as EncryptedTransactionPayment
  ).secretByteArray as Uint8Array;
  const cipherTextByteArray = (
    encryptedTransactionPayment as EncryptedTransactionPayment
  ).cipherTextByteArray as Uint8Array;
  if (!secretByteArray || !cipherTextByteArray) return null;
  const decryptedTransactionPayment =
    decryptToObject<TransactionPaymentConsolidate>(
      secretByteArray,
      cipherTextByteArray,
    );
  if (decryptedTransactionPayment) {
    return {
      ...decryptedTransactionPayment,
      // these are the keys not encrypted according to app-pos\src\localDb\dbcontext.ts
      transactionId:
        encryptedTransactionPayment?.transactionId ??
        decryptedTransactionPayment.transactionId,
      transactionPaymentId:
        encryptedTransactionPayment?.transactionPaymentId ??
        decryptedTransactionPayment.transactionPaymentId,
      transactionPaymentStatus:
        encryptedTransactionPayment?.transactionPaymentStatus ??
        decryptedTransactionPayment.transactionPaymentStatus,
      recordStatus:
        encryptedTransactionPayment?.recordStatus ??
        decryptedTransactionPayment.recordStatus,
      secretByteArray: null,
      cipherTextByteArray: null,
    } as TransactionPaymentConsolidate;
  } else {
    return null;
  }
}

export function decryptToObject<T>(
  secret: Uint8Array | string,
  encryptedData: Uint8Array | string,
): T {
  const decryptedString = decryptToString(secret, encryptedData);
  //Parse the decrypted value back into an object
  const result = JSON.parse(decryptedString);

  return result;
}

export function decryptToString(
  secret: Uint8Array | string,
  encryptedData: Uint8Array | string,
): string {
  let secretString = '';
  let cipherTextByteArray: Uint8Array;

  if (typeof secret === 'string') {
    secretString = secret;
  } else {
    secretString = base64EncArr(secret);
  }

  if (typeof encryptedData === 'string') {
    cipherTextByteArray = base64DecToArr(encryptedData, 1);
  } else {
    cipherTextByteArray = encryptedData;
  }

  const key_wordArray = CryptoJS.enc.Base64.parse(secretString);

  //Split IV from the actual encrypted message
  const iv = cipherTextByteArray.slice(0, 16);
  const message = cipherTextByteArray.slice(16, cipherTextByteArray.length);

  //Convert IV to a CryptoJS WordArray so it can be used
  const iv_wordArray = CryptoJS.enc.Base64.parse(base64EncArr(iv));
  const cipherText = base64EncArr(message);

  const result = CryptoJS.AES.decrypt(cipherText, key_wordArray, {
    iv: iv_wordArray,
  }).toString(CryptoJS.enc.Utf8);

  return result;
}

/**
 * Performance of Crypto-JS' PBKDF2 is probably one of the worst performing
 * and should be replaced so that the number of iterations can be increased
 * and be closer to the performace you can get with other libraries.
 *
 * See "https://dominictarr.github.io/crypto-bench/" for benchmarks.
 * Crypto-JS has a non-linear increase when increasing iterations which
 * means there's something wrong with it. The article is older, but the
 * non-linear increase with a higher number of iterations was immediately
 * noticable and was what prompted a quick search on the issue.
 */
export function generateKey(plainTextKey: string, salt: string): string {
  return CryptoJS.PBKDF2(plainTextKey, salt, {
    keySize: 256 / 32,
    iterations: 1000,
  }).toString(CryptoJS.enc.Base64);
}
