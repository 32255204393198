import {Navbar} from '@emporos/components-pos';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo, useCallback} from 'react';
import {
  AuthClaim,
  useAuthentication,
  useSyncSession,
  useTransactionsConfig,
} from '../../../';
import {CloseSession} from './';

function CloseSessionContainerComponent(_: RouteComponentProps) {
  const {user, logout} = useAuthentication();
  const {sessionStatus, syncSession} = useSyncSession();
  const {setSessionClosed} = useTransactionsConfig();

  const onNewSession = useCallback(() => {
    setSessionClosed(false);
  }, []);

  assert(
    user !== null,
    'Internal Error: tried to render CloseSession without an authenticated user',
  );

  return (
    <>
      <Navbar
        username={user.profile[AuthClaim.Name] as string}
        onSessionStatus={syncSession}
        sessionStatus={sessionStatus}
        isLoadingData={false}
        onLogout={logout}
        hideSessionStatus={true}
      />
      <CloseSession onLogout={logout} onNewSession={onNewSession} />
    </>
  );
}

export const CloseSessionContainer = memo(CloseSessionContainerComponent);
