import {
  Stack,
  PriceFooter,
  FooterGroup,
  Button,
  Variant,
  VerificationModal,
} from '@emporos/components';
import {NavigateFn} from '@reach/router';
import {TotalsResult, useSignatures} from '../../../../hooks';
import {
  StationType,
  Transaction,
  TransactionUpdates,
  useAlertState,
  useBetaFeatures,
  useNetworkAvailable,
} from '../../../../contexts';
import {mapComplianceIndicators, mapIdCheckIndicators} from '../payments';
import {Setting} from '@emporos/api-enterprise';
import {mergeTaxes} from '../../../../utils/taxes';
import {OfflineSynced, OfflineTransaction} from '../../../../api';
import {useCallback, useState} from 'react';
import {normalizeCustomer} from '../../../../utils';
import {isMailout} from '../../../../utils/session';

interface PatientPayFooterProps {
  totals: TotalsResult;
  transaction: Transaction;
  settings: Setting[];
  stationType: StationType;
  onUpdateTransaction: (updates: TransactionUpdates) => void;
  navigate: NavigateFn;
}

export const PatientPayFooter = ({
  totals,
  transaction,
  settings,
  stationType,
  onUpdateTransaction,
  navigate,
}: PatientPayFooterProps) => {
  const {online} = useNetworkAvailable();
  const {notification} = useAlertState();
  const {verificationModal} = useBetaFeatures();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const useMailoutFlow = isMailout(stationType);

  const {isNplex, isControlledSubstance, isRequiredAge, isIDRequiredOTC} =
    mapIdCheckIndicators(transaction);

  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const showPaymentsPage = totals.totalDue != 0;
  const showIdCapturePage =
    !transaction.identification &&
    ((isNplex && transaction.pseCheckResult !== 1) ||
      isControlledSubstance ||
      isRequiredAge ||
      isIDRequiredOTC);
  const showCompliancePage = showCounsel || showHipaa || showRelationship;
  const showSignaturePage = !!useSignatures().length;
  const nextPageLocation = () => {
    if (showPaymentsPage) return './payments/customer-payment';
    if (useMailoutFlow) return './payments/customer-payment';
    else if (showIdCapturePage) return './payments/id-check';
    else if (showCompliancePage) return './payments/compliance';
    else if (showSignaturePage) return './payments/acknowledgements';
    else return './payments/receipts';
  };

  const continueTransaction = () => {
    if (isNplex && transaction.pseCheckResult !== 1 && !online) {
      notification({
        title: 'Offline Error: PSE Items',
        description:
          'This transaction cannot move forward while offline with unapproved PSE items in the cart.',
        type: 'warning',
        icon: 'Warning',
      });
      return;
    }
    navigate(nextPageLocation(), {
      state: {animatePanel: true},
    });
  };

  const updateTransaction = useCallback(() => {
    setShowVerificationModal(false);
    onUpdateTransaction({
      isVerified: true,
    } as Partial<Transaction> & OfflineSynced);
    return continueTransaction();
  }, [totals]);

  const continueHandler = () => {
    setShowVerificationModal(false);

    if (
      !useMailoutFlow &&
      transaction.items.filter(({rx}) => rx).length > 0 &&
      !(transaction as OfflineTransaction)?.isVerified
    ) {
      setShowVerificationModal(true);
    } else {
      setShowVerificationModal(false);

      onUpdateTransaction({
        isSynced: false,
        taxableSubTotal: totals.taxableSubTotal,
        discount: totals.discount,
        subTotal: totals.subTotal,
        totalSale: totals.transactionTotal,
        totalTax: totals.salesTax,
        qhpRxAmount: totals.qhpRxAmount,
        qhpAmount: totals.qhpAmount,
        qhpRxQty: totals.qhpRxQty,
        qhpOtherAmount: totals.qhpOtherAmount,
        qhpOtherQty: totals.qhpOtherQty,
        taxes: mergeTaxes(transaction.taxes, totals.transactionTaxes),
      } as Partial<Transaction> & OfflineSynced);
      continueTransaction();
    }
  };

  const proceedToNextHandler = () => {
    if (!showPaymentsPage && (showIdCapturePage || showCompliancePage)) {
      continueHandler();
    } else {
      navigate(nextPageLocation(), {
        state: {animatePanel: true},
      });
    }
  };

  return (
    <>
      <Stack style={{marginTop: 0}}>
        <PriceFooter totals={totals} style={{marginBottom: 16, marginTop: 0}} />
        <FooterGroup>
          <Button
            data-testid="leave-in-queue-btn"
            variant={Variant.Secondary}
            flex
            onClick={() => navigate('/sales')}
          >
            Leave in Queue
          </Button>
          <Button
            flex
            data-testid="proceed-compliance-btn"
            onClick={proceedToNextHandler}
          >
            Proceed to{' '}
            {showPaymentsPage || useMailoutFlow
              ? 'Payments'
              : showIdCapturePage || showCompliancePage
              ? 'Compliance'
              : showSignaturePage
              ? 'Signature'
              : 'Receipt'}
          </Button>
        </FooterGroup>
      </Stack>
      {verificationModal && transaction.customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(transaction.customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={updateTransaction}
        />
      )}
    </>
  );
};
