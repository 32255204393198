import {
  Transaction,
  TransactionItem,
  OtcItem,
  Prescription,
} from '@emporos/api-enterprise';
import {OfflineTransactionItem, OfflineSynced} from '../';
import {
  mapOTCTransactionItem,
  mapPrescriptionTransactionItem,
  updateQuantity,
} from './';

export const createTransactionPrescriptionReducer =
  (transaction: Transaction, defaultTaxGroupId?: number) =>
  (
    acc: Array<TransactionItem>,
    prescription: Prescription,
  ): TransactionItem[] =>
    createTransactionReducer(
      acc,
      prescription.itemNumber || '',
      () =>
        mapPrescriptionTransactionItem(
          transaction.transactionId,
          prescription,
          defaultTaxGroupId,
        ),
      () =>
        ({isVerified: true, isSynced: false} as OfflineTransactionItem &
          OfflineSynced),
    );

export const createTransactionOtcReducer =
  (transaction: Transaction) =>
  (acc: Array<TransactionItem>, otcItem: OtcItem): TransactionItem[] =>
    createTransactionReducer(
      acc,
      otcItem.itemNumber || '',
      () => mapOTCTransactionItem(transaction.transactionId, otcItem),
      prevTransactionItem => ({
        ...updateQuantity(
          prevTransactionItem,
          prevTransactionItem.quantity + 1,
        ),
        isSynced: false,
      }),
    );

const createTransactionReducer = (
  transactionItems: TransactionItem[],
  itemNumber: string,
  onNew: () => TransactionItem,
  onUpdate: (prevTransactionItem: TransactionItem) => Partial<TransactionItem>,
): TransactionItem[] => {
  const index = transactionItems.findIndex(
    ({itemNumber: _itemNumber}) => itemNumber === _itemNumber,
  );
  if (index > -1) {
    const transactionItem = transactionItems[index];
    transactionItems[index] = {
      ...transactionItem,
      ...onUpdate(transactionItem),
    };
  } else {
    transactionItems.push(onNew());
  }
  return transactionItems;
};
