import {HTTPHeaders, HTTPQuery, RequiredError} from '@emporos/api-enterprise';
import {
  EncryptedTransaction,
  EncryptedTransactionPayment,
} from '../utils/crypto';
import {EncryptedBaseApi} from './EncryptedBaseApi';

export class EncryptedNonSessionSyncApi extends EncryptedBaseApi {
  /**
   * Syncs a Transaction for the user not logged in
   */
  async SyncSignatureImageFromTransactionRaw(
    encryptedTransaction: EncryptedTransaction,
  ): Promise<boolean> {
    if (
      encryptedTransaction.sessionId === null ||
      encryptedTransaction.sessionId === undefined
    ) {
      throw new RequiredError(
        'sessionId',
        'Required parameter encryptedTransaction.sessionId was null or undefined when calling SyncNonUserTransaction.',
      );
    }

    if (
      encryptedTransaction.transactionId === null ||
      encryptedTransaction.transactionId === undefined
    ) {
      throw new RequiredError(
        'transactionId',
        'Required parameter encryptedTransaction.transactionId was null or undefined when calling SyncNonUserTransaction.',
      );
    }

    if (
      encryptedTransaction.serverTransactionID === null ||
      encryptedTransaction.serverTransactionID === undefined
    ) {
      throw new RequiredError(
        'serverTransactionID',
        'Required parameter encryptedTransaction.serverTransactionID was null or undefined when calling SyncNonUserTransaction.',
      );
    }

    if (
      encryptedTransaction.cipherTextByteArray === null ||
      encryptedTransaction.cipherTextByteArray === undefined
    ) {
      throw new RequiredError(
        'cipherTextByteArray',
        'Required parameter requestParameters.txStatus was null or undefined when calling SyncNonUserTransaction.',
      );
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(
      encryptedTransaction.sessionId,
    );

    const response = await this.MakeRequest(
      `/client/cache/signatures/images`,
      headerParameters,
      queryParameters,
      'POST',
      this.ConvertToEncodedString(encryptedTransaction.cipherTextByteArray),
    );

    return response.status === 200;
  }

  /**
   * Syncs a Transaction for the user not logged in
   */
  async SyncSignatureImageFromTransaction(
    encryptedTransaction: EncryptedTransaction,
  ): Promise<boolean> {
    const response = await this.SyncSignatureImageFromTransactionRaw(
      encryptedTransaction,
    );
    return response;
  }

  /**
   * Syncs a TransactionPayment for the user not logged in
   */
  async SyncNonUserTransactionPaymentRaw(
    encryptedTransactionPayment: EncryptedTransactionPayment,
    sessionId: string,
    transactionId: string,
  ): Promise<boolean> {
    if (sessionId === null || sessionId === undefined) {
      throw new RequiredError(
        'sessionId',
        'Required parameter sessionId was null or undefined when calling SyncNonUserTransactionPayment.',
      );
    }

    if (transactionId === null || transactionId === undefined) {
      throw new RequiredError(
        'transactionId',
        'Required parameter transactionId was null or undefined when calling SyncNonUserTransactionPayment.',
      );
    }

    if (
      encryptedTransactionPayment.siteId === null ||
      encryptedTransactionPayment.siteId === undefined
    ) {
      throw new RequiredError(
        'siteId',
        'Required parameter encryptedTransactionPayment.siteId was null or undefined when calling SyncNonUserTransactionPayment.',
      );
    }

    if (
      encryptedTransactionPayment.stationId === null ||
      encryptedTransactionPayment.stationId === undefined
    ) {
      throw new RequiredError(
        'stationId',
        'Required parameter encryptedTransactionPayment.stationId was null or undefined when calling SyncNonUserTransactionPayment.',
      );
    }

    if (
      encryptedTransactionPayment.cipherTextByteArray === null ||
      encryptedTransactionPayment.cipherTextByteArray === undefined
    ) {
      throw new RequiredError(
        'cipherTextByteArray',
        'Required parameter encryptedTransactionPayment.cipherTextByteArray was null or undefined when calling SyncNonUserTransactionPayment.',
      );
    }

    if (
      encryptedTransactionPayment.customerId === null ||
      encryptedTransactionPayment.customerId === undefined
    ) {
      encryptedTransactionPayment.customerId = 0;
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions/{transactionId}/payments/sync/{siteId}/{stationId}/{customerId}`
        .replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)))
        .replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(transactionId)),
        )
        .replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(encryptedTransactionPayment.siteId)),
        )
        .replace(
          `{${'stationId'}}`,
          encodeURIComponent(String(encryptedTransactionPayment.stationId)),
        )
        .replace(
          `{${'customerId'}}`,
          encodeURIComponent(String(encryptedTransactionPayment.customerId)),
        ),
      headerParameters,
      queryParameters,
      'POST',
      this.ConvertToEncodedString(
        encryptedTransactionPayment.cipherTextByteArray,
      ),
    );

    return response.status === 200 || response.status === 201;
  }

  /**
   * Syncs a TransactionPayment for the user not logged in
   */
  async SyncNonUserTransactionPayment(
    encryptedTransactionPayment: EncryptedTransactionPayment,
    sessionId: string,
    transactionId: string,
  ): Promise<boolean> {
    const response = await this.SyncNonUserTransactionPaymentRaw(
      encryptedTransactionPayment,
      sessionId,
      transactionId,
    );
    return response;
  }
}
