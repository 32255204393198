import {TransactionItem} from '@emporos/api-enterprise';
import {CompleteBarcodeComponent} from '@emporos/barcodes';
import {Redirect} from '@reach/router';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {
  AddItemsResult,
  createTransactionOtcReducer,
  OfflineTransaction,
  OtcItemsPanel,
  useCustomerSearch,
  useSessionData,
  useNetworkAvailable,
  usePendingTransaction,
  useTransactionsState,
  withChildPage,
} from '../../../../';

const OtcSearchPageComponent = withChildPage(function (props) {
  const {navigate} = props;
  const {currentTransactionId} = useTransactionsState();

  if (!currentTransactionId) {
    return <Redirect to="/sales" noThrow />;
  }

  const {online} = useNetworkAvailable();
  const {pmsName, barcodeName, barcodesResult, otcItemsResult} =
    useSessionData();
  const {customer: searchCustomer} = useCustomerSearch();
  const onCancel = useCallback(() => navigate('../'), [navigate]);
  const {transaction, commit, tryAttachCustomer, transactionChanged} =
    usePendingTransaction();
  const customer = useMemo(() => transaction.customer, [transaction]);
  const barcodeComponents = useMemo(
    () =>
      barcodesResult && barcodesResult.data
        ? (barcodesResult.data.barcodeComponents.filter(
            barcodeComponent => barcodeComponent.barcodeName === barcodeName,
          ) as CompleteBarcodeComponent[])
        : [],
    [barcodesResult],
  );

  useEffect(() => {
    if (searchCustomer) {
      tryAttachCustomer(searchCustomer);
    }
  }, [searchCustomer]);

  const onAdd = useCallback(
    (addItemsResult: AddItemsResult) => {
      const {otc} = addItemsResult;
      let items: TransactionItem[] = [...transaction.items];
      if (otc) {
        items = otc.reduce(createTransactionOtcReducer(transaction), items);
      }
      commit({items});
      navigate('../');
    },
    [navigate, transaction, customer],
  );

  return (
    <>
      <OtcItemsPanel
        barcodeComponents={barcodeComponents}
        currentTransactionId={currentTransactionId}
        transaction={transaction as OfflineTransaction}
        transactionChanged={transactionChanged}
        onAdd={onAdd}
        onCancel={onCancel}
        otcItems={otcItemsResult?.data || []}
        online={online}
        pmsName={pmsName}
        title="OTC Search"
      />
    </>
  );
});

export const OtcSearchPage = memo(OtcSearchPageComponent);
