import {
  Customer,
  CustomerAccount,
  Transaction,
  TransactionItem,
  Prescription,
  WillCallRequest,
} from '@emporos/api-enterprise';
import {
  CompleteBarcodeComponent,
  ScanResult,
  usePMSStrategy,
} from '@emporos/barcodes';
import {
  Button,
  CardOnFile,
  Carousel,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Numberpad,
  PendingChangesModal,
  Row,
  RowItem,
  ScrollContainer,
  SegmentSlider,
  Stack,
  Text,
  TextInput,
  TextVariant as TV,
  Variant,
  theme,
  Size,
} from '@emporos/components';
import {PageBarcodeScanner} from '@emporos/components-pos';
import assert from 'assert';
import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {
  ars,
  createTransactionPrescriptionReducer,
  formatMedication,
  isRxItem,
  ManualRXFormData,
  OfflineCustomer,
  OfflineTransaction,
  OfflineSynced,
  pds,
  useAlertState,
  useSessionData,
  WillCallPanel,
  useAuthentication,
  useTransaction,
  useTransactionsState,
  useGlobalSettings,
} from '../../../';
import {IsPrescriptionReady} from '../../../utils/prescription';
import {PaymentService} from '../../../services/PaymentService';
import {usePaymentsWindow} from '../../../contexts/PaymentsWindowProvider';
import {
  DeleteConfirmationModal,
  SuccessModal,
  ErrorModal,
  ProcessingModal,
} from './CustomerInfoPanelModals'; // Import modals from the new file
import {useIdleTimerContext} from 'react-idle-timer';

interface Props {
  barcodeComponents?: CompleteBarcodeComponent[];
  forceCanSave?: boolean;
  initialPrescription?: ManualRXFormData | null;
  transaction: OfflineTransaction;
  transactionChanged?: boolean;
  loadingCustomer?: boolean;
  online?: boolean;
  pmsName?: string;
  taxGroupId?: number;
  viewOnly?: boolean;
  onAddCustomerClick?: () => void;
  onCancel: () => void;
  onSave: (updates: Partial<Transaction>) => void;
  onUnresolvedRxScan?: (willCallParams: WillCallRequest) => void;
}

const findRx = (prescriptions: Prescription[], item: ManualRXFormData) =>
  prescriptions.find(
    ({fill, partial, rx, site}) =>
      fill === item.fill &&
      partial === item.partial &&
      rx === item.rx &&
      site === item.site,
  );

const willCallError = new Error(
  'Customer prescription info could not be pulled down. Please try again.',
);
const mismatchedError = new Error(
  'This prescription doesn’t match the customer attached to the sale.',
);
const notFoundError = new Error(
  'This prescription cannot be found on the customer’s Will Call.',
);
const doubleScanError = (medication: string) => {
  const doubleScan = new Error(
    `${formatMedication(medication)} has already been scanned on this sale.`,
  );
  doubleScan.name = 'Prescription Already Scanned';
  return doubleScan;
};
const notReadyError = (medication: string) => {
  const notReady = new Error(
    `${formatMedication(medication)} is not ready for pickup.`,
  );
  notReady.name = 'Prescription Not Ready';
  return notReady;
};

const Footer = (onCancel: () => void, canSave: boolean, loading: boolean) => {
  return (
    <div style={{marginTop: 'auto'}}>
      <FooterGroup>
        <Button
          type="button"
          onClick={onCancel}
          variant={canSave ? Variant.Danger : Variant.Secondary}
          flex
        >
          Cancel
        </Button>
        <Button
          variant={Variant.Primary}
          type="submit"
          flex
          loading={loading}
          disabled={!canSave}
        >
          Save
        </Button>
      </FooterGroup>
    </div>
  );
};

const RenderEmptyRow = (type: string) => (
  <RowItem title="None" variant="inverted" data-testid={`no-${type}`} />
);

const ARAccounts = (arAccounts: CustomerAccount[]) =>
  !arAccounts.length
    ? RenderEmptyRow('ar')
    : arAccounts.map(ar => (
        <RowItem
          key={ar.accountNumber}
          title={ar.accountNumber}
          subtitle={`Limit: $${ar.creditLimit.toFixed(2)}`}
          rightText={`Due: $${ar.balance.toFixed(2)}`}
          leftIcon="Bill"
          leftIconColor="indigo"
          noClick
        />
      ));

const CreditCards = (
  customer: Customer | undefined,
  onDelete: (cardId: string) => void,
  onUpdate: (cardId: string) => void,
  online?: boolean,
) => {
  const elements: JSX.Element[] = [];

  if (customer && customer.creditCards && customer.creditCards.length) {
    // Sort credit cards by the isPrimary field
    const sortedCreditCards = customer.creditCards.sort(
      (a, b) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0),
    );

    sortedCreditCards.forEach((cc, i) =>
      elements.push(
        <CardOnFile
          cardToken={cc.token}
          cardHolder={cc.member}
          expirationDate={formatExpDate(cc.expiration)}
          lastFour={cc.last4OfCard}
          cardType={cc.cardType}
          nickName={cc.nickName}
          isDefault={cc.isPrimary}
          isExpired={compareDates(cc.expiration)}
          isSelected={false}
          key={i}
          showLowerRow={false}
          onDelete={() => onDelete(cc.token)}
          onEdit={() => onUpdate(String(cc.paymentInformationId))}
          online={online}
        ></CardOnFile>,
      ),
    );
  } else {
    elements.push(RenderEmptyRow('cc'));
  }

  return elements;
};

const CCRow = styled('div')`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.gray_200};
`;

const formatExpDate = (date: Date) => {
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString().slice(2);
  const exp = month + '/' + year;
  return exp;
};

const compareDates = (cardExp: Date) => {
  const today = new Date();
  const isExpired = cardExp < today;
  return isExpired;
};

const PDAccounts = (pdAccounts: CustomerAccount[]) =>
  !pdAccounts.length
    ? RenderEmptyRow('pd')
    : pdAccounts.map(pd => (
        <RowItem
          key={pd.accountNumber}
          title={pd.accountNumber}
          subtitle={`Limit: $${pd.creditLimit.toFixed(2)}`}
          rightText={`Due: $${pd.balance.toFixed(2)}`}
          leftIcon="IdCard"
          leftIconColor="indigo"
          noClick
        />
      ));

const CustomerInfoPanelComponent = ({
  barcodeComponents,
  forceCanSave = false,
  initialPrescription,
  taxGroupId,
  transaction,
  transactionChanged,
  loadingCustomer = false,
  online,
  pmsName,
  viewOnly = false,
  onCancel,
  onSave,
  onAddCustomerClick,
  onUnresolvedRxScan,
}: Props): JSX.Element | null => {
  const {notification, reset} = useAlertState();
  const [active, setActive] = useState(0);
  const [transactionNotes, setTransactionNotes] = useState(
    transaction.notes || '',
  );
  const [roomNumber, setRoomNumber] = useState(transaction.roomNumber || '');
  const [selectedRX, setSelectedPrescriptions] = useState<Array<string>>([]);
  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);
  const {settingsResult, skipWillCall, setSkipWillCall} = useSessionData();
  const {strategy: pmsStrategy} = usePMSStrategy();
  const {customer} = transaction;
  const [customerHasCards, setCustomerHasCards] = useState(
    customer?.creditCards.length ? true : false,
  );
  const {paymentsWindowService} = usePaymentsWindow();
  const {user} = useAuthentication();
  const {session} = useTransactionsState();
  const {tenantId} = useGlobalSettings();
  const token = user ? user.access_token : '';
  const paymentService = new PaymentService(token, tenantId);
  const {transaction: trx} = useTransaction();
  const {pause: idleTimerPause} = useIdleTimerContext();
  const [deleteCardId, setDeleteCardId] = useState<string | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showProcessingModal, setshowProcessingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [updatedCustomer, setUpdatedCustomer] = useState<Customer | undefined>(
    customer,
  );

  const handleDeleteCard = async () => {
    if (!deleteCardId) return;
    setShowDeleteConfirmation(false);
    try {
      setshowProcessingModal(true);
      await paymentService.deleteCreditCard(transaction, deleteCardId);

      setshowProcessingModal(false);
      setSuccessMessage('Credit card deleted successfully.');
      setShowSuccessModal(true);
      // Remove the deleted card from the transaction
      if (updatedCustomer) {
        const newCustomer = {
          ...updatedCustomer,
          creditCards: updatedCustomer.creditCards?.filter(
            card => card.token !== deleteCardId,
          ),
        };
        setUpdatedCustomer(newCustomer); // Update the state with the new customer object
      }
    } catch (error) {
      setshowProcessingModal(false);
      setErrorMessage(error instanceof Error ? error.message : 'Unknown error');
      setShowErrorModal(true);
    } finally {
      setShowDeleteConfirmation(false);
      await paymentsWindowService.open();

      await paymentsWindowService.messageCallback({
        data: {type: 'navigate', data: {status: 'success'}},
      } as MessageEvent);

      await paymentsWindowService.close();
    }
  };

  const handleUpdateCard = async (ccofId: string) => {
    if (!ccofId) return;
    try {
      await paymentService.UpdateCreditCard(
        transaction,
        paymentsWindowService,
        ccofId,
      );
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'Unknown error');
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleOpenDeleteConfirmation = (cardId: string) => {
    setDeleteCardId(cardId);
    setShowDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setDeleteCardId(null);
  };

  useEffect(() => {
    setCustomerHasCards(trx.customer?.creditCards.length ? true : false);
  }, [trx.customer]);

  const creditCards = useMemo(() => {
    return CreditCards(
      trx.customer,
      handleOpenDeleteConfirmation,
      handleUpdateCard,
      online,
    );
  }, [session, trx, online]);

  const transactionPrescriptions = transaction.items.reduce(
    (acc, {itemNumber, rx}) => {
      if (rx) {
        itemNumber && acc.push(itemNumber);
      }
      return acc;
    },
    [] as string[],
  );
  const canSave =
    forceCanSave ||
    (customer &&
      !(customer as OfflineCustomer)?.prescriptions &&
      transactionChanged) ||
    (!viewOnly &&
      selectedRX.length &&
      selectedRX.every(rx => !transactionPrescriptions.includes(rx))) ||
    (transaction.roomNumber || '') !== roomNumber ||
    (transaction.notes || '') !== transactionNotes;

  const hideNewCardButton =
    !settingsResult ||
    !settingsResult.data?.some(({name, value}) => {
      return name === 'CreditCardOnFileEnabled' && value === '1';
    });

  const arAccounts = customer ? ars(customer, false, false) : [];
  const pdAccounts = customer ? pds(customer, false, false) : [];

  const _openPaymentsDomain = useCallback(() => {
    idleTimerPause();

    paymentService.OpenCCOFPaymentsDomain(transaction, paymentsWindowService);
  }, [paymentService, transaction, user, session]);

  useEffect(() => {
    if (initialPrescription && customer) {
      handleRxScan(initialPrescription);
    }
  }, [initialPrescription, customer]);

  useEffect(() => {
    if (skipWillCall && customer) {
      setShowPendingChangesModal(false);
      addItems();
      setSkipWillCall(false);
    }
  }, [skipWillCall, customer]);

  const getPrescriptionItemNumber = (
    manualRxFormData: ManualRXFormData,
  ): string => {
    const prescriptions = (customer as OfflineCustomer | undefined)
      ?.prescriptions;

    if (!prescriptions) {
      willCallError.name = 'Will Call Error';
      throw willCallError;
    }

    const prescription = findRx(prescriptions, manualRxFormData);

    if (!prescription) {
      if (transactionChanged) {
        notFoundError.name = 'Prescription Not Found';
        throw notFoundError;
      } else {
        mismatchedError.name = "Customer Doesn't Match";
        throw mismatchedError;
      }
    }

    const {itemNumber, description} = prescription;
    assert(itemNumber, 'Missing itemNumber');

    if (
      transactionPrescriptions.includes(itemNumber) ||
      (selectedRX && selectedRX.includes(itemNumber))
    ) {
      throw doubleScanError(description || '');
    }

    if (IsPrescriptionReady(prescription)) {
      return itemNumber;
    } else {
      throw notReadyError(description || '');
    }
  };

  const addPrescriptionItemNumber = (itemNumber: string) => {
    setActive(0);
    setSelectedPrescriptions(curr => [...curr, itemNumber]);
  };

  const handleManualRxFormData = useCallback(
    (manualRxFormData: ManualRXFormData) =>
      addPrescriptionItemNumber(getPrescriptionItemNumber(manualRxFormData)),
    [transactionPrescriptions, selectedRX],
  );

  const handleRxScan = useCallback(
    (item: ManualRXFormData) => {
      if (Number(active) !== 0) {
        setActive(0);
      }

      if (customer) {
        try {
          handleManualRxFormData(item);
        } catch (error) {
          switch ((error as Error).name) {
            case 'Will Call Error':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
            case 'Prescription Not Found':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              break;
            case "Customer Doesn't Match":
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'error',
                icon: 'X',
              });
              break;
            case 'Prescription Already Scanned':
            case 'Prescription Not Ready':
              notification({
                title: (error as Error).name,
                description: (error as Error).message,
                type: 'warning',
                icon: 'Warning',
              });
              break;
            default:
              notification({
                title: 'Error Occurred',
                description:
                  'Uh-oh! Something isn’t right with the Emporos API.',
                type: 'warning',
                icon: 'Warning',
              });
              // Not placing tracking here since API related errors are tracked in alerts provider
              break;
          }
        }
      } else if (!loadingCustomer) {
        // Fetch a customer if the will call screen is available and
        // not already fetching
        onUnresolvedRxScan &&
          onUnresolvedRxScan({
            rx: item.rx,
            fill: item.fill,
            partial: item.partial,
            site: item.site,
          });
      } else {
        notification({
          title: 'Will Call Loading',
          description:
            "We're on it! Please wait while we fetch customer information.",
          type: 'warning',
          icon: 'Warning',
        });
      }
    },
    [handleManualRxFormData, customer, loadingCustomer],
  );

  const addItems = useCallback(() => {
    let items: TransactionItem[] = [...transaction.items];
    const rx = (customer as OfflineCustomer)?.prescriptions
      ?.filter(({itemNumber}) => itemNumber && selectedRX.includes(itemNumber))
      .map(p => ({...p, medicationStatus: 1}));
    if (rx?.length) {
      items = rx.reduce(
        createTransactionPrescriptionReducer(transaction, taxGroupId),
        items,
      );
    }
    onSave({
      isVerified: transaction.isVerified,
      items,
      notes: transactionNotes,
      roomNumber,
      isSynced: false,
    } as Partial<Transaction> & OfflineSynced);
  }, [
    customer,
    transaction,
    transactionNotes,
    roomNumber,
    selectedRX,
    taxGroupId,
  ]);

  const _onCancel = useCallback(() => {
    if (canSave) {
      setShowPendingChangesModal(true);
    } else {
      onCancel();
    }
  }, [onCancel, canSave]);

  const onScan = useCallback(
    (scanResult: ScanResult) => {
      if (barcodeComponents && pmsName) {
        const item = pmsStrategy.decode(scanResult, barcodeComponents);
        reset();
        if (isRxItem(item) && item.rx && item.fill && item.site) {
          handleRxScan(item);
        }
      }
    },
    [barcodeComponents, pmsStrategy, handleRxScan],
  );

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setShowPendingChangesModal(false);
    addItems();
  }

  const RenderPaymentOrNotesTab = (activeTab: number) =>
    activeTab === 1 ? (
      <>
        <ScrollContainer>
          <Stack
            style={{flex: 1, height: '100%'}}
            data-testid="CustomerPaymentsTab"
          >
            {customer && (
              <Stack gutter={Gutter.S}>
                <Row
                  align="end"
                  justify="space-between"
                  style={{padding: '0px 16px 0px 0px'}}
                >
                  <Text
                    variant={TV.Caption}
                    style={{paddingLeft: 16, marginBottom: -6}}
                  >
                    Credit Cards on File
                  </Text>
                  {!hideNewCardButton && (
                    <Button
                      data-testid="newcard"
                      size={Size.Small}
                      icon="PlusCircle"
                      onClick={_openPaymentsDomain}
                      type="button"
                      disabled={!online}
                    >
                      New Card
                    </Button>
                  )}
                </Row>
                {customerHasCards && (
                  <CCRow>
                    <Carousel>{creditCards}</Carousel>
                  </CCRow>
                )}

                {!customerHasCards && RenderEmptyRow('cc')}
                <Text
                  variant={TV.Caption}
                  style={{paddingLeft: 16, marginBottom: -6}}
                >
                  AR Accounts
                </Text>
                {ARAccounts(arAccounts)}
                <Text
                  variant={TV.Caption}
                  style={{paddingLeft: 16, marginBottom: -6}}
                >
                  PD Accounts
                </Text>
                {PDAccounts(pdAccounts)}
              </Stack>
            )}
            {!customer && (
              <Stack
                align="center"
                justify="center"
                gutter={Gutter.None}
                style={{height: '100%'}}
              >
                <Illustration illustration="AddToGroup" />
                <Text variant={TV.Main} align="center">
                  Add customer to see payments
                </Text>
              </Stack>
            )}
          </Stack>
        </ScrollContainer>
        {Footer(_onCancel, canSave, loadingCustomer)}
      </>
    ) : (
      <>
        <Row
          data-testid="CustomerNotesTab"
          gutter={Gutter.XXL}
          style={{overflow: 'hidden', height: '100%'}}
          noWrap
        >
          <Stack style={{marginTop: '5px', width: '100%', flex: 1}}>
            {customer && (
              <TextInput
                {...{as: 'textarea', rows: '5'}}
                placeholder="Customer Notes"
                name="customerNotes"
                value={customer?.notes.join('\n')}
                disabled={true}
              />
            )}
            <TextInput
              {...{as: 'textarea', rows: '5'}}
              placeholder="Transaction Notes"
              name="transactionNotes"
              value={transactionNotes}
              onChange={event => setTransactionNotes(event.target.value)}
            />
            {Footer(_onCancel, canSave, loadingCustomer)}
          </Stack>
          <Numberpad
            title="Room Number"
            number={roomNumber}
            setNumber={setRoomNumber}
            onClear={() => setRoomNumber('')}
          />
        </Row>
      </>
    );

  return (
    <>
      <Stack
        style={{height: '100%'}}
        data-testid="CustomerInfoPanel"
        as="form"
        onSubmit={onSubmit}
      >
        <Header title="Customer Info">
          <SegmentSlider
            onSelectIndex={setActive}
            active={active}
            items={
              trx.customer ? ['Will Call', 'Payments', 'Notes'] : ['Will Call']
            }
          />
        </Header>

        {active === 0 ? (
          <>
            <Row
              gutter={Gutter.XXL}
              style={{overflow: 'hidden', height: '100%'}}
              noWrap
              data-testid="CustomerInfoTab"
            >
              <Stack style={{width: '100%', flex: 1}}>
                <WillCallPanel
                  transaction={transaction}
                  customerInfo={customer}
                  loadingCustomer={loadingCustomer}
                  prescriptions={(customer as OfflineCustomer)?.prescriptions}
                  selectedRX={selectedRX}
                  viewOnly={viewOnly}
                  handleAddCustomerClick={onAddCustomerClick}
                  setSelectedPrescriptions={setSelectedPrescriptions}
                  online={!!online}
                />
              </Stack>
            </Row>

            {Footer(_onCancel, canSave, loadingCustomer)}
          </>
        ) : (
          RenderPaymentOrNotesTab(active)
        )}
      </Stack>
      {Number(active) === 0 && !viewOnly && online && (
        <PageBarcodeScanner onScan={onScan} closeAfterSuccess={false} />
      )}
      <PendingChangesModal
        visible={showPendingChangesModal}
        onCancel={() => setShowPendingChangesModal(false)}
        onContinue={onCancel}
      />
      <ProcessingModal visible={showProcessingModal} />
      <DeleteConfirmationModal
        visible={showDeleteConfirmation}
        onConfirm={handleDeleteCard}
        onCancel={handleCloseDeleteConfirmation}
      />
      <SuccessModal
        visible={showSuccessModal}
        message={successMessage}
        onClose={handleCloseSuccessModal}
      />
      <ErrorModal
        visible={showErrorModal}
        message={errorMessage}
        onClose={handleCloseErrorModal}
      />
    </>
  );
};

export const CustomerInfoPanel = memo(CustomerInfoPanelComponent);
