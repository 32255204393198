import {KeyboardScannerService} from '@emporos/barcodes';
import {BarcodeScannerProvider as ComponentBarcodeScannerProvider} from '@emporos/components-pos';
import {ReactElement, ReactNode, useCallback, useMemo} from 'react';
import {useAlertState, useGlobalSettings} from './';

export function BarcodeScannerProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const {exposedAppSettings} = useGlobalSettings();
  const {notification} = useAlertState();
  const keyboardScannerService = useMemo(
    () => new KeyboardScannerService(),
    [],
  );

  const scanditLicense = useMemo(() => {
    return exposedAppSettings?.settings['SCANDIT_LICENSE_KEY'] ?? '';
  }, [exposedAppSettings]);

  const scanditError = useCallback(
    (error?: Error) => {
      notification({
        title: 'Camera Scanner Error',
        description:
          error?.message ||
          'The camera scanner is not responding. Try refreshing the app.',
        type: 'warning',
        icon: 'Warning',
      });
    },
    [notification],
  );

  return (
    <ComponentBarcodeScannerProvider
      keyboardScannerService={keyboardScannerService}
      onScanditError={scanditError}
      onScannerError={scanditError}
      licenseKey={scanditLicense}
    >
      {children}
    </ComponentBarcodeScannerProvider>
  );
}
