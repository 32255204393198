import React, {
  createContext,
  memo,
  PropsWithChildren,
  PropsWithoutRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

export type PageProps = PropsWithChildren<{
  level?: number;
  open?: boolean;
}>;

const noop = () => undefined;

export const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: ${({theme}) => theme.colors.gray_100};
  flex: 1;
  overflow: hidden;
  padding-left: 8px;
  margin-left: -8px;
  .page.animate {
    transform: translateX(100%);
    transition: transform 400ms ease-out;
  }

  .slide-enter-done > .page.animate {
    transform: initial;
  }
`;

const styleForReachRoutersDiv = `> * {
  height: 100%;
}`;

const StyledPage = styled.div<PageProps & {level: number}>`
  height: 100%;
  width: ${({level}) => (level === 1 ? '100%' : 'auto')};
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 30px 0 0 30px;
  box-shadow: ${({theme}) => theme.shadows.shadow_4};
  padding: 36px;

  position: ${({level}) => (level > 1 ? 'absolute' : 'static')};
  top: 0;
  right: ${({open}) => (open ? '0' : '-8px')};
  left: ${({level}) => `${level === 2 ? 40 : 36}px`};
  transform: ${({level, open}) =>
    !open && level > 1 ? 'translateX(100%)' : 'unset'};
  transition: transform 400ms ease-out;
  ${styleForReachRoutersDiv};
  @media (max-width: 1180px) {
    padding: 15px;
    border-radius: 23px 0 0 23px;
    left: ${({level}) => `${level === 2 ? 19 : 15}px`};
  }
`;

export const PageScrollContainer = styled.div`
  position: relative;
  overflow-y: auto;
`;

export type StackContext = {
  open(layer: number): void;
  close(layer: number): void;
  top: number;
};

export const stackContext = createContext<StackContext>({
  open: noop,
  close: noop,
  top: -1,
});

/**
 * A simple counter useful for identifying a single stack of windows or panels.
 * @param props
 */
export function StackProvider(props: {children?: React.ReactNode}) {
  const [top, setTop] = useState(-1);
  const open = useCallback(layer => setTop(x => (layer > x ? layer : x)), []);
  const close = useCallback(layer => setTop(x => layer - 1), []);
  const context = useMemo(
    () => ({
      open,
      close,
      top,
    }),
    [open, close, top],
  );

  return (
    <stackContext.Provider value={context}>
      {props.children}
    </stackContext.Provider>
  );
}

export const layerContext = createContext(0);

export const Page = memo(
  ({
    level,
    ...props
  }: PageProps & PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
    // TODO: No need for `open` prop in the future where every page is a
    // standalone route, and only mounted when open.
    const {open} = props;
    const stack = useContext(stackContext);
    // TODO: This can be calculated in the future from the parent layer since
    // pages will be nested (in the router) soon.
    const parentLayer = useLayer();
    const layer = useRef(typeof level === 'number' ? level : parentLayer + 1);

    useEffect(() => {
      const currentLayer = layer.current;

      if (open) {
        stack.open(currentLayer);
        return () => stack.close(currentLayer);
      }
    }, [stack, open]);

    return (
      <layerContext.Provider value={layer.current}>
        {/* TODO: Use layer for level. */}
        <StyledPage
          level={layer.current}
          data-testid={`page-level-${layer.current}`}
          className="page"
          {...props}
        />
      </layerContext.Provider>
    );
  },
);

export function useLayer() {
  return useContext(layerContext);
}
