import {CustomerCreditCard} from '@emporos/api-enterprise';
import {TransactionPayment, PaymentType} from '@emporos/api-enterprise';
import {CardOnFileProps} from '@emporos/components';
import {AllIcons, ColorType, Icons} from '@emporos/components';
import {memoize} from 'lodash';

export type AcceptedPaymentTypes =
  | 'Cash'
  | 'Check'
  | 'Credit Card'
  | 'PD'
  | 'AR'
  | 'UDP'
  | '<unknown>';

// TODO: does this mapping come from an API response we need to account for?
const mapDescriptorToType: Record<string, string> = {
  Cash: 'Cash',
  Check: 'Check',
  Visa: 'Credit Card',
  Disc: 'Credit Card',
  Amex: 'Credit Card',
  MC: 'Credit Card',
  AR: 'AR',
  'AR 3rd Party': 'AR',
  'AR On Account': 'AR',
  'AR INS Charge': 'AR',
  'AR DME Charge': 'AR',
  'Payroll Deduct': 'PD',
  User1: 'UDP',
  User2: 'UDP',
  User3: 'UDP',
  User4: 'UDP',
  User5: 'UDP',
  User6: 'UDP',
  Debit: 'Credit Card',
};
/* istanbul ignore next  */
function assertUnreachable(_x: never): never {
  throw new TypeError(
    'This should be unreachable. If you experience this please contact the Emporos customer support team.',
  );
}
const paymentTypes = memoize(function paymentTypes(
  paymentTenders: PaymentType[],
) {
  return paymentTenders.reduce((map, current) => {
    map.set(
      current.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapDescriptorToType[current.name] || '<unknown>',
    );
    return map;
  }, new Map<number, AcceptedPaymentTypes>());
});

export function getPaymentType(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): AcceptedPaymentTypes {
  const types = paymentTypes(paymentTenders);
  return types.get(paymentTypeId) || '<unknown>';
}

export function getPaymentIcon(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): keyof typeof AllIcons {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'Cash';
    case 'AR':
      return 'Bill';
    case 'Check':
      return 'Check';
    case 'Credit Card':
      switch (getPaymentDescriptor(paymentTypeId, paymentTenders)) {
        case 'MC':
          return 'PaymentMastercard';
        case 'Disc':
          return 'PaymentDiscover';
        case 'Amex':
          return 'PaymentAmex';
        case 'Visa':
          return 'PaymentVisa';
        case 'Debit':
          return 'CreditCard';
        default:
          return 'CreditCard';
      }
    case 'PD':
      return 'IdCard';
    case 'UDP':
      return 'MoneyBag';
    case '<unknown>':
      return 'MoneyBag';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getSimplePaymentIcon(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): keyof typeof Icons {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'Cash';
    case 'AR':
      return 'Bill';
    case 'Check':
      return 'Check';
    case 'Credit Card':
      return 'CreditCard';
    case 'PD':
      return 'IdCard';
    case 'UDP':
      return 'MoneyBag';
    case '<unknown>':
      return 'MoneyBag';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getPaymentIconType(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): ColorType {
  const types = paymentTypes(paymentTenders);
  const type = types.get(paymentTypeId) || '<unknown>';
  switch (type) {
    case 'Cash':
      return 'success';
    case 'Check':
      return 'purple';
    case 'Credit Card':
      return 'primary';
    case 'PD':
    case 'AR':
      return 'indigo';
    case 'UDP':
      return 'warning';
    case '<unknown>':
      return 'warning';
    /* istanbul ignore next */
    default:
      assertUnreachable(type);
  }
}

export function getPaymentTypeId(
  type: AcceptedPaymentTypes,
  paymentTenders: PaymentType[],
): number | undefined {
  const descriptor = Object.keys(mapDescriptorToType).find(
    key => mapDescriptorToType[key] === type,
  );
  return paymentTenders.find(({name}) => {
    return name === descriptor;
  })?.id;
}

export function getPaymentDescriptor(
  paymentTypeId: number,
  paymentTenders: PaymentType[],
): string | null | undefined {
  return paymentTenders.find(({id}) => {
    return id === paymentTypeId;
  })?.name;
}

export function getPaymentTypeIdByPaymentType(
  type: string,
  paymentTenders: PaymentType[],
): number | undefined {
  return paymentTenders.find(({description}) => description === type)?.id;
}

export function getPaymentTypeIdByDescriptor(
  descriptor: string,
  paymentTenders: PaymentType[],
): number | undefined {
  return paymentTenders.find(({name}) => name === descriptor)?.id;
}

export function displayPaymentNumber(
  {paymentTypeID, paymentNumber, amountReturned}: TransactionPayment,
  paymentTenders: PaymentType[],
): string {
  if (!paymentTypeID) {
    return '';
  }

  const paymentType = getPaymentType(paymentTypeID, paymentTenders);
  switch (paymentType) {
    case 'Cash':
      return `Change: $${(amountReturned || 0).toFixed(2)}`;
    case 'Credit Card':
      const descriptor = getPaymentDescriptor(paymentTypeID, paymentTenders);
      if (descriptor) {
        return [descriptor, paymentNumber?.substr(-4)].join(' - ');
      }
      return '';
    default:
      if (paymentNumber && paymentNumber !== '0') {
        return paymentNumber;
      } else {
        return '';
      }
  }
}

export function formatExpDate(date: Date): string {
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString().slice(2);
  const exp = month + '/' + year;
  return exp;
}

export function compareDates(cardExp: Date): boolean {
  const today = new Date();
  const isExpired = cardExp < today;
  return isExpired;
}

// this is needed temporarily until we begin making calls to payments domain to get boarded cards
// it will handle mapping what we get back from hilo-api to what we expect wheb displaying boarded cards
export function mapCardsToProps(
  cardsOnFile: CustomerCreditCard[],
  cardHolderName: string,
): CardOnFileProps[] {
  const cardProps: CardOnFileProps[] = [];
  if (cardsOnFile) {
    cardsOnFile.forEach(card => {
      if (!compareDates(card.expiration)) {
        cardProps.push({
          cardToken: card.token,
          cardHolder: cardHolderName,
          expirationDate: formatExpDate(card.expiration),
          lastFour: card.last4OfCard,
          cardType: card.cardType,
          nickName: 'nickname',
          isExpired: compareDates(card.expiration),
        });
      }
    });
  }
  return cardProps;
}

export const TenderTypes = {
  tenderCash: 'Cash',
  tenderCheck: 'Check',
  tenderAccountsReceivable: 'AR',
  tenderPayrollDeduction: 'PD',
  tenderUnknown: 'UDP',
  unknown: '<unknown>',
};

export function getAcceptedPaymentTypesFromFeatureParam(): AcceptedPaymentTypes {
  const params = new URLSearchParams(window.location.search);
  const feature: string = params.get('feature') ?? '<unknown>';

  if (feature && feature in TenderTypes) {
    const type = TenderTypes[feature as keyof typeof TenderTypes];
    return type as AcceptedPaymentTypes;
  } else {
    return TenderTypes.unknown as AcceptedPaymentTypes;
  }
}
