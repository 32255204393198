import {
  ApiResponse,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError,
  Transaction,
  TransactionFromJSON,
} from '@emporos/api-enterprise';
import {EncryptedBaseApi} from './EncryptedBaseApi';

export class EncryptedTransactionsApi extends EncryptedBaseApi {
  /**
   * Inserts a transaction
   */
  async InsertTransactionEncryptedRaw(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<ApiResponse<Transaction>> {
    this.checkRequiredParameters(
      ['sessionId', 'cipherTextByteArray'],
      sessionId,
      null as unknown as string,
      cipherTextByteArray,
      'EncryptedTransactionsApi.InsertTransactionEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      ),
      headerParameters,
      queryParameters,
      'POST',
      this.ConvertToEncodedString(cipherTextByteArray),
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionFromJSON(jsonValue),
    );
  }

  /**
   * Inserts a transaction
   */
  async InsertTransactionEncrypted(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<Transaction> {
    const response = await this.InsertTransactionEncryptedRaw(
      sessionId,
      cipherTextByteArray,
    );
    return await response.value();
  }

  /**
   * Updates a transaction
   */
  async UpdateTransactionEncryptedRaw(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<ApiResponse<Transaction>> {
    this.checkRequiredParameters(
      ['sessionId', 'cipherTextByteArray'],
      sessionId,
      null as unknown as string,
      cipherTextByteArray,
      'EncryptedTransactionsApi.UpdateTransactionEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(sessionId);

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      ),
      headerParameters,
      queryParameters,
      'PUT',
      this.ConvertToEncodedString(cipherTextByteArray),
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionFromJSON(jsonValue),
    );
  }

  /**
   * Updates a transaction
   */
  async UpdateTransactionEncrypted(
    sessionId: string,
    cipherTextByteArray: Uint8Array,
  ): Promise<Transaction> {
    const response = await this.UpdateTransactionEncryptedRaw(
      sessionId,
      cipherTextByteArray,
    );
    return await response.value();
  }

  /**
   * Completes a transaction
   */
  async CompletePatchTransactionEncryptedRaw(
    sessionId: string,
    transactionId: string,
  ): Promise<ApiResponse<Transaction>> {
    this.checkRequiredParameters(
      ['sessionId', 'transactionId'],
      sessionId,
      transactionId,
      null as unknown as Uint8Array,
      'EncryptedTransactionsApi.CompletePatchTransactionEncrypted',
    );
    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = this.GenerateHeaders(
      sessionId,
      false,
    );

    const response = await this.MakeRequest(
      `/client/cache/sessions/{sessionId}/transactions/{transactionId}/complete`
        .replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)))
        .replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(transactionId)),
        ),
      headerParameters,
      queryParameters,
      'PATCH',
    );

    return new JSONApiResponse(response, jsonValue =>
      TransactionFromJSON(jsonValue),
    );
  }

  /**
   * Completes a transaction
   */
  async CompletePatchTransactionEncrypted(
    sessionId: string,
    transactionId: string,
  ): Promise<Transaction> {
    const response = await this.CompletePatchTransactionEncryptedRaw(
      sessionId,
      transactionId,
    );
    return await response.value();
  }

  checkRequiredParameters(
    requiredParameters: string[],
    sessionId: string,
    transactionId: string,
    cipherTextByteArray: Uint8Array,
    methodName: string,
  ): void {
    if (
      (requiredParameters.some(x => x === 'sessionId') && sessionId === null) ||
      sessionId === undefined
    ) {
      throw new RequiredError(
        'sessionId',
        'Required parameter sessionId was null or undefined when calling ' +
          methodName,
      );
    }

    if (
      (requiredParameters.some(x => x === 'transactionId') &&
        transactionId === null) ||
      transactionId === undefined
    ) {
      throw new RequiredError(
        'transactionId',
        'Required parameter transactionId was null or undefined when calling ' +
          methodName,
      );
    }

    if (
      (requiredParameters.some(x => x === 'cipherTextByteArray') &&
        cipherTextByteArray === null) ||
      cipherTextByteArray === undefined
    ) {
      throw new RequiredError(
        'cipherTextByteArray',
        'Required parameter cipherTextByteArray was null or undefined when calling ' +
          methodName,
      );
    }
  }
}
