import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export const DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS = 5 * 60;
export const DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES = 5;
export const DEFAULT_TIMEOUT_IN_MINUTES = 10;

interface SettingsProps {
  creditCardDeviceRequestTimeoutSeconds: number;
  setCreditCardDeviceRequestTimeoutSeconds: Dispatch<SetStateAction<number>>;
  creditCardPendingPaymentExpirationMinutes: number;
  setCreditCardPendingPaymentExpirationMinutes: Dispatch<
    SetStateAction<number>
  >;
  timeoutInMinutes: number;
  setTimeoutInMinutes: Dispatch<SetStateAction<number>>;
}
const SettingsContext = createContext<SettingsProps>({
  creditCardDeviceRequestTimeoutSeconds:
    DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCreditCardDeviceRequestTimeoutSeconds: (value: SetStateAction<number>) => {
    return;
  },
  creditCardPendingPaymentExpirationMinutes:
    DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES,
  setCreditCardPendingPaymentExpirationMinutes: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: SetStateAction<number>,
  ) => {
    return;
  },
  timeoutInMinutes: DEFAULT_TIMEOUT_IN_MINUTES,
  setTimeoutInMinutes: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: SetStateAction<number>,
  ) => {
    return;
  },
});

export function SettingsProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [
    creditCardDeviceRequestTimeoutSeconds,
    setCreditCardDeviceRequestTimeoutSeconds,
  ] = useState(DEFAULT_CREDIT_CARD_DEVICE_TIMOUT_REQUEST_SECONDS);
  const [
    creditCardPendingPaymentExpirationMinutes,
    setCreditCardPendingPaymentExpirationMinutes,
  ] = useState(DEFAULT_CREDIT_CARD_PENDING_PAYMENT_EXPIRATION_MINUTES);
  const [timeoutInMinutes, setTimeoutInMinutes] = useState(
    DEFAULT_TIMEOUT_IN_MINUTES,
  );

  return (
    <SettingsContext.Provider
      value={{
        creditCardDeviceRequestTimeoutSeconds,
        creditCardPendingPaymentExpirationMinutes,
        setCreditCardDeviceRequestTimeoutSeconds,
        setCreditCardPendingPaymentExpirationMinutes,
        timeoutInMinutes: timeoutInMinutes,
        setTimeoutInMinutes: setTimeoutInMinutes,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
export const useSettings = (): SettingsProps => useContext(SettingsContext);
