/**
 * Emporos BetaFeatures is a very simplistic pattern to introduce feature
 * development branches so that we can keep the `dev` branch always with the
 * latest code while still preventing customers from accessing in-development
 * features. This reduces risk for large releases because we always are
 * targeting the same version of the product code without having to maintain
 * complex release strategies.
 *
 * At the time of writing this is only a client-side implementation, though it
 * can be extended to load data from an API source at runtime for future product
 * use cases.
 *
 * Example usage is in the associated BetaFeatureProvider.test.tsx cases.
 **/
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as mousetrap from 'mousetrap';

export interface BetaFeatures {
  /**
   * Credit Cards on File is expected for Early Access, though is still in early
   * development. It is expected that this beta flag is removed before Early
   * Access.
   **/
  creditCardsOnFile: boolean;
  /**
   * TODO: write description for this beta flag.
   **/
  verificationModal: boolean;
  /**
   * Support for printing devices throughout the application. This is
   * de-prioritized for Early Access, with the expectation that this will be
   * fully enabled by General Availability.
   **/
  printing: boolean;
  /**
   * Credit Card processor Vantiv.
   **/
  vantiv: boolean;
}

const shortcuts: Array<[string, keyof BetaFeatures]> = [
  ['* c o f', 'creditCardsOnFile'],
  ['* p r i n t', 'printing'],
  ['* v v', 'vantiv'],
];

interface BetaFeatureProps extends BetaFeatures {
  toggleFlag: (flag: keyof BetaFeatures) => void;
}

const BetaFeatureContext = createContext<BetaFeatureProps>({
  creditCardsOnFile: true,
  verificationModal: true,
  printing: false,
  vantiv: false,
  toggleFlag(_flag: keyof BetaFeatures) {
    throw new Error(
      'You must provide a <BetaFeatureContext.Provider /> to update feature flags.',
    );
  },
});

export function BetaFeatureProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const {ENABLE_BETA_FEATURES, NODE_ENV} = process.env;
  const [betaState, setBetaState] = useState<BetaFeatures>({
    creditCardsOnFile: true,
    verificationModal: true,
    printing: false,
    vantiv: false,
  });
  const toggleFlag = useCallback(
    (flag: keyof BetaFeatures) => {
      setBetaState(state => ({...state, [flag]: !state[flag]}));
    },
    [betaState],
  );

  // TODO: we want to disable these when we go to real production, but for now
  // these are useful for the product team in the dev environment
  if (ENABLE_BETA_FEATURES === 'enabled' || String(NODE_ENV) !== 'production') {
    useEffect(() => {
      shortcuts.forEach(([shortcut, flag]) => {
        mousetrap.bind(shortcut, () => {
          toggleFlag(flag);
        });
      });

      return () => {
        shortcuts.forEach(([shortcut]) => {
          mousetrap.unbind(shortcut);
        });
      };
    }, []);
  }

  return (
    <BetaFeatureContext.Provider value={{...betaState, toggleFlag: toggleFlag}}>
      {children}
    </BetaFeatureContext.Provider>
  );
}
export const useBetaFeatures = (): BetaFeatureProps =>
  useContext(BetaFeatureContext);

export function BetaFeatureSwitch({
  feature,
  children,
}: {
  feature: keyof BetaFeatures;
  children: (enabled: boolean) => JSX.Element | null;
}): JSX.Element | null {
  const enabled = useBetaFeatures()[feature];

  return children(enabled);
}
