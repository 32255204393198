import {Transaction} from '@emporos/api-enterprise';
import {ButtonShape, ButtonSize, Header, Modal} from '@emporos/components';
import {formatCustomerName} from '../../../../utils';
import {useCallback, useState} from 'react';
import {navigate} from '@reach/router';
import {
  useAuthentication,
  useGlobalSettings,
  useNetworkAvailable,
  useTransactionsConfig,
} from '../../../../contexts';
import {PaymentService} from '../../../../services/PaymentService';
import {PatientPayExtras} from '../PatientPayTransactionIntegration';
import {useTransactionsState} from '../../../../contexts/TransactionsStateProvider';

interface PatientPayHeaderProps {
  transaction: Transaction;
  canDeleteTransaction: boolean;
  onRemoveTransaction: (transaction: Transaction) => void;
}

export const PatientPayHeader = ({
  transaction,
  canDeleteTransaction,
  onRemoveTransaction,
}: PatientPayHeaderProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [returnToOnHoldModalOpen, setReturnToOnHoldModalOpen] = useState(false);
  const [processingModalOpen, setProcessingModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {user} = useAuthentication();
  const {tenantId} = useGlobalSettings();
  const token = user ? user.access_token : '';
  const paymentService = new PaymentService(token, tenantId);
  const {deleteTransactionFromLocalDb} = useTransactionsState();
  const {loadSession} = useTransactionsConfig();
  const {online} = useNetworkAvailable();

  const hasReturnedPayment = transaction.payments.some(
    p => p.recordStatus === 'Voided',
  );
  const customerName = transaction.customer
    ? formatCustomerName(transaction.customer)
    : '';

  const removeTransactionHandler = useCallback(() => {
    onRemoveTransaction(transaction);
    navigate('/sales');
  }, [transaction, onRemoveTransaction]);

  const handleReturnToOnHoldSubmit = async () => {
    setProcessingModalOpen(true);
    setReturnToOnHoldModalOpen(false);
    try {
      const patientPayExtrasString = transaction.extras.find(
        extra => extra.dataKey === 'PatientPay',
      )?.dataValue;

      const patientPayExtras: PatientPayExtras = JSON.parse(
        patientPayExtrasString || '{}',
      );
      const result = await paymentService.ReturnToOnHold(
        transaction,
        patientPayExtras.linkToPayCode ?? '',
      );

      if (result) {
        await deleteTransactionFromLocalDb(transaction.transactionId);
        await loadSession();
        navigate('/sales');
      } else {
        setProcessingModalOpen(false);
        setErrorMessage('Could not put the transaction back on hold.');
        setErrorModalOpen(true);
      }
    } catch (error) {
      setProcessingModalOpen(false);
      setErrorMessage(error instanceof Error ? error.message : 'Unknown error');
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <Header title={customerName} badgeText={transaction.roomNumber ?? ''}>
        <ButtonShape
          size={ButtonSize.Small}
          icon="ClockSuspend"
          disabled={!online || hasReturnedPayment}
          data-testid="return-to-onhold"
          onClick={() => setReturnToOnHoldModalOpen(true)}
        />
        <ButtonShape
          size={ButtonSize.Small}
          icon="User"
          disabled={true}
          data-testid="customer-info-btn"
        />
        <ButtonShape
          disabled={!canDeleteTransaction}
          size={ButtonSize.Small}
          icon="Trash"
          onClick={() => setDeleteModalOpen(true)}
          data-testid="delete"
        />
      </Header>

      <Modal
        data-testid="delete-modal"
        icon="Trash"
        color="error"
        visible={deleteModalOpen}
        title="Delete This Transaction"
        subtitle="This transaction will be deleted and cannot be recovered. Would you like to continue?"
        onCancel={() => setDeleteModalOpen(false)}
        onContinue={removeTransactionHandler}
      />

      <Modal
        data-testid="return-to-onhold-modal"
        icon="Warning"
        color="warning"
        visible={returnToOnHoldModalOpen}
        title="Remove from Queue"
        subtitle="Are you sure that you want to put this transaction back on hold?"
        onCancel={() => setReturnToOnHoldModalOpen(false)}
        onContinue={handleReturnToOnHoldSubmit}
      />

      <Modal
        data-testid="processing-modal"
        icon="Spinner"
        iconSpinning={true}
        color="primary"
        visible={processingModalOpen}
        title="Processing..."
        subtitle="Please wait while we process your request."
        disableSubmit
      />

      <Modal
        data-testid="error-modal"
        icon="Warning"
        color="error"
        visible={errorModalOpen}
        title="Error"
        subtitle={errorMessage}
        buttonText="Close"
        onContinue={() => setErrorModalOpen(false)}
      />
    </>
  );
};
