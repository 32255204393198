import {Dispatch, MutableRefObject} from 'react';
import {WindowService} from './WindowService';
import {IPaymentsWindowState} from '../contexts/PaymentsWindowProvider';

export interface IPaymentsWindowMessage {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class PaymentsWindowService {
  private PAYMENT_BASE_URL: string;
  private PAYMENT_API_UI: string;
  private _isNetworkAvailable: MutableRefObject<boolean>;
  private _paymentsWindow: Window | null = null;
  private _isRefreshing = false;
  private _windowService: WindowService;
  private _interval: number | null = null;
  private _loadSession: () => Promise<void>;
  private _updateModalState: Dispatch<Partial<IPaymentsWindowState>> | null =
    null;
  private _navigateToFeature: (
    feature?: string,
    transactionId?: string,
  ) => void;
  private _resumeTimer: () => boolean;
  private _abortController: AbortController | null = null;

  constructor(
    loadSession: () => Promise<void>,
    isNetworkAvailable: MutableRefObject<boolean>,
    updateModalState: Dispatch<Partial<IPaymentsWindowState>>,
    navigateToFeature: (feature?: string, transactionId?: string) => void,
    resumeTimer: () => boolean,
    tenantId: string | void,
  ) {
    const paymentBaseUrl = process.env.PAYMENT_BASE_URL ?? '';

    this.PAYMENT_BASE_URL = paymentBaseUrl;
    this.PAYMENT_API_UI = paymentBaseUrl + '/' + tenantId + '/';

    this._isNetworkAvailable = isNetworkAvailable;
    this._windowService = new WindowService();
    this._loadSession = loadSession;
    this._updateModalState = updateModalState;
    this._navigateToFeature = navigateToFeature;
    this._resumeTimer = resumeTimer;
  }

  public get isOpen(): boolean {
    return Boolean(this._paymentsWindow && !this._paymentsWindow.closed);
  }

  private _isAllowedOrigin(originUrl: string): boolean {
    if (!!!this.PAYMENT_BASE_URL) return false;

    const allowedOrigin = new URL(this.PAYMENT_BASE_URL).origin;
    const origin = new URL(originUrl).origin;

    return origin === allowedOrigin;
  }

  public open(urlCode = ''): void {
    // reset the abort controller
    this._resetAbortController();

    this._abortController = new AbortController();

    // set the message listener
    this._windowService.addEventListener(
      'message',
      (event: MessageEvent) => {
        if (!this._isAllowedOrigin(event.origin)) return;
        this.messageCallback(event);
      },
      {signal: this._abortController.signal},
    );

    // listen for Hilo closing
    this._windowService.addEventListener(
      'beforeunload',
      () => {
        this.close();
      },
      {signal: this._abortController.signal},
    );

    // open the payments window
    if (urlCode != '') {
      this._paymentsWindow = this._windowService.open(
        this.PAYMENT_API_UI + urlCode,
      );
    }

    // set the is open state
    this._updateModalState?.({isOpen: true});

    // set the interval
    this._setInterval();
  }

  /**
   * Removes event listeners from the window and
   * cleans up the abort controller
   */
  private _resetAbortController(): void {
    // remove the message listener
    this._abortController?.abort();
    this._abortController = null;
  }

  /**
   * Sets the interval
   */
  private _setInterval(): void {
    /**
     * BUG #23290
     *
     * On iOS, the payments window modal may remain open if the PD tab is closed.
     * This interval will check if the payments window is still open and close it if it is.
     */
    this._interval = this._windowService.setInterval(async () => {
      if (!this.isOpen) {
        this._clearInterval();
        await this._refresh();
      }
    }, 500);
  }

  /**
   * Clears the interval
   */
  private _clearInterval(): void {
    if (this._interval) {
      this._windowService.clearInterval(this._interval);
      this._interval = null;
    }
  }

  /**
   * Closes the payments window
   */
  public close(): void {
    // clear the interval
    const paymentStatus = this.getPaymentInProcess();
    if (paymentStatus === 'inProgress') {
      //the process is still in progress
      return;
    }
    this._resetAbortController();

    // close the payments window
    this._paymentsWindow?.close();
    this._paymentsWindow = null;

    // close the modal window
    this._updateModalState?.({isOpen: false});

    // resume the timer
    this._resumeTimer();
  }

  /**
   * Updates the isRefreshing state
   * @param {boolean} isRefreshing
   */
  private _setIsRefreshing(isRefreshing: boolean): void {
    this._isRefreshing = isRefreshing;
    this._updateModalState?.({isRefreshing});
  }

  /**
   * Refreshes Hilo session
   * @returns {Promise<void>}
   */
  private async _refresh(): Promise<void> {
    if (!this._isNetworkAvailable.current || this._isRefreshing)
      return Promise.resolve();

    this._setIsRefreshing(true);

    await this._loadSession();

    this._setIsRefreshing(false);
    if (!this.isOpen) this.close();
  }

  /**
   * Handles messages from the payments window
   * @param {MessageEvent} event
   * @returns {Promise<void>}
   */
  public async messageCallback(event: MessageEvent): Promise<void> {
    if (!event.data) return Promise.resolve();

    const message: IPaymentsWindowMessage = event.data;
    const type = message.type ?? null;
    const data = message.data ?? {};

    // Early return if type is not a string
    if (typeof type !== 'string') return Promise.resolve();

    switch (type) {
      case 'paymentStatusUpdate':
        // Handle payment process status updates
        this.setPaymentInProcess(data as 'inProgress' | 'none');
        this._updateModalState?.({isProcessing: data === 'inProgress'});
        break;

      case 'processing':
        // Handle processing updates
        this._updateModalState?.({isProcessing: data.isProcessing});
        break;

      case 'navigate':
        // Handle navigation requests
        const {feature, transactionId} = data;
        if (feature !== 'timeout') {
          await this._refresh();
        }
        this.close();
        if (feature) {
          this._navigateToFeature(feature, transactionId);
        }
        break;

      default:
        // Handle default case, possibly a refresh
        await this._refresh();
        break;
    }
  }

  private setPaymentInProcess(status: 'inProgress' | 'none'): void {
    localStorage.setItem('paymentInProcessSession', status);
  }

  // Get the payment process status from localStorage
  public getPaymentInProcess(): 'inProgress' | 'none' | null {
    return localStorage.getItem('paymentInProcessSession') as
      | 'inProgress'
      | 'none'
      | null;
  }
}
