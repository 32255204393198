import {AccessCode, Modal} from '@emporos/components';
import {Button, Variant} from '@emporos/components/src/Button';
import {FooterGroup} from '@emporos/components/src/FooterGroup';
import {Header} from '@emporos/components/src/Header';
import {Gutter, Row, Stack} from '@emporos/components/src/Layout';
import {ScrollContainer} from '@emporos/components/src/LayoutUtilities';
import {navigate} from '@reach/router';
import {memo, useEffect, useState} from 'react';
import {useNetworkAvailable} from '../../../contexts';

interface Props {
  accessCodeLength: number;
  setAccessCode: (accessCode: string) => void;
  onConfirm?: () => void;
  onBack?: () => void;
  loading: boolean;
}

function CreateAccessCodeComponent({
  accessCodeLength,
  setAccessCode,
  onConfirm,
  onBack,
  loading,
}: Props): JSX.Element {
  const {online} = useNetworkAvailable();
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [headerTitle, setHeaderTitle] = useState<string>(
    'Create an Access Code',
  );
  const [renderAccessCode, setRenderAccessCode] = useState<boolean>(true);
  const [secondPass, setSecondPass] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // we reset the AccessCode component by unmounting and immediately remounting it
  useEffect(() => {
    setRenderAccessCode(true);
  }, [renderAccessCode]);

  const onBackClick = () => {
    if (secondPass) {
      setRenderAccessCode(false);
      setSecondPass(false);
      setConfirmDisabled(true);
    } else if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const codeConfirmation = async (accessCode: string): Promise<boolean> => {
    setAccessCode(accessCode);
    setConfirmDisabled(false);
    return true;
  };

  return (
    <Stack>
      <Header title={headerTitle} />
      <ScrollContainer style={{display: 'flex'}}>
        <Stack
          align="center"
          justify="center"
          gutter={Gutter.L}
          style={{flex: 1}}
        >
          <Row>
            <Modal
              visible={showPopup}
              data-testid="Modal__WeakAccessCode"
              icon="Warning"
              color="error"
              title="Access Code"
              subtitle="Your access code is not strong enough."
              buttonText="Okay"
              onContinue={() => setShowPopup(false)}
            />
            {renderAccessCode && (
              <AccessCode
                mode="create"
                codeLength={accessCodeLength}
                setHeaderTitle={setHeaderTitle}
                setAccessCode={codeConfirmation}
                setSecondPassForParent={setSecondPass}
                onWeakAccessCode={() => setShowPopup(true)}
              ></AccessCode>
            )}
          </Row>
        </Stack>
      </ScrollContainer>
      <FooterGroup>
        <Button variant={Variant.Danger} flex onClick={onBackClick}>
          Back
        </Button>
        <Button
          flex
          disabled={confirmDisabled || !online}
          onClick={confirmDisabled ? undefined : onConfirm}
          loading={loading}
        >
          Start Session
        </Button>
      </FooterGroup>
    </Stack>
  );
}

export const CreateAccessCode = memo(CreateAccessCodeComponent);
