export class WindowService {
  location(): Location {
    return window.location;
  }

  addEventListener(
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (event: any) => void,
    options = {},
  ): void {
    window.addEventListener(type, callback, options);
  }

  removeEventListener(
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (event: any) => void,
  ): void {
    window.removeEventListener(type, callback);
  }

  open(url: string): Window {
    return window.open(url, '_blank') as Window;
  }

  setInterval(
    handler: TimerHandler,
    timeout?: number | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): number {
    return window.setInterval(handler, timeout, ...args);
  }

  clearInterval(handle?: number | undefined): void {
    window.clearInterval(handle);
  }
}
