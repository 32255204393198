import {Page, PageWrapper, PortraitNotSupported} from '@emporos/components';
import {Navbar} from '@emporos/components-pos';
import {
  Redirect,
  RouteComponentProps,
  navigate,
  useLocation,
} from '@reach/router';
import assert from 'assert';
import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  AuthClaim,
  useAuthentication,
  useSyncSession,
  useTransactionsConfig,
} from '../../../';
import {
  CreateAccessCode,
  isSessionConfigCompleted,
  SessionConfig,
  SessionSidebar,
} from './';

const MainWrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
  height: calc(var(--vh, 1vh) * 100 - 68px);
`;

// hard coded access code length for now
const accessCodeLength = 6;

const CreateAccessCodeChildContainer = (_props: RouteComponentProps) => {
  const {user, logout, deviceId} = useAuthentication();
  const {sessionStatus, syncSession} = useSyncSession();
  const {createSession, createSessionLoading} = useTransactionsConfig();
  const [accessCode, setAccessCode] = useState<string>('');
  const [accessCodeSet, setAccessCodeSet] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as SessionConfig;

  useEffect(() => {
    if (
      isSessionConfigCompleted(state) &&
      accessCode.length == accessCodeLength
    ) {
      setAccessCodeSet(true);
    }
  }, [accessCode]);

  assert(
    user !== null,
    'Internal Error: tried to render CreateSession without an authenticated user',
  );

  const onConfirm = useCallback(async () => {
    if (!isSessionConfigCompleted(state) || !accessCodeSet) {
      return;
    }
    const {
      site,
      station,
      till,
      paymentDeviceAddress,
      paymentDevicePort,
      tillStartingAmount,
    } = state;
    return createSession(
      site,
      station,
      till,
      tillStartingAmount,
      accessCode,
      deviceId,
      paymentDeviceAddress,
      paymentDevicePort,
    );
  }, [state, accessCodeSet]);

  const onBack = () => {
    navigate('/login/create-session', {state: {...state}});
  };

  if (state == null || !isSessionConfigCompleted(state))
    return <Redirect to="/login/create-session" replace={true}></Redirect>;

  return (
    <>
      <div className="layout-landscape">
        <Navbar
          username={user.profile[AuthClaim.Name] as string}
          onSessionStatus={syncSession}
          sessionStatus={sessionStatus}
          isLoadingData={false}
          onLogout={logout}
          hideSessionStatus={true}
        />
        <MainWrapper>
          <SessionSidebar
            fullName={user.profile[AuthClaim.Name] as string}
            site={state.site}
            station={state.station}
            till={state.till}
            paymentDeviceAddress={state.paymentDeviceAddress}
          />
          <PageWrapper style={{backgroundColor: 'transparent'}}>
            <Page level={1}>
              <CreateAccessCode
                accessCodeLength={accessCodeLength}
                setAccessCode={setAccessCode}
                onConfirm={onConfirm}
                onBack={onBack}
                loading={createSessionLoading}
              />
            </Page>
          </PageWrapper>
        </MainWrapper>
      </div>
      <div className="layout-portrait">
        <PortraitNotSupported></PortraitNotSupported>
      </div>
    </>
  );
};

export const CreateAccessCodeContainer = memo(CreateAccessCodeChildContainer);
