import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {memo} from 'react';
import {
  Transaction,
  OfflineSynced,
  useTransaction,
  useSessionData,
  withChildPage,
} from '../../../../../';
import {Receipts} from './';

export interface EmailForm {
  email: string;
}

function ReceiptsIntegrationComponent({navigate}: RouteComponentProps) {
  const {transaction, updateTransaction} = useTransaction();
  const {NODE_ENV} = process.env;
  const {settingsResult} = useSessionData();

  assert(
    navigate,
    '<ReceiptsIntegration /> must have a `navigate` prop.' +
      String(NODE_ENV) ===
      'production'
      ? ''
      : ' This likely means that you need to have it as a direct child of a <Router />',
  );
  assert(transaction, 'Missing transaction');
  assert(settingsResult && settingsResult.data, 'Missing settingsResult');

  const onNoReceipt = () => {
    completeTransaction({
      isCompleted: true,
      isSynced: true,
    } as Partial<Transaction> & OfflineSynced);
  };

  const onEmailReceipt = (data: EmailForm) => {
    completeTransaction({
      emailReceipt: data.email,
      isCompleted: true,
      isSynced: true,
    } as Partial<Transaction> & OfflineSynced);
  };

  const completeTransaction = (
    transactionUpdates: Partial<Transaction> & OfflineSynced,
  ) => {
    updateTransaction(transactionUpdates, true);

    setTimeout(() => navigate('/sales'), 400);
  };

  const onBack = (onBackLocation: string) => {
    navigate(onBackLocation);
  };

  return (
    <Receipts
      transaction={transaction}
      settings={settingsResult.data}
      onPrint={() => {
        return navigate('/sales');
      }}
      onNoReceipt={onNoReceipt}
      onEmailReceipt={onEmailReceipt}
      onBack={onBackLocation => {
        onBack(onBackLocation);
      }}
      navigate={navigate}
    />
  );
}

export const ReceiptsIntegration = memo(
  withChildPage(ReceiptsIntegrationComponent),
);
