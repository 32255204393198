import {memo} from 'react';
import {
  OfflineTransaction,
  useTransaction,
  useNetworkAvailable,
  useTotals,
  withChildPage,
  WithPageProps,
} from '../../../';
import {CustomerInfoPanel} from '../';

interface Props {
  viewOnly?: boolean;
}

function CustomerInfoComponent({viewOnly}: WithPageProps<Props>) {
  const {online} = useNetworkAvailable();
  const {transaction, updateTransaction} = useTransaction();
  const {taxGroupId} = useTotals();

  return (
    <CustomerInfoPanel
      online={online}
      transaction={transaction as OfflineTransaction}
      taxGroupId={taxGroupId}
      viewOnly={viewOnly}
      onCancel={() => {
        history.back();
      }}
      onSave={updates => {
        updateTransaction(updates);
        history.back();
      }}
    />
  );
}

export const CustomerInfoIntegration = memo(
  withChildPage(CustomerInfoComponent),
);
