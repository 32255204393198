import {CayanConfig, VantivConfig} from '../';
// These are the names from Emporos’ C# implementation and are only used for
// common terminology at this point of development.
const GatewayUsername = '';
const GatewayId = '';
const GatewayPassword = '';

const sharedConfig = {
  appName: 'Emporos Testing',
  siteId: '1500000000',
};

export const cayanTestConfig: CayanConfig = {
  gatewayUsername: GatewayUsername,
  gatewayId: GatewayId,
  gatewayPassword: GatewayPassword,
  gatewayTerminal: '', //
  processorLaneId: '',
  useSecureProtocol: true,
  accessToken: 'not-an-access-token',
  fetchTimeoutSeconds: 5,

  ...sharedConfig,
};

export const vantivTestConfig: VantivConfig = {
  gatewayUsername: '',
  gatewayId: '',
  gatewayPassword: '',
  gatewayTerminal: '', // gatewayTerminalId
  partnerId: '', //  || '9420'
  processorLaneId: 9999,
  ...sharedConfig,
};

export const requestTransactionTestData = {
  clerkId: '123',
  orderId: '456',
  amount: 10,
  taxAmount: 0,
  qhpSubtotal: 0,
};
