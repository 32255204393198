import {
  Customer,
  CustomerAccount,
  CustomerResponseApiResponse,
  EligibilitySearchMatch,
  EligibilitySearchMatchListApiResponse,
  TransactionPayment,
  PaymentOptionResult,
  PaymentType,
  Setting,
  SignatureType,
} from '@emporos/api-enterprise';
import {
  Button,
  ButtonShape,
  ButtonSize as Size,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Modal,
  Row,
  SmallSidebarWrapper,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
  VerificationModal,
} from '@emporos/components';
import {navigate} from '@reach/router';
import assert from 'assert';
import {
  createRef,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AcceptedPaymentTypes,
  ARPayment,
  CashPayment,
  CheckPayment,
  CreditCard,
  getPaymentTypeId,
  Transaction,
  TransactionUpdates,
  mapComplianceIndicators,
  mapIdCheckIndicators,
  mapTransactionPayment,
  Paid,
  PaymentInfoData,
  PaymentOptions,
  PaymentProvider,
  PaymentRef,
  PD as PDPayment,
  TotalsResult,
  UDPayment,
  useCardReaderCredentials,
  useTransaction,
  usePaymentProvider,
  TransactionPaymentConsolidate,
  getAcceptedPaymentTypesFromFeatureParam,
  StationType,
} from '../../../../../';
import {usePaymentsWindow} from '../../../../../contexts/PaymentsWindowProvider';
import {
  useAlertState,
  useBetaFeatures,
  useNetworkAvailable,
} from '../../../../../contexts';
import {isPatientPayTransaction, normalizeCustomer} from '../../../../../utils';
import {mergeTaxes} from '../../../../../utils/taxes';
import {OfflineSynced, OfflineTransaction} from '../../../../../api';
import {isMailout} from '../../../../../utils/session';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    flex: 1;
    padding: 0 12px 20px 12px;
  }
  @media (max-width: 1180px) {
    margin-left: 15px;
  }
`;

function formatMoney(money: number) {
  return money.toFixed(2);
}

function mapPaymentOptions(paymentOptions: PaymentOptionResult[]): Set<string> {
  return new Set(paymentOptions.map(o => o.displayName));
}

export function mapUDP(
  paymentTenders: PaymentType[],
  paymentOptions: PaymentOptionResult[],
): PaymentType[] {
  return paymentOptions
    .filter(o => o.displayName.startsWith('User'))
    .map(o => paymentTenders.find(t => t.name === o.displayName))
    .filter(Boolean) as PaymentType[];
}

interface Props {
  customer: Customer | null;
  showCreditCardsOnFile: boolean;
  showVantiv?: boolean;
  showHostedPayment?: boolean;
  creditCardPendingPaymentExpirationMinutes: number;
  isOnline: boolean;
  navigateTo: typeof navigate;
  credentials: ReturnType<typeof useCardReaderCredentials>['credentials'];
  transaction: Transaction;
  processedPayments: TransactionPayment[];
  totals: TotalsResult;
  settings: Setting[];
  paymentTenders: PaymentType[];
  paymentOptions: PaymentOptionResult[];
  stationType: StationType;
  mailoutSignature: TransactionUpdates;
  updateTransaction: (updates: TransactionUpdates) => Promise<void>;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  onSearchEligibility: (
    query: string,
  ) => Promise<EligibilitySearchMatchListApiResponse>;
  onGetCustomer: () => void;
  onGetEmployee: (
    customer: EligibilitySearchMatch,
  ) => Promise<CustomerResponseApiResponse>;
  onCreateAR: () => Promise<CustomerAccount | undefined>;
  onCreatePD: (id: number) => Promise<CustomerAccount | undefined>;
  signatureTypes: Array<SignatureType>;
  paymentProvider: PaymentProvider;
  onUpdateTransaction: (updates: TransactionUpdates) => void;
}

export function CustomerPayment({
  customer,
  showCreditCardsOnFile,
  showVantiv,
  showHostedPayment,
  creditCardPendingPaymentExpirationMinutes,
  isOnline,
  navigateTo,
  credentials,
  transaction,
  processedPayments,
  totals,
  settings,
  paymentTenders,
  paymentOptions,
  stationType,
  mailoutSignature,
  updateTransaction,
  onSearchEligibility,
  onGetCustomer,
  onGetEmployee,
  onCreateAR,
  onCreatePD,
  setSelectedPayment,
  signatureTypes,
  paymentProvider,
  onUpdateTransaction,
}: Props): JSX.Element {
  const {state: paymentsWindowState} = usePaymentsWindow();
  const {pendingPayments} = useTransaction();
  const [amount, setAmount] = useState(0);
  const [awaitingContinue, setAwaitingContinue] = useState(false);
  const [canPayWithPD, setCanPayWithPD] = useState(false);
  const [canPayWithAR, setCanPayWithAR] = useState(false);
  const [canPayWithUDP, setCanPayWithUDP] = useState(false);
  const [canPayWithCreditCard, setCanPayWithCreditCard] = useState(false);
  const [changeDueOpen, setChangeDueOpen] = useState(false);
  const [paymentNumber, setPaymentNumber] = useState<string | null>(null);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(
    getInitialPaymentTypeId(),
  );
  const [paymentTypeDesc, setPaymentTypeDesc] = useState<string>('');

  const [paymentType, setPaymentType] = useState<AcceptedPaymentTypes>(
    getInitialPaymentType(),
  );
  const {verificationModal} = useBetaFeatures();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const useMailoutFlow = isMailout(stationType);

  function getInitialPaymentTypeId(): number {
    const type = getAcceptedPaymentTypesFromFeatureParam();
    return getPaymentTypeId(type, paymentTenders) || 0;
  }

  function getInitialPaymentType(): AcceptedPaymentTypes {
    return getAcceptedPaymentTypesFromFeatureParam();
  }

  const paymentRef = createRef<PaymentRef>();
  const {totalDue, qhpAmount} = totals;
  const showingPaidView = totalDue === 0 && !awaitingContinue;
  const {cancelTransaction} = usePaymentProvider();
  const isPtPayTransaction = isPatientPayTransaction(transaction);
  const {isNplex, isControlledSubstance, isRequiredAge, isIDRequiredOTC} =
    mapIdCheckIndicators(transaction);
  const showIdCapturePage =
    !transaction.identification &&
    ((isNplex && transaction.pseCheckResult !== 1) ||
      isControlledSubstance ||
      isRequiredAge ||
      isIDRequiredOTC);
  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );
  const showCompliancePage = showCounsel || showHipaa || showRelationship;
  const showSignaturePage = signatureTypes.length;
  const isConfirmPaymentDisabled =
    amount === 0 ||
    !paymentTypeId ||
    (paymentType !== 'Cash' && amount > totalDue) ||
    (paymentType === 'Check' && !paymentNumber) ||
    (paymentType === 'PD' && !canPayWithPD) ||
    (paymentType === 'UDP' && !canPayWithUDP) ||
    (paymentType === 'Credit Card' && !canPayWithCreditCard) ||
    (paymentType === 'AR' && !canPayWithAR);

  // This useEffect will be triggered when the component mounts
  useEffect(() => {
    //send cancel method to payment device to cancel any pending transactions from creditcard component
    cancelTransaction();
    //Reset device to default state to prevent overcharge error
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // this useEffect will be triggered when the returnFeature changes
  useEffect(() => {
    // if the returnFeature is not empty, update the payment type and payment type id
    if (
      paymentsWindowState.returnFeature &&
      paymentsWindowState.returnFeature !== ''
    ) {
      setPaymentTypeId(getInitialPaymentTypeId());
      setPaymentType(getInitialPaymentType());
    }
  }, [paymentsWindowState.returnFeature]);

  useEffect(() => {
    // whenever the payment type changes, we refresh this to exact...
    // this could be implemented by moving amount management to each payment
    // option
    setAmount(paymentType === 'Cash' ? 0 : totals.totalDue);
    if (paymentType !== 'UDP') {
      setPaymentNumber('');
    }

    if (paymentType !== 'Credit Card' && awaitingContinue) {
      setAwaitingContinue(false);
    }
  }, [paymentType, processedPayments.length]);
  const {online} = useNetworkAvailable();

  const getButtonText = () => {
    if (isPtPayTransaction && (showIdCapturePage || showCompliancePage))
      return 'Proceed to Compliance';
    else if (showSignaturePage && !useMailoutFlow) return 'Customer Signature';
    else return 'Receipt Delivery';
  };

  const _setPaymentType = useCallback(
    (type: AcceptedPaymentTypes) => {
      setPaymentType(type);
      setPaymentTypeId(getPaymentTypeId(type, paymentTenders) || 0);
    },
    [paymentType, paymentTenders],
  );
  const {notification} = useAlertState();

  const continueTransaction = (nextLocation: string) => {
    if (isNplex && transaction.pseCheckResult !== 1 && !online) {
      notification({
        title: 'Offline Error: PSE Items',
        description:
          'This transaction cannot move forward while offline with unapproved PSE items in the cart.',
        type: 'warning',
        icon: 'Warning',
      });
      return;
    }
    navigateTo(nextLocation);
  };

  const verificationUpdateTransaction = useCallback(() => {
    setShowVerificationModal(false);
    onUpdateTransaction({
      isVerified: true,
    } as Partial<Transaction> & OfflineSynced);
    return continueTransaction(
      showIdCapturePage
        ? '/sales/patient-pay-transactions/payments/id-check'
        : '/sales/patient-pay-transactions/payments/compliance',
    );
  }, [totals, onUpdateTransaction]);

  const proceedToComplianceHandler = (showIdCapturePage: boolean) => {
    setShowVerificationModal(false);

    if (
      transaction.items.filter(({rx}) => rx).length > 0 &&
      !(transaction as OfflineTransaction)?.isVerified
    ) {
      setShowVerificationModal(true);
    } else {
      setShowVerificationModal(false);

      onUpdateTransaction({
        isSynced: false,
        taxableSubTotal: totals.taxableSubTotal,
        discount: totals.discount,
        subTotal: totals.subTotal,
        totalSale: totals.transactionTotal,
        totalTax: totals.salesTax,
        qhpRxAmount: totals.qhpRxAmount,
        qhpAmount: totals.qhpAmount,
        qhpRxQty: totals.qhpRxQty,
        qhpOtherAmount: totals.qhpOtherAmount,
        qhpOtherQty: totals.qhpOtherQty,
        taxes: mergeTaxes(transaction.taxes, totals.transactionTaxes),
      } as Partial<Transaction> & OfflineSynced);
      continueTransaction(
        showIdCapturePage
          ? '/sales/patient-pay-transactions/payments/id-check'
          : '/sales/patient-pay-transactions/payments/compliance',
      );
    }
  };

  function addPaymentWithoutSideEffects(paymentInfo?: PaymentInfoData) {
    const paymentAmount = paymentInfo?.amountApproved || amount;

    const newPayment = mapTransactionPayment(
      transaction.transactionId,
      paymentInfo?.paymentTypeId || paymentTypeId,
      paymentInfo?.paymentTypeDesc || paymentTypeDesc,
      paymentAmount,
      {
        ...(amount > totalDue && {
          amountReturned: amount - totalDue,
          paymentTypeDesc: paymentInfo?.paymentTypeDesc || paymentTypeDesc,
        }),
        paymentNumber: paymentInfo?.paymentId || paymentNumber || '',
        gatewayPaymentID: paymentInfo?.epsReference,
        recordStatus: paymentInfo?.recordStatus || 'Active',
        jsonPaymentProcessorResponse: paymentInfo?.jsonPaymentProcessorResponse,
      },
    );
    newPayment.paymentTypeDesc =
      paymentInfo?.paymentTypeDesc || paymentTypeDesc;

    // If we are paying offline and paymentInfo includes customerAccount (AR payment), we need to store customerId for use when returning online
    if (!isOnline && paymentInfo?.customerAccount) {
      (newPayment as TransactionPaymentConsolidate).customerId =
        paymentInfo.customerAccount.customerId;
    }

    updateTransaction(prevTransaction => ({
      payments: [...prevTransaction.payments, newPayment],
    }));
  }

  function addPayment() {
    addPaymentWithoutSideEffects();
    setChangeDueOpen(false);
  }

  async function onConfirmPayment() {
    switch (paymentType) {
      case 'Credit Card': {
        // flipping this off is the responsibility of the <CreditCard />
        // experience which has confirmation modals for every action
        setAwaitingContinue(true);
        assert(
          paymentRef.current,
          'Attempting to make a card transaction without the Card UI open',
        );
        await paymentRef.current.onConfirmPayment();
        break;
      }
      case 'AR':
      case 'PD': {
        assert(
          paymentRef.current,
          'Attempting to make a AR transaction without the AR UI open',
        );
        const paymentInfo = await paymentRef.current.onConfirmPayment();
        if (paymentInfo) {
          addPaymentWithoutSideEffects(paymentInfo);
        }
        break;
      }
      case 'Cash':
        if (amount > totalDue) {
          setChangeDueOpen(true);
        } else {
          addPayment();
        }
        break;
      case 'Check':
      case 'UDP':
        addPayment();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Stack gutter={Gutter.L}>
        <Header title="Customer Payment">
          <ButtonShape
            disabled={!!pendingPayments.length}
            size={Size.Small}
            icon="User"
            data-testid="customer-info-btn"
            onClick={() => navigateTo('customer-info')}
          />
        </Header>

        <Row gutter={Gutter.XXL} style={{height: '100%', minHeight: 0}} noWrap>
          <Stack className="stack-mobile" style={{flex: 2}}>
            {(showingPaidView && <Paid />) ||
              (paymentType === 'Cash' && (
                <CashPayment
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Check' && (
                <CheckPayment
                  amount={amount}
                  setAmount={setAmount}
                  paymentNumber={paymentNumber || ''}
                  setPaymentNumber={setPaymentNumber}
                  totalDue={totalDue}
                />
              )) ||
              (paymentType === 'Credit Card' && (
                <CreditCard
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithCreditCard(
                      paymentRef.current.isAcceptablePayment(),
                    );
                  }}
                  cardsOnFile={
                    (showCreditCardsOnFile && customer?.creditCards) || []
                  }
                  showVantiv={showVantiv}
                  showHostedPayment={showHostedPayment}
                  creditCardPendingPaymentExpirationMinutes={
                    creditCardPendingPaymentExpirationMinutes
                  }
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  qhpAmount={qhpAmount}
                  // TODO: verify what tax amount we should send through. Is
                  // this additive to the totalDue or subtractive or ignored?
                  deviceCredentials={credentials}
                  onContinue={() => setAwaitingContinue(false)}
                  navigate={navigateTo}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                  paymentProvider={paymentProvider}
                  transaction={transaction}
                  updateTransaction={updateTransaction}
                  setSelectedPayment={setSelectedPayment}
                  cardHolderName={`${customer?.lastName}, ${customer?.firstName}`}
                />
              )) ||
              (paymentType === 'AR' && customer && (
                <ARPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithAR(paymentRef.current.isAcceptablePayment());
                  }}
                  customer={customer}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  isOnline={isOnline}
                  onChangeCustomer={onGetCustomer}
                  onCreateAR={onCreateAR}
                  arCreditLimit={Number(
                    settings.find(({name}) => name === 'ARCreditLimit')
                      ?.value || 0,
                  )}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'PD' && (
                <PDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithPD(paymentRef.current.isAcceptablePayment());
                  }}
                  amount={amount}
                  setAmount={setAmount}
                  totalDue={totalDue}
                  onGetEmployee={onGetEmployee}
                  onSearchEligibility={onSearchEligibility}
                  onCreatePD={onCreatePD}
                  isOnline={isOnline}
                  paymentTenders={paymentTenders}
                  ref={paymentRef}
                />
              )) ||
              (paymentType === 'UDP' && (
                <UDPayment
                  onChangeActivePayment={() => {
                    assert(paymentRef.current);
                    setCanPayWithUDP(paymentRef.current.isAcceptablePayment());
                  }}
                  udps={mapUDP(paymentTenders, paymentOptions)}
                  amount={amount}
                  setAmount={setAmount}
                  setPaymentTypeId={setPaymentTypeId}
                  setPaymentTypeDesc={setPaymentTypeDesc}
                  totalDue={totalDue}
                  ref={paymentRef}
                />
              )) || (
                <Stack
                  justify="center"
                  align="center"
                  gutter={Gutter.None}
                  style={{flex: 1}}
                >
                  <Illustration illustration="SelectPayment" />
                  <Text variant={TV.T2} align="center">
                    Please Select Payment Type
                  </Text>
                </Stack>
              )}

            {totalDue > 0 || awaitingContinue ? (
              <FooterGroup>
                <Button
                  type="button"
                  data-testid="back-button"
                  variant={BV.Secondary}
                  disabled={
                    isPtPayTransaction
                      ? false
                      : !!processedPayments.length || !!pendingPayments.length
                  }
                  flex
                  onClick={() => {
                    if (isPtPayTransaction) {
                      return navigateTo('/sales/patient-pay-transactions');
                    }

                    if (useMailoutFlow) {
                      return navigateTo('/sales/transactions');
                    }

                    if (showCompliancePage) {
                      return navigateTo(
                        '/sales/transactions/payments/compliance',
                      );
                    }

                    if (showIdCapturePage) {
                      return navigateTo(
                        '/sales/transactions/payments/id-check',
                      );
                    }

                    // time for animation to complete, then navigate
                    setTimeout(() => navigateTo('/sales/transactions'), 400);
                  }}
                >
                  Back
                </Button>
                <Button
                  flex
                  disabled={
                    isConfirmPaymentDisabled || !!pendingPayments.length
                  }
                  onClick={onConfirmPayment}
                >
                  Confirm Payment
                </Button>
              </FooterGroup>
            ) : (
              <FooterGroup>
                {useMailoutFlow ? (
                  <Button
                    flex
                    onClick={() => {
                      if (
                        signatureTypes.length &&
                        !transaction.signatures?.length
                      ) {
                        updateTransaction(mailoutSignature);
                      }
                      return navigateTo('../receipts');
                    }}
                  >
                    Receipt Delivery
                  </Button>
                ) : (
                  <>
                    {isPtPayTransaction && (
                      <Button
                        type="button"
                        data-testid="back-button"
                        variant={BV.Secondary}
                        disabled={!isPtPayTransaction}
                        flex
                        onClick={() => {
                          if (isPtPayTransaction) {
                            return navigateTo(
                              '/sales/patient-pay-transactions',
                            );
                          }
                        }}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      flex
                      onClick={() => {
                        if (isPtPayTransaction) {
                          if (showIdCapturePage || showCompliancePage) {
                            proceedToComplianceHandler(showIdCapturePage);
                          } else {
                            navigateTo(
                              showSignaturePage
                                ? '/sales/patient-pay-transactions/payments/acknowledgements'
                                : '/sales/patient-pay-transactions/payments/receipts',
                            );
                          }
                        } else {
                          navigateTo(
                            showSignaturePage
                              ? '../acknowledgements'
                              : '../receipts',
                          );
                        }
                      }}
                    >
                      {getButtonText()}
                    </Button>
                  </>
                )}
              </FooterGroup>
            )}
          </Stack>

          <RightSidebar data-testid="Sidebar">
            <PaymentOptions
              paymentOptions={mapPaymentOptions(paymentOptions)}
              customerPaymentsAvailable={!!customer}
              disabled={showingPaidView || !!pendingPayments.length}
              activePaymentType={paymentType}
              setPaymentType={_setPaymentType}
              isOnline={isOnline}
              arDisabled={
                (customer &&
                  customer.accounts &&
                  customer.accounts.length > 0 &&
                  customer.accounts.every(
                    account => account.creditHoldIndicator,
                  )) ||
                (customer && customer.creditHoldIndicator)
                  ? true
                  : false
              }
            />
          </RightSidebar>
        </Row>
      </Stack>
      {verificationModal && transaction.customer && (
        <VerificationModal
          customerInfo={normalizeCustomer(transaction.customer)}
          visible={showVerificationModal}
          onCancel={() => setShowVerificationModal(false)}
          onContinue={verificationUpdateTransaction}
        />
      )}

      <Modal
        data-testid="Modal__ChangeDue"
        visible={changeDueOpen}
        icon="Cash"
        color="success"
        onContinue={addPayment}
        buttonText="Okay"
        title={`Change Due: $${formatMoney(amount - totalDue)}`}
        subtitle="Please provide change to the customer. Amount can be viewed again in the payment details."
      />
    </>
  );
}
