import {Transaction, TransactionExtra} from '@emporos/api-enterprise';

export const TransactionSourceKey = 'TransactionSource';
export enum TransactionSource {
  Hilo,
  PatientPay,
}

export const getTransactionSource = (transactionExtras: TransactionExtra[]) => {
  const source = transactionExtras.find(
    extra => extra.dataKey === TransactionSourceKey,
  )?.dataValue;

  if (
    TransactionSource[source as keyof typeof TransactionSource] !== undefined
  ) {
    return TransactionSource[source as keyof typeof TransactionSource];
  } else if (
    transactionExtras.some(
      extra =>
        extra.dataKey === TransactionSource[TransactionSource.PatientPay],
    ) ||
    transactionExtras.some(
      extra =>
        extra.dataKey === TransactionSourceKey &&
        extra.dataValue === TransactionSource[TransactionSource.PatientPay],
    )
  ) {
    return TransactionSource.PatientPay;
  } else {
    return TransactionSource.Hilo;
  }
};

export const isPatientPayTransaction = (transaction: Transaction) => {
  return (
    getTransactionSource(transaction.extras) === TransactionSource.PatientPay
  );
};
