import {
  KeyboardScannerReader,
  ScanResult,
  SignalListener,
  Symbology,
} from '@emporos/barcodes';
import {stackContext} from '@emporos/components';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {FloatingScanner} from './';
import {scannerManager} from './ScannerManager';

export interface Subscriber {
  listener: SignalListener<ScanResult>;
  enabledSymbologies?: Array<Symbology>;
}

export type BarcodeScannerContext = {
  /**
   * Subscribe to scans when they occur at a particular layer.
   */
  subscribe: (layer: number, subscriber: Subscriber) => void;
  /**
   * Unsubscribe from scans at a layer.
   */
  unsubscribe: (layer: number, listener: SignalListener<ScanResult>) => void;
  setOpen?: Dispatch<SetStateAction<boolean>>;
};

export type BarcodeScannerProviderProps = PropsWithChildren<{
  keyboardScannerService: KeyboardScannerReader;
  onScanditError: (error: Error) => void;
  onScannerError: (error: Error) => void;
  licenseKey: string;
}>;

export const barcodeScannerContext = createContext<BarcodeScannerContext>({
  subscribe: () => {},
  unsubscribe: () => {},
});

export function BarcodeScannerProvider({
  children,
  keyboardScannerService,
  onScanditError,
  onScannerError,
  licenseKey,
}: BarcodeScannerProviderProps) {
  (
    window as unknown as {
      keyboardScannerService: KeyboardScannerReader;
    }
  ).keyboardScannerService = keyboardScannerService;
  const [open, setOpen] = useState(false);
  const [subscribersByLayer, setSubscribersByLayer] = useState<
    (Subscriber | null)[]
  >([]);
  const {top} = useContext(stackContext);

  const onScanFinished = useCallback(
    (scanResult: ScanResult) => subscribersByLayer[top]?.listener(scanResult),
    [top, subscribersByLayer],
  );
  const context = useMemo(
    () => ({
      subscribe: (layer: number, subscriber: Subscriber) => {
        setSubscribersByLayer(subscribers => {
          const clone = [...subscribers];
          clone[layer] = subscriber;
          return clone;
        });
      },
      unsubscribe: (layer: number, listener: SignalListener<ScanResult>) =>
        setSubscribersByLayer(subscribers => {
          if (subscribers[layer]?.listener === listener) {
            const clone = [...subscribers];
            clone[layer] = null;
            return clone;
          }
          return subscribers;
        }),
      open: false,
      setOpen,
    }),
    [],
  );

  useEffect(() => {
    keyboardScannerService.start();
    return () => keyboardScannerService.stop();
  }, [keyboardScannerService]);

  useEffect(() => {
    keyboardScannerService.scanFinished.listen(onScanFinished);
    return () => keyboardScannerService.scanFinished.unlisten(onScanFinished);
  }, [keyboardScannerService, onScanFinished]);

  useEffect(() => {
    void scannerManager.initialize(licenseKey);
    return () => {
      void scannerManager.cleanup();
    };
  }, [licenseKey]);

  return (
    <barcodeScannerContext.Provider value={context}>
      {children}
      <FloatingScanner
        enabled={Boolean(subscribersByLayer[top])}
        onScan={onScanFinished}
        onScanError={onScannerError}
        onError={onScanditError}
        licenseKey={licenseKey}
        enabledSymbologies={subscribersByLayer[top]?.enabledSymbologies}
        open={open}
        setOpen={setOpen}
      />
    </barcodeScannerContext.Provider>
  );
}

export function useBarcodeScanner() {
  return useContext(barcodeScannerContext);
}
