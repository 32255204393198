import {memo, useCallback, useEffect, useRef} from 'react';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import styled from 'styled-components';
import {scannerManager} from './ScannerManager';

const DELAY_BETWEEN_SCANS = 700;

export interface BarcodeScannerProps {
  onScan: (result: SDCBarcode.Barcode) => void;
  onScanError: (error: Error) => void;
  onError: (error: Error) => void;
  // TODO: this should be made non-optional once we add this to the Enterprise
  // settings API
  licenseKey?: string;
  enabledSymbologies: SDCBarcode.Symbology[];
  height?: number | string;
  isScanning?: boolean;
}
const StyledBarcodeScanner = styled.div`
  height: 100%;

  .scandit {
    &.scandit-container {
      border-radius: 12px;
    }
    .scandit-video {
      border-radius: 12px;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

export const BarcodeScanner = memo(
  ({
    onScan,
    onScanError,
    onError,
    enabledSymbologies,
    height = '100%',
  }: BarcodeScannerProps): JSX.Element => {
    const dataCaptureView = useRef<HTMLDivElement | null>(null);

    const didScanListener = useCallback(
      async (
        barcodeCaptureMode: SDCBarcode.BarcodeCapture,
        session: SDCBarcode.BarcodeCaptureSession,
      ): Promise<void> => {
        try {
          // no more didScan callbacks will be invoked after this call.
          await barcodeCaptureMode.setEnabled(false);
          const barcode: SDCBarcode.Barcode =
            session.newlyRecognizedBarcodes[0];
          onScan(barcode);

          // Delay to avoid multiple scans of the same barcode.
          await new Promise(resolve =>
            setTimeout(resolve, DELAY_BETWEEN_SCANS),
          );
          // re-enable scanning
          await barcodeCaptureMode.setEnabled(true);
        } catch (error) {
          onScanError(error as Error);
        }
      },
      [onScan, onScanError],
    );

    // Every time we get a new onScan callback,
    // we first need to remove the old one to be repalced with the new one
    useEffect(() => {
      scannerManager.replaceScanListener(didScanListener);
      return () => {
        scannerManager.removeScanListener();
      };
    }, [didScanListener]);

    useEffect(() => {
      scannerManager.enableSymbologies(enabledSymbologies);
    }, [enabledSymbologies]);

    useEffect(() => {
      const init = async () => {
        scannerManager.connectToElement(dataCaptureView.current!);
        scannerManager.enableCamera(true);
        scannerManager.enableScanning(true);
      };

      void init().catch(error => onError(error));
    }, [onError]);

    useEffect(() => {
      const cleanup = async () => {
        scannerManager.detachFromElement();
        await scannerManager.enableScanning(false);
        await scannerManager.enableCamera(false);
      };

      return () => {
        void cleanup();
      };
    }, []);

    return (
      <Container style={{height: height}}>
        <StyledBarcodeScanner
          ref={dataCaptureView}
          id="data-capture-view"
        ></StyledBarcodeScanner>
      </Container>
    );
  },
);
