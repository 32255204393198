import {Gutter, InputSquare, Modal, Row} from '@emporos/components';
import {memo, useCallback} from 'react';
import styled from 'styled-components';
import {AcceptedPaymentTypes} from '../';
import {useTransaction} from '../../../../../hooks/useTransaction';
import {PaymentService} from '../../../../../services/PaymentService';
import {
  Transaction,
  useAuthentication,
  useGlobalSettings,
  useTransactionsState,
} from '../../../../../contexts';
import {usePaymentsWindow} from '../../../../../contexts/PaymentsWindowProvider';
import {useIdleTimerContext} from 'react-idle-timer';
import {useState} from 'react';
import {useTotals} from '../../../../../hooks';
import {OfflineSynced} from '../../../../../api';

interface Props {
  paymentOptions: Set<string>; // TODO: this should be a union of strings
  customerPaymentsAvailable: boolean;
  activePaymentType: AcceptedPaymentTypes;
  setPaymentType: (v: AcceptedPaymentTypes) => void;
  disabled: boolean;
  isOnline: boolean;
  arDisabled: boolean;
}

const PaymentButton = styled(InputSquare)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;
function PaymentOptionsComponent({
  paymentOptions,
  customerPaymentsAvailable,
  activePaymentType,
  setPaymentType,
  disabled,
  isOnline,
  arDisabled,
}: Props): JSX.Element {
  const {transaction, updateTransaction} = useTransaction();
  const {totals} = useTotals();
  const {PAYMENT_DOMAIN_ENABLED} = process.env;
  const {user} = useAuthentication();
  const {session} = useTransactionsState();
  const token = user ? user.access_token : '';
  const {tenantId} = useGlobalSettings();
  const paymentService = new PaymentService(token, tenantId);
  const {paymentsWindowService} = usePaymentsWindow();
  const {pause: idleTimerPause} = useIdleTimerContext();

  const [transactionBadState, setTransactionBadState] = useState(false);
  const hasUDP = Array.from(paymentOptions).some(n => n.startsWith('User'));

  const _openPaymentsDomain = useCallback(() => {
    idleTimerPause();

    //validate that the transaction have total amount greater than 0 and if not show a Modal with the message "Your session needs to be refresh please click ok to proceed"
    if (transaction?.totalSale <= 0 || transaction?.subTotal <= 0) {
      const fieldsToUpdate = {
        isSynced: false,
        taxableSubTotal: totals.taxableSubTotal,
        discount: totals.discount,
        subTotal: totals.subTotal,
        totalSale: totals.transactionTotal,
        totalTax: totals.salesTax,
        qhpRxAmount: totals.qhpRxAmount,
        qhpAmount: totals.qhpAmount,
        qhpRxQty: totals.qhpRxQty,
        qhpOtherAmount: totals.qhpOtherAmount,
        qhpOtherQty: totals.qhpOtherQty,
      } as Partial<Transaction> & OfflineSynced;

      updateTransaction(fieldsToUpdate);

      setTransactionBadState(true);
      return;
    }

    paymentService.OpenPaymentsDomain(transaction, paymentsWindowService);
  }, [paymentService, transaction, paymentOptions, user, session]);

  const onCreditCardClick = useCallback(() => {
    PAYMENT_DOMAIN_ENABLED != 'false'
      ? _openPaymentsDomain()
      : setPaymentType('Credit Card');
  }, [_openPaymentsDomain]);

  return (
    <Row gutter={Gutter.None} justify="space-around">
      {paymentOptions.has('Cash') && (
        <PaymentButton
          icon="Cash"
          name="payment-option"
          text="Cash"
          value="Cash"
          checked={activePaymentType === 'Cash'}
          disabled={disabled}
          onChange={() => {
            setPaymentType('Cash');
          }}
        />
      )}
      {paymentOptions.has('Check') && (
        <PaymentButton
          icon="Check"
          name="payment-option"
          text="Check"
          value="Check"
          checked={activePaymentType === 'Check'}
          disabled={disabled}
          onChange={() => setPaymentType('Check')}
        />
      )}
      {paymentOptions.has('Card') && (
        <PaymentButton
          icon="CreditCard"
          name="payment-option"
          text="Credit"
          value="Credit Card"
          checked={activePaymentType === 'Credit Card'}
          disabled={disabled || !isOnline}
          onChange={onCreditCardClick}
        />
      )}
      {paymentOptions.has('Account Receivable') && (
        <PaymentButton
          icon="Bill"
          name="payment-option"
          text="AR"
          value="AR"
          checked={activePaymentType === 'AR'}
          disabled={disabled || !customerPaymentsAvailable || arDisabled}
          onChange={() => setPaymentType('AR')}
        />
      )}
      {paymentOptions.has('Payroll Deduct') && (
        <PaymentButton
          icon="IdCard"
          name="payment-option"
          text="PD"
          value="PD"
          checked={activePaymentType === 'PD'}
          disabled={disabled || !isOnline}
          onChange={() => {
            setPaymentType('PD');
          }}
        />
      )}
      {hasUDP && (
        <PaymentButton
          icon="MoneyBag"
          name="payment-option"
          text="Custom"
          value="UDP"
          checked={activePaymentType === 'UDP'}
          disabled={disabled}
          onChange={() => {
            setPaymentType('UDP');
          }}
        />
      )}

      <Modal
        visible={transactionBadState} //outdated state of the transaction
        data-testid="Modal__PaymentInProcess"
        icon="CreditCard"
        color="warning"
        title="Update Transaction"
        subtitle={
          'Your session needs to be refreshed. Please click Okay to proceed.'
        }
        buttonText={'Okay'}
        onContinue={() => {
          // Construct the new URL with the transactionId to guarantee that the transaction is loaded
          const currentUrl = new URL(location.href);
          currentUrl.searchParams.set(
            'transactionId',
            transaction.transactionId,
          );

          // Reload the page with the new URL
          window.location.href = currentUrl.href;
        }}
      />
    </Row>
  );
}
export const PaymentOptions = memo(PaymentOptionsComponent);
