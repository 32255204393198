import {AlertBannerStack, NotificationAlert} from '@emporos/components';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {v4 as uuid} from 'uuid';

export type NotificationFn = (alert: Omit<NotificationAlert, 'id'>) => void;

export type AlertStateContext = {
  notification: NotificationFn;
  reset: () => void;
};
export interface AlertStateProviderProps {
  notification: NotificationFn;
  reset: () => void;
}

export const alertStateContext = createContext<AlertStateContext>({
  notification: () => {
    return;
  },
  reset: () => {
    return;
  },
});

function AlertStateProvider({children}: {children: ReactNode}): ReactElement {
  const [alerts, setAlerts] = useState<NotificationAlert[]>([]);

  const notification = useCallback((alert: Omit<NotificationAlert, 'id'>) => {
    setAlerts(prevAlerts => {
      const id = uuid();
      setTimeout(() => removeAlert(id), 1000 * 30);
      return [...prevAlerts, {...alert, id}];
    });
  }, []);

  const reset = useCallback(() => setAlerts([]), []);

  const removeAlert = useCallback(
    (_id: string) =>
      setAlerts(prevAlerts => prevAlerts.filter(({id}) => id !== _id)),
    [],
  );

  return (
    <>
      <AlertBannerStack alerts={alerts} onDismiss={removeAlert} />
      <alertStateContext.Provider
        value={{
          notification,
          reset,
        }}
      >
        {children}
      </alertStateContext.Provider>
    </>
  );
}
const useAlertState = (): AlertStateProviderProps =>
  useContext(alertStateContext);
export {AlertStateProvider, useAlertState};
