import {
  ApiResponse,
  FeatureConfig,
  IPaymentService,
  Integration,
} from './IPaymentService';
import {ManagementPayloadIntegration} from '../api';
import {Transaction} from '@emporos/api-enterprise/src';
import {PaymentsWindowService} from './PaymentsWindowService';
import {ConsoleLogger} from '../utils/console-logger';

export interface LinkToPayCounts {
  completed: number;
  errored: number;
  isProblem: boolean;
}

export class PaymentService implements IPaymentService {
  private PAYMENT_API_URL: string;
  private CLIENT_API_URL: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();

  private TOKEN = '';

  constructor(token: string, tenantId: string | void) {
    const paymentBaseUrl = process.env.PAYMENT_BASE_URL ?? '';
    const clientApiUrl = process.env.CLIENT_API_URL ?? '';

    this.PAYMENT_API_URL = paymentBaseUrl + '/api/' + tenantId + '/';
    this.CLIENT_API_URL = clientApiUrl + '/client/cache/sessions/';

    this.TOKEN = token;
  }

  async GetEnabledFeatures(
    siteId?: number,
    stationId?: number,
  ): Promise<FeatureConfig[]> {
    let url = this.PAYMENT_API_URL + 'integration/enabledFeatures';
    if (siteId) url += '/' + siteId;
    if (stationId) url += '/' + stationId;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });
      const res: ApiResponse<FeatureConfig[]> = await response.json();
      return res.data;
    } catch (error) {
      this._consoleLogger.logError('Error while making GET request: ', error);

      return [];
    }
  }

  async OpenPaymentsDomain(
    transaction: Transaction,
    paymentsWindowService: PaymentsWindowService,
    transactionPaymentId?: string,
  ): Promise<void> {
    const url = transactionPaymentId
      ? `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}/${transactionPaymentId}`
      : `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API: ',
          dataResponse.errorMessages,
        );

        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);
      paymentsWindowService?.open('pay/' + dataResponse.data.urlCode);
    } catch (error) {
      this._consoleLogger.logError('Error while making POST request:', error);
      throw error;
    }
  }

  async OpenPaymentsManage(
    username: string,
    sessionId: string,
    siteId: number,
    paymentsWindowService: PaymentsWindowService,
  ): Promise<void> {
    const getCode = this.PAYMENT_API_URL + 'integration/init-manage';

    const mangePyl: ManagementPayloadIntegration = {
      sessionId: sessionId,
      username: username,
      siteId: siteId,
      transactionId: '00000-0000-0000-0000-0000-0000',
    };

    try {
      // Make POST request to Payment API
      const response = await fetch(getCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify(mangePyl),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API:',
          dataResponse.errorMessages,
        );
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);

      await paymentsWindowService?.open(
        'manage/' +
          dataResponse.data.urlCode +
          '/siteId/' +
          siteId +
          '/sessionId/' +
          sessionId +
          '/username/' +
          username,
      );
    } catch (error) {
      this._consoleLogger.logError('Error while making POST request:', error);
      throw error;
    }
  }

  async RedirectLtpSms(
    transaction: Transaction,
    paymentsWindowService: PaymentsWindowService,
  ): Promise<void> {
    const url = `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}`;

    try {
      // Make POST request to Payment API
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API:',
          dataResponse.errorMessages,
        );
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);
      const ltpsms = 'pay/ltp/sms/' + dataResponse.data.urlCode;

      await paymentsWindowService?.open(ltpsms);
    } catch (error) {
      this._consoleLogger.logError('Error while making POST request:', error);
      throw error;
    }
  }

  async GetLtpCounts(siteId: number): Promise<LinkToPayCounts> {
    let result = {
      completed: 0,
      errored: 0,
      isProblem: false,
    };

    try {
      const getCount = this.PAYMENT_API_URL + 'linktopay/siteId/' + siteId;
      const response = await fetch(getCount);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<LinkToPayCounts> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API:',
          dataResponse.errorMessages,
        );
        result.isProblem = true;
      }

      result = {...result, ...dataResponse.data};
    } catch (error) {
      this._consoleLogger.logError('Error while making GET request:', error);
      result.isProblem = true;
    }

    return result;
  }

  async ReturnToOnHold(
    transaction: Transaction,
    linkToPayCode?: string,
  ): Promise<boolean> {
    let result = false;

    try {
      const backToOnHold = this.PAYMENT_API_URL + 'linktopay/' + linkToPayCode;
      const response = await fetch(backToOnHold, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
        body: JSON.stringify({
          transactionId: transaction.transactionId,
          sessionId: transaction.sessionId,
        }),
      });

      let dataResponse: ApiResponse<boolean>;
      if (response.ok) {
        return true;
      } else if (!response.ok) {
        try {
          dataResponse = await response.json();
        } catch {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (
          dataResponse.errorMessages &&
          dataResponse.errorMessages.length > 0
        ) {
          this._consoleLogger.logError(
            'Error messages from the API:',
            dataResponse.errorMessages,
          );
        }

        if (!!dataResponse.data && typeof dataResponse.data === 'boolean') {
          result = dataResponse.data;
        }
      }
    } catch (error) {
      this._consoleLogger.logError('Error while making PATCH request:', error);
      result = false;
    }

    return result;
  }

  async deleteCreditCard(
    transaction: Transaction,
    cardtoken: string | null,
  ): Promise<void> {
    const url = `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}/ccof`;

    try {
      // Make POST request to Payment API
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API:',
          dataResponse.errorMessages,
        );

        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);
      //dataResponse.data.urlCode
      //http request DELETE from api base/ccof/urlcode with parameters body
      //CCOFDelete(string tenantId,string token ,string cardId)
      const ccofRequest = {
        token: cardtoken,
      };

      try {
        // Make DELETE request to Payment API
        const unboard =
          this.PAYMENT_API_URL +
          'ccofmanagementintegration/ccof/unboard/' +
          dataResponse.data.urlCode +
          '/' +
          ccofRequest.token;

        const response = await fetch(unboard, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.TOKEN}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        this._consoleLogger.logError('Error while making POST request:', error);
        throw error;
      }
    } catch (error) {
      this._consoleLogger.logError('Error while making POST request:', error);
      throw error;
    }
  }

  async UpdateCreditCard(
    transaction: Transaction,
    paymentsWindowService: PaymentsWindowService,
    ccofId: string | null,
  ): Promise<void> {
    const url = `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}/ccof`;
    try {
      // Make POST request to Payment API
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.log('Error messages from the API:', dataResponse.errorMessages);
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);

      await paymentsWindowService?.open(
        'ccof/' + dataResponse.data.urlCode + '/' + ccofId,
      );
    } catch (error) {
      console.log('Error while making POST request:', error);
      throw error;
    }
  }

  async OpenCCOFPaymentsDomain(
    transaction: Transaction,
    paymentsWindowService: PaymentsWindowService,
  ): Promise<void> {
    const url = `${this.CLIENT_API_URL}${transaction.sessionId}/payments-domain/init-integration/${transaction.transactionId}/ccof`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const dataResponse: ApiResponse<Integration> = await response.json();

      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        this._consoleLogger.logError(
          'Error messages from the API:',
          dataResponse.errorMessages,
        );
        throw new Error('Error messages received from the API.');
      }

      this.setCookie(`Payments_${dataResponse.data.urlCode}`, this.TOKEN, 1);
      await paymentsWindowService?.open('ccof/' + dataResponse.data.urlCode);
    } catch (error) {
      this._consoleLogger.logError('Error while making POST request:', error);
      throw error;
    }
  }

  private setCookie(name: string, value: string, minutes: number): void {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    const isLocalhost = window.location.hostname.includes('localhost');
    const domain = isLocalhost
      ? ''
      : '; domain=' + window.location.hostname.split('.').slice(-2).join('.');
    const sameSite = isLocalhost ? 'Lax' : 'Strict';
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; path=/' +
      domain +
      '; SameSite=' +
      sameSite +
      '; Secure';
  }
}
