import {
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureListener,
  barcodeCaptureLoader,
} from 'scandit-web-datacapture-barcode';
import {
  configure,
  Camera,
  VideoResolution,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinderLineStyle,
  RectangularViewfinder,
  RectangularViewfinderStyle,
} from 'scandit-web-datacapture-core';
import {
  BarcodeCaptureSettingsExtended,
  DEFAULT_SYMBOLOGIES,
} from './ScannerSettings/BarcodeCaptureSettingsExtended';

const SCANDIT_SELF_HOSTED_LIBRARY = `${process.env.PUBLIC_URL}/scandit`;

export interface SDK {
  initialize: (licenseKey: string) => Promise<void>;
  cleanup: () => Promise<void>;
  connectToElement: (element: HTMLElement) => void;
  detachFromElement: () => void;
  enableCamera: (enabled: boolean) => Promise<void>;
  enableScanning: (enabled: boolean) => Promise<void>;
  enableSymbology: (symbology: Symbology, enabled: boolean) => Promise<void>;
  enableSymbologies: (newSymbologies: Symbology[]) => Promise<void>;
  removeScanListener: () => void;
  replaceScanListener: (
    callback: NonNullable<BarcodeCaptureListener['didScan']>,
  ) => void;
  getEnabledSymbologies: () => Symbology[];
  selectBestCameraResolution: () => Promise<void>;
}

export function scanditManagerInit(): SDK {
  let context: DataCaptureContext | undefined;
  let view: DataCaptureView;
  let camera: Camera;
  let settings: BarcodeCaptureSettings;
  let barcodeCapture: BarcodeCapture;
  let overlay: BarcodeCaptureOverlay;
  let host: HTMLElement;
  let barcodeCaptureListener: BarcodeCaptureListener;

  return {
    async initialize(licenseKey: string) {
      await configure({
        licenseKey: licenseKey,
        libraryLocation: SCANDIT_SELF_HOSTED_LIBRARY,
        moduleLoaders: [
          barcodeCaptureLoader({
            highEndBlurryRecognition: false,
          }),
        ],
      });

      context = await DataCaptureContext.create();
      view = await DataCaptureView.forContext(context);
      settings = new BarcodeCaptureSettingsExtended([]);

      barcodeCapture = await BarcodeCapture.forContext(context, settings);
      await barcodeCapture.setEnabled(false);

      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        barcodeCapture,
        view,
        BarcodeCaptureOverlayStyle.Frame,
      );
      await overlay.setViewfinder(
        new RectangularViewfinder(
          RectangularViewfinderStyle.Square,
          RectangularViewfinderLineStyle.Light,
        ),
      );
      await view.addOverlay(overlay);

      camera = Camera.default;
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
      await context.setFrameSource(camera);

      // ============================================================================================================
      // NOTE:
      // The following is a workaround to keep the scanner working correctly with React.
      // The DataCaptureView requires the host element to remain the same throughout its lifecycle.
      // Unfortunately, between re-renders, React doesn't keep the same nodes alive, but creates new ones each time.
      // This means that, between re-renders, the DataCaptureView might stop rendering overlays, viewfinders etc...
      // To fix this, we connect the DataCaptureView to a hidden element, then append it to a React component.
      // This allows us to keep the node alive, and the DataCaptureView rendering correctly.
      // When mounting the scanner component, we show the hidden node, then hide it when unmounting the scanner.
      // ============================================================================================================
      host = document.createElement('div');
      host.style.display = 'none';
      host.style.width = '100%';
      host.style.height = '100%';
      document.body.append(host);
      view.connectToElement(host);
    },
    async cleanup() {
      await camera.switchToDesiredState(FrameSourceState.Off);
      await context?.dispose();
      await context?.removeAllModes();
      await view.removeOverlay(overlay);
      barcodeCapture.removeListener(barcodeCaptureListener);
      view.detachFromElement();
    },
    connectToElement(element: HTMLElement) {
      host.style.display = 'block';
      element.append(host);
    },
    detachFromElement() {
      host.style.display = 'none';
      document.body.append(host);
    },
    async enableCamera(enabled: boolean) {
      camera = context?.frameSource as Camera;
      await camera.switchToDesiredState(
        enabled ? FrameSourceState.On : FrameSourceState.Off,
      );
    },
    async enableScanning(enabled: boolean) {
      await barcodeCapture.setEnabled(enabled);
    },
    async enableSymbology(symbology: Symbology, enabled: boolean) {
      settings.enableSymbology(symbology, enabled);
      await barcodeCapture.applySettings(settings);
    },
    async enableSymbologies(newSymbologies: Symbology[]) {
      let applySettings = false;

      const symbsRequested =
        newSymbologies.length === 0 ? DEFAULT_SYMBOLOGIES : newSymbologies;

      const previousSymbologies = settings.enabledSymbologies;
      const symbologiesToDisable = previousSymbologies.filter(
        symbology => !symbsRequested.includes(symbology),
      );
      const symbologiesToEnable = symbsRequested.filter(
        symbology => !previousSymbologies.includes(symbology),
      );

      if (symbologiesToDisable.length > 0) {
        symbologiesToDisable.forEach(symbology => {
          settings.enableSymbology(symbology, false);
        });
        applySettings = true;
      }

      if (symbologiesToEnable.length > 0) {
        settings.enableSymbologies(symbologiesToEnable);
        applySettings = true;
      }

      if (applySettings) {
        await barcodeCapture.applySettings(settings);
      }
    },
    removeScanListener() {
      barcodeCapture.removeListener(barcodeCaptureListener);
    },
    replaceScanListener(
      callback: NonNullable<BarcodeCaptureListener['didScan']>,
    ) {
      barcodeCaptureListener = {
        didScan: callback,
      };
      barcodeCapture.addListener(barcodeCaptureListener);
    },
    getEnabledSymbologies() {
      return settings.enabledSymbologies;
    },
    async selectBestCameraResolution() {
      if (context) {
        const cameraSettings = BarcodeCapture.recommendedCameraSettings;
        if (isResolutionSupported(context, VideoResolution.UHD4K)) {
          cameraSettings.preferredResolution = VideoResolution.UHD4K;
        } else if (isResolutionSupported(context, VideoResolution.FullHD)) {
          cameraSettings.preferredResolution = VideoResolution.FullHD;
        }
        await camera.applySettings(cameraSettings);
      }
    },
  };
}

export const scannerManager = scanditManagerInit();

const videoResolutionHeightDictionary: ReadonlyMap<VideoResolution, number> =
  new Map([
    [VideoResolution.Auto, 0],
    [VideoResolution.HD, 720],
    [VideoResolution.FullHD, 1080],
    [VideoResolution.UHD4K, 2160],
  ]);

export function isResolutionSupported(
  ctx: DataCaptureContext,
  resolution: VideoResolution,
): boolean {
  const resolutionHeightInPixels =
    videoResolutionHeightDictionary.get(resolution)!;
  const camera = ctx.frameSource as Camera;
  const cameraResolution = camera.currentResolution ?? {
    height: 0,
    width: 0,
  };
  return (
    cameraResolution.height >= resolutionHeightInPixels ||
    cameraResolution.width >= resolutionHeightInPixels
  );
}
