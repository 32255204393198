export class CacheWrapper {
  public static put = async (
    cacheName: string,
    url: string,
    response: Response,
  ) => {
    await global.caches
      ?.open(cacheName)
      .then(async cache => cache.put(url, response.clone()));
  };

  public static match = async (
    cacheName: string,
    url: string,
  ): Promise<Response | void> => {
    const cache = await global.caches?.open(cacheName);
    return await cache?.match(url);
  };
}
