import type {User} from '@emporos/hilo-auth';

export interface AuthState {
  user?: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  activeNavigator?:
    | 'signinRedirect'
    | 'signinPopup'
    | 'signinSilent'
    | 'signoutRedirect'
    | 'signoutPopup'
    | 'signinRefresh';
  error?: Error;
}

export const initialAuthState: AuthState = {
  isLoading: true,
  isAuthenticated: false,
};
