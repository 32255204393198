import {Intermission, StackProvider, theme} from '@emporos/components';
import {PMSStrategyProvider} from '@emporos/barcodes';
import {Redirect, Router} from '@reach/router';
import styled, {ThemeProvider} from 'styled-components';
import {
  AlertStateProvider,
  AuthenticationProvider,
  BarcodeScannerProvider,
  BetaFeatureProvider,
  CardReaderCredentialsProvider,
  CloseSessionContainer as CloseSession,
  CreateSessionContainer as CreateSession,
  CreateAccessCodeContainer as CreateAccessCode,
  CreditCardProcessingProvider,
  SessionDataProvider,
  GlobalStyles,
  NetworkAvailableProvider,
  Routes,
  SettingsProvider,
  SyncWarnings,
  TransactionsConfigProvider,
  TransactionsStateProvider,
  useTransactionsConfig,
  typedOidcConfiguration,
} from './';
import {ApiProvider} from './contexts/ApiProvider';
import {ConsoleLoggingProvider} from './contexts/ConsoleLoggingProvider';
import {GlobalDataProvider} from './contexts/GlobalDataProvider';
import {PaymentsWindowProvider} from './contexts/PaymentsWindowProvider';
import {OidcAuthProvider} from './contexts/OidcAuthProvider';
import {GlobalSettingsProvider} from './contexts/GlobalSettingsProvider';

const Background = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

function ConfigureOrLoadSession() {
  const {
    session,
    loading: sessionLoading,
    ready,
    hardLoadingSession,
    sessionClosed,
  } = useTransactionsConfig();

  if (!hardLoadingSession && (sessionLoading || !ready)) {
    return <Intermission />;
  }

  if (!session) {
    return sessionClosed ? (
      <Router>
        <CloseSession path="/login/close-session" />
        <Redirect from="/" to="/login/close-session" default noThrow />
      </Router>
    ) : (
      <Router>
        <CreateSession path="/login/create-session" />
        <CreateAccessCode path="/login/create-access-code" />
        <Redirect from="/" to="/login/create-session" default noThrow />
      </Router>
    );
  }

  return (
    <GlobalDataProvider>
      <TransactionsStateProvider>
        <PMSStrategyProvider>
          <SessionDataProvider>
            <SyncWarnings>
              <CardReaderCredentialsProvider>
                <BarcodeScannerProvider>
                  <CardReaderCredentialsProvider>
                    <CreditCardProcessingProvider>
                      <PaymentsWindowProvider>
                        <Routes />
                      </PaymentsWindowProvider>
                    </CreditCardProcessingProvider>
                  </CardReaderCredentialsProvider>
                </BarcodeScannerProvider>
              </CardReaderCredentialsProvider>
            </SyncWarnings>
          </SessionDataProvider>
        </PMSStrategyProvider>
      </TransactionsStateProvider>
    </GlobalDataProvider>
  );
}

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Background>
        <SettingsProvider>
          <NetworkAvailableProvider>
            <ConsoleLoggingProvider>
              <AlertStateProvider>
                <StackProvider>
                  <OidcAuthProvider {...typedOidcConfiguration()}>
                    <ApiProvider>
                      <AuthenticationProvider>
                        <GlobalSettingsProvider>
                          <TransactionsConfigProvider>
                            <BetaFeatureProvider>
                              <ConfigureOrLoadSession />
                            </BetaFeatureProvider>
                          </TransactionsConfigProvider>
                        </GlobalSettingsProvider>
                      </AuthenticationProvider>
                    </ApiProvider>
                  </OidcAuthProvider>
                </StackProvider>
              </AlertStateProvider>
            </ConsoleLoggingProvider>
          </NetworkAvailableProvider>
        </SettingsProvider>
      </Background>
    </ThemeProvider>
  );
}
