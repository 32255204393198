import {TransactionSignature} from '@emporos/api-enterprise';
import {
  OfflineSynced,
  OfflineTransaction,
  TransactionUpdates,
  mapSignatureImage,
  mapTransactionSignature,
  useSignatures,
  useTransaction,
} from '../';

export const mailoutSignaturePoints = [
  // M
  [
    {x: 10, y: 100},
    {x: 10, y: 10},
    {x: 17, y: 55},
    {x: 18, y: 55},
    {x: 25, y: 10},
    {x: 25, y: 100},
  ],
  // A
  [
    {x: 40, y: 100},
    {x: 47, y: 10},
    {x: 48, y: 10},
    {x: 55, y: 100},
  ],
  [
    {x: 44, y: 55},
    {x: 50, y: 55},
  ],
  // I
  [
    {x: 70, y: 10},
    {x: 70, y: 100},
  ],
  // L
  [
    {x: 85, y: 10},
    {x: 85, y: 100},
    {x: 100, y: 100},
  ],
  // O
  [
    {x: 115, y: 10},
    {x: 115, y: 100},
    {x: 130, y: 100},
    {x: 130, y: 10},
    {x: 115, y: 10},
  ],
  // U
  [
    {x: 145, y: 10},
    {x: 145, y: 100},
    {x: 160, y: 100},
    {x: 160, y: 10},
  ],
  // T
  [
    {x: 175, y: 10},
    {x: 175, y: 100},
  ],
  [
    {x: 168, y: 10},
    {x: 182, y: 10},
  ],
];

export const useMailoutSignature = (): TransactionUpdates => {
  const {transaction} = useTransaction();
  const signatureTypes = useSignatures();

  const _signatureImage = (transaction as OfflineTransaction).signatureImage;
  const signatureImage = _signatureImage
    ? _signatureImage
    : mapSignatureImage();

  return {
    signatureImage: {
      ...signatureImage,
      pointData: mailoutSignaturePoints.map(group =>
        group.map(({x, y}) => ({x, y})),
      ),
      isSynced: false,
    },
    signatures: transaction.signatures
      .map(
        s =>
          ({
            ...s,
            isDeleted: true,
            isSynced: false,
          } as TransactionSignature & OfflineSynced),
      )
      .concat(
        signatureTypes.map(type =>
          mapTransactionSignature(
            transaction.transactionId,
            false,
            type,
            signatureImage.signatureImageId,
          ),
        ),
      ),
  } as Partial<OfflineTransaction>;
};
