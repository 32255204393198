import {InMemoryWebStorage, User} from '@emporos/hilo-auth';
import {IntermissionThemed} from '../';
import {AuthProviderProps} from '../contexts/OidcAuthProvider';
import {EncryptedStateStore} from './EncryptedStateStore';

// not in use anymore but keeping it because it could be useful to reference behaviors and wording if the need arises
export const oidcCallbackComponents = {
  notAuthenticated: function NotAuthenticated(): JSX.Element {
    return <IntermissionThemed error="You are not logged in." />;
  },
  notAuthorized: function NotAuthorized(): JSX.Element {
    return (
      <IntermissionThemed error="You are not authorized to access this resource." />
    );
  },
  authenticating: function Authenticating(): JSX.Element {
    return <IntermissionThemed />;
  },
  callbackComponentOverride: function CallbackComponentOverride(): JSX.Element {
    return <IntermissionThemed />;
  },
  sessionLostComponent: function SessionLostComponent(): JSX.Element {
    return <IntermissionThemed error="Session lost, please log in again." />;
  },
};

const {OIDC_AUTHORITY, OIDC_CLIENT} = process.env;

export const typedOidcConfiguration = (): AuthProviderProps => {
  return {
    monitorSession: false,
    client_id: OIDC_CLIENT as string,
    redirect_uri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/signin-oidc`,
    response_type: 'code',
    post_logout_redirect_uri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/login`,
    scope: 'openid profile emp-v10 clientapi offline_access',
    authority: OIDC_AUTHORITY as string,
    silent_redirect_uri: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/authentication/silent_callback`,
    automaticSilentRenew: true,
    revokeTokensOnSignout: false,
    loadUserInfo: true,
    onSigninCallback: (_user: User | void): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    userStore: new EncryptedStateStore({
      prefix: 'oidc.',
      store:
        typeof window !== 'undefined'
          ? window.sessionStorage
          : new InMemoryWebStorage(),
    }),
  };
};
