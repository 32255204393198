import {navigate} from '@reach/router';
import {WindowService} from '../services/WindowService';

type useFeatureLogicProps = {
  setCurrentTransactionId?: (id: string) => void;
  logout?: () => void;
  clearSmsSent?: (transactionId: string) => Promise<void>;
  feature?: string;
  transactionId?: string;
  isNavigatingToPatientPayTransaction?: boolean;
};

export async function useFeatureLogic({
  setCurrentTransactionId,
  logout,
  clearSmsSent,
  feature,
  transactionId,
  isNavigatingToPatientPayTransaction: isNavigatingToPatientPayTransaction,
}: useFeatureLogicProps): Promise<string> {
  const windowService = new WindowService();
  const url = new URL(windowService.location().href);
  const transactionIdParam =
    typeof transactionId === 'string'
      ? transactionId
      : url.searchParams.get('transactionId');
  const featureParam =
    typeof feature === 'string' ? feature : url.searchParams.get('feature');

  if (typeof transactionIdParam === 'string') {
    url.searchParams.delete('transactionId');
    url.searchParams.append('transactionId', transactionIdParam);
  }

  if (typeof featureParam === 'string') {
    url.searchParams.delete('feature');
    url.searchParams.append('feature', featureParam);
  }

  if (transactionIdParam && setCurrentTransactionId) {
    if (featureParam !== 'sms-sent')
      setCurrentTransactionId(transactionIdParam);

    switch (featureParam) {
      case 'timeout':
      case 'logout':
        if (logout) {
          logout();
        }
        break;
      case 'customerInfo':
        url.pathname =
          '/sales/transactions/payments/customer-payment/customer-info';
        break;
      case 'deliver':
        url.pathname = '/sales/patient-pay-transactions';
        break;
      case 'addToQueue':
      case 'returnToTransaction':
        url.pathname = '/sales/transactions';
        break;
      case 'sms-sent':
        if (clearSmsSent) await clearSmsSent(transactionIdParam);
        url.pathname = '/sales';
        break;
      default:
        url.pathname = `/sales/${
          isNavigatingToPatientPayTransaction ? 'patient-pay-' : ''
        }transactions/payments/customer-payment`;
        break;
    }

    await navigate(`${url.pathname}${url.search}`, {
      replace: true,
    });
  } else if (featureParam && !transactionIdParam) {
    switch (featureParam) {
      case 'completed':
        await navigate('/sales/completed');
        break;
      case 'settings':
        await navigate('/sales/settings');
        break;
      case 'sales':
        await navigate('/sales');
        break;
      case 'timeout':
        if (logout) logout();
        break;
      default:
        break;
    }
  }

  return featureParam || '';
}
