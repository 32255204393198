import {
  Transaction,
  Session,
  PaymentType,
  TransactionPayment,
} from '@emporos/api-enterprise';
import {TransactionConsolidate, TransactionPaymentConsolidate} from '../api';
import {ITransactionService} from '../services/ITransactionService';
import {
  ITransactionDatabaseAccess,
  ITransactionPaymentsDatabaseAccess,
} from './IDatabaseAccess';
import {mapTransactionConsolidate} from '../utils';
import {TransactionLocaldb} from './TransactionsLocaldb';
import {ConsoleLogger, ConsoleLoggerVariant} from '../utils/console-logger';
import {
  NonSessionSyncResult,
  SyncResult,
  TransactionService,
} from '../services/TransactionService';
import {TransactionPaymentLocaldb} from './TransactionPaymentLocaldb';
import _ from 'lodash';
import {MutableRefObject} from 'react';
import {IAuthStorageService} from '../services/IAuthStorageService';
import {AuthLocalStorageService} from '../services/AuthLocalStorageService';
const devLogging = String(process.env.NODE_ENV).trim() === 'development';

export class sessionLocaldb {
  private _key!: string;
  private _token!: string | undefined;
  private _transactionDb!: ITransactionDatabaseAccess;
  private _transactionPaymentDb!: ITransactionPaymentsDatabaseAccess;
  private _sessionId!: string;
  private _transactionService!: ITransactionService;
  private _currentTransactionId!: string;
  private _accessCode!: string;
  private _userSub!: string;
  private _consoleLogger: ConsoleLogger = new ConsoleLogger();
  private _localStorageSvc!: IAuthStorageService;

  constructor(
    key: string,
    token: string | undefined,
    sessionId: string,
    currentTransactionId: string,
    accessCode: string,
    userSub: string,
    paymentTenders?: PaymentType[],
  ) {
    try {
      this._key = key;
      this._token = token;
      this._sessionId = sessionId;
      this._accessCode = accessCode;
      this._userSub = userSub;
      this._currentTransactionId = currentTransactionId;
      this._localStorageSvc = new AuthLocalStorageService();
      const transactiondb = new TransactionLocaldb();
      transactiondb.initialize(this._sessionId, this._accessCode);
      const transactionPaymentdb = new TransactionPaymentLocaldb();
      transactionPaymentdb.initialize(this._sessionId, this._accessCode);
      this._transactionDb = transactiondb;
      this._transactionPaymentDb = transactionPaymentdb;
      const service = new TransactionService();
      service.initialize(
        this._sessionId,
        this._token?.toString() || '',
        this._accessCode,
        this._userSub,
        paymentTenders,
      );
      this._transactionService = service;
    } catch (error) {
      this._consoleLogger.logError(error);
    }
  }

  resetToken(token: string): void {
    this._token = token;
    this._transactionService.resetToken(token);
  }

  async saveSession(
    value: Session | null,
    isOnline: boolean,
    CompleteTransaction?: boolean,
  ): Promise<void> {
    try {
      if (value === null) {
        if (devLogging) {
          window.localStorage.removeItem(this._key);
        }
      } else {
        const valueToStore = value instanceof Function ? value(value) : value;

        if (
          this._currentTransactionId === '0' ||
          this._currentTransactionId === ''
        ) {
          //New transaction has been added or new session loaded from cloud.
          for (let i = 0; i < valueToStore.transactions.length; i++) {
            const existingObjectDb = await this._transactionDb.get(
              valueToStore.transactions[i].transactionId,
            );

            if (existingObjectDb.serverTransactionID > 0) {
            } else {
              valueToStore.transactions[i].isSynced = isOnline;
              await this._transactionDb.add(valueToStore.transactions[i]);
            }
          }
        }

        if (this._currentTransactionId !== '0') {
          const existingObjectDb = await this._transactionDb.get(
            this._currentTransactionId,
          );
          let sessionObject = valueToStore.transactions.find(
            (n: {transactionId: string}) =>
              n.transactionId === this._currentTransactionId,
          ) as TransactionConsolidate;

          if (existingObjectDb.serverTransactionID > 0) {
            existingObjectDb.isDeleted = sessionObject.isDeleted;
            //maps items
            existingObjectDb.items = sessionObject.items;
            //maps payments
            existingObjectDb.payments = sessionObject.payments;
            //maps taxes
            existingObjectDb.taxes = sessionObject.taxes;
            //maps signature
            existingObjectDb.signatures = sessionObject.signatures;

            //preserve data version before map
            if (sessionObject.signatureImage) {
              const signatureImageDataVersion =
                existingObjectDb.signatureImage?.dataVersion;
              existingObjectDb.signatureImage = sessionObject.signatureImage;
              // eslint-disable-next-line
              sessionObject!.signatureImage!.dataVersion =
                signatureImageDataVersion;
            }
            //maps notes
            existingObjectDb.notes = sessionObject.notes;
            //maps customer
            if (sessionObject.customer?.id) {
              sessionObject.customerId = sessionObject.customer.id;
              existingObjectDb.customerId = sessionObject.customer.id;
            }
            existingObjectDb.customer = sessionObject.customer;

            const updatedDbObject = mapTransactionConsolidate(
              existingObjectDb,
              sessionObject,
            );

            updatedDbObject.isCompleted = CompleteTransaction;
            updatedDbObject.isSynced = isOnline;
            sessionObject.saleDate = new Date();
            updatedDbObject.saleDate = sessionObject.saleDate;
            await this._transactionDb.update(updatedDbObject);

            this._consoleLogger.styledLog(
              'sessionLocalDb - Transaction DB Updated:',
              ConsoleLoggerVariant.PURPLE,
              updatedDbObject,
            );

            try {
              if (isOnline) {
                const syncResult =
                  await this._transactionService.syncTransactions(
                    updatedDbObject.transactionId,
                  );
                if (syncResult.deletedTransactionPayments.length > 0)
                  sessionObject.payments = this.deleteTransactionPayments(
                    syncResult,
                    sessionObject,
                  );
                if (syncResult.updatedTransactionPayments.length > 0)
                  sessionObject.payments = this.updateTransactionPayments(
                    syncResult,
                    sessionObject,
                  );
              } else {
                for (const txPayment of updatedDbObject.payments) {
                  const dbPayment = await this._transactionPaymentDb.get(
                    txPayment.transactionPaymentId,
                  );
                  (txPayment as TransactionPaymentConsolidate).isSynced = false;
                  (txPayment as TransactionPaymentConsolidate).isDeleted =
                    updatedDbObject.isDeleted ||
                    (txPayment as TransactionPaymentConsolidate).isDeleted
                      ? true
                      : false;
                  (txPayment as TransactionPaymentConsolidate).siteId =
                    updatedDbObject.saleSiteID;
                  (txPayment as TransactionPaymentConsolidate).stationId =
                    updatedDbObject.station;
                  if (_.isEmpty(dbPayment)) {
                    await this._transactionPaymentDb.add(
                      txPayment as TransactionPaymentConsolidate,
                    );
                  } else {
                    await this._transactionPaymentDb.update(
                      txPayment as TransactionPaymentConsolidate,
                    );
                  }
                }
              }
              if (updatedDbObject.serverTransactionID > 0) {
                sessionObject = updatedDbObject as unknown as Transaction;

                if (updatedDbObject.isDeleted) {
                  if (isOnline) {
                    await this._transactionDb.delete(
                      updatedDbObject.transactionId,
                    );

                    this._consoleLogger.styledLog(
                      'sessionLocalDb - Deleted Transaction from Local DB:',
                      ConsoleLoggerVariant.PURPLE,
                      updatedDbObject,
                    );
                  } else {
                    await this._transactionDb.update(updatedDbObject);

                    this._consoleLogger.styledLog(
                      'sessionLocalDb - Updated Transaction from Local DB - will delete upon returning online and syncing:',
                      ConsoleLoggerVariant.PURPLE,
                      updatedDbObject,
                    );
                  }
                }
                for (let i = 0; i < valueToStore.transactions.length; i++) {
                  if (
                    valueToStore.transactions[i].transactionId ==
                    updatedDbObject.transactionId
                  ) {
                    valueToStore.transactions[i].serverTransactionID =
                      updatedDbObject.serverTransactionID;
                    valueToStore.transactions[i].status =
                      updatedDbObject.status;
                    valueToStore.transactions[i].isSynced = isOnline;
                  }
                }
              }
            } catch (error) {
              this._consoleLogger.logError(error);
            }
          } else {
            sessionObject.isSynced = isOnline;
            await this._transactionDb.add(sessionObject);
            if (isOnline) {
              const syncResult =
                await this._transactionService.syncTransactions(
                  sessionObject.transactionId,
                );
              if (syncResult.deletedTransactionPayments.length > 0)
                sessionObject.payments = this.deleteTransactionPayments(
                  syncResult,
                  sessionObject,
                );
            }
          }
        }
      }
    } catch (err) {
      this._consoleLogger.logError(err);
    }
  }

  deleteTransactionPayments(
    syncResult: SyncResult,
    transaction: TransactionConsolidate,
  ): TransactionPayment[] {
    return transaction.payments.filter(function (item) {
      return (
        syncResult.deletedTransactionPayments.indexOf(
          item.transactionPaymentId,
        ) === -1
      );
    });
  }

  updateTransactionPayments(
    syncResult: SyncResult,
    transaction: TransactionConsolidate,
  ): TransactionPayment[] {
    return transaction.payments.map(sessionPayment => {
      const updatedPayment = syncResult.updatedTransactionPayments.find(
        i2 => i2.transactionPaymentId === sessionPayment.transactionPaymentId,
      );
      return updatedPayment ? updatedPayment : sessionPayment;
    });
  }

  async syncTransactionsOffline(
    value: Session | null,
    syncing: MutableRefObject<boolean>,
  ): Promise<void> {
    // cannot have overlapping runs otherwise we get DbConcurrencyErrors from the BE
    if (syncing.current) return;
    syncing.current = true;

    // sync transactions that were updated/completed offline in the current session
    try {
      const valueToStore = value instanceof Function ? value(value) : value;

      const transactions = (await this._transactionDb.getAll()).filter(
        n => n.isSynced === false,
      );

      this._consoleLogger.styledLog(
        'sessionLocalDb - Saving Offline Session:',
        ConsoleLoggerVariant.PURPLE,
        transactions,
      );

      for (let i = 0; i < transactions.length; i++) {
        const syncedTransaction =
          await this._transactionService.syncTransactionsOffline(
            transactions[i].transactionId,
          );
        if (!syncedTransaction) continue;

        for (let j = 0; j < valueToStore.transactions.length; j++) {
          if (
            valueToStore.transactions[j].transactionId ==
            transactions[i].transactionId
          ) {
            valueToStore.transactions[j].serverTransactionID =
              syncedTransaction.serverTransactionID;
            valueToStore.transactions[j].status = syncedTransaction.status;
            valueToStore.transactions[j].isSynced = true;
            valueToStore.transactions[j].payments = syncedTransaction.payments;
          }
        }
      }
    } catch (error) {
      this._consoleLogger.logError(
        'Error syncing offline transactions:',
        error,
      );
    }

    // sync transactions that were updated/completed offline in other user sessions
    try {
      const processedTransactionResults: NonSessionSyncResult[] = [];
      const subsToRemove: string[] = [];
      const transactions =
        await this._transactionDb.getNonSessionUnsyncedEncryptedTransactions();

      this._consoleLogger.styledLog(
        'sessionLocalDb - Saving Offline Non-Session Transactions',
        ConsoleLoggerVariant.PURPLE,
      );

      for (let j = 0; j < transactions.length; j++) {
        // wrapped in a sub try-catch to ensure that we continue processing the rest of the transactions in the event of an error
        // also will not be added to the result list to be locally deleted so we can continue to try again with unsynced transactions
        try {
          const result =
            await this._transactionService.syncNonSessionTransactionOffline(
              transactions[j],
            );
          if (result) {
            processedTransactionResults.push(result);
          }
        } catch (error) {
          this._consoleLogger.logError(
            'Error Syncing Offline Non-Session Transaction: ',
            transactions[j].transactionId,
            error,
          );
        }
      }

      for (let j = 0; j < processedTransactionResults.length; j++) {
        if (processedTransactionResults[j].needsCompleted) {
          await this._transactionService.patchTransaction(
            processedTransactionResults[j].sessionId,
            processedTransactionResults[j].transaction,
            false,
          );
        }
        if (processedTransactionResults[j].needsDeletedLocally) {
          await this._transactionService.deleteTransactionAndPaymentsFromLocalDatabase(
            processedTransactionResults[j].transaction.transactionId,
          );
          if (!subsToRemove.includes(processedTransactionResults[j].userSub))
            subsToRemove.push(processedTransactionResults[j].userSub);
        }
      }

      for (let j = 0; j < subsToRemove.length; j++) {
        this._localStorageSvc.removeAuthInfoBySub(subsToRemove[j]);
      }
    } catch (error) {
      this._consoleLogger.logError(
        'Error Syncing Offline Non-Session Transactions:',
        error,
      );
    }

    syncing.current = false;
  }

  async deleteTransactionFromLocalDb(transactionId: string): Promise<void> {
    try {
      const payments =
        await this._transactionPaymentDb.getPaymentsForTransaction(
          transactionId,
        );

      payments.forEach(p => {
        this._transactionPaymentDb.delete(p.transactionPaymentId);
      });

      await this._transactionDb.delete(transactionId);
    } catch (error) {
      this._consoleLogger.logError(
        'There was an error deleting the transaction from the local db: ',
        error,
      );
    }
  }
}
