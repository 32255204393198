import {AsyncStorage, WebStorageStateStore} from '@emporos/hilo-auth';
import {getDeviceFingerprint} from '../utils';
import {decryptPrependedIV, encryptPrependIV} from '../utils/crypto';

interface UserCache {
  [key: string]: string;
}

export class EncryptedStateStore extends WebStorageStateStore {
  private readonly _encryptionKey: string;
  private _cachedUser: UserCache = {};

  public constructor({
    prefix = 'oidc.',
    store = localStorage,
  }: {prefix?: string; store?: AsyncStorage | Storage} = {}) {
    super({prefix, store});
    this._encryptionKey = getDeviceFingerprint();
    this._cachedUser = {};
  }

  public async set(key: string, value: string): Promise<void> {
    this._logger.create(`set('${key}')`);
    key = this._prefix + key;
    value = encryptPrependIV(value, this._encryptionKey);
    await this._store.setItem(key, value);
    this._cachedUser[key] = value;
  }

  public async get(key: string): Promise<string | null> {
    this._logger.create(`get('${key}')`);
    key = this._prefix + key;
    const item = await this._store.getItem(key);
    if (item) {
      const decrypted = decryptPrependedIV(item, this._encryptionKey);
      return decrypted;
    }

    const userItem = this._cachedUser[key];
    if (userItem) {
      const decrypted = decryptPrependedIV(userItem, this._encryptionKey);
      return decrypted;
    }

    return item;
  }
}
