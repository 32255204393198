import {User} from '@emporos/hilo-auth';
import {
  encryptPrependIV,
  decryptPrependedIV,
} from '@emporos/pos/src/utils/crypto';

export type StoredUser = {
  user: string;
  name: string;
  iat: number;
};

export type DisplayUser = StoredUser & {
  userKey: string;
  sessionKey: string;
};

export const generateStoredUser = (
  user: User,
  accessCode: string,
): StoredUser => {
  return {
    user: encryptPrependIV(user.toStorageString(), accessCode),
    name: user.profile[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
    ],
    iat: user.profile.iat,
  } as StoredUser;
};

export const generateStoredUserString = (
  user: User,
  accessCode: string,
): string => {
  const storedUser = generateStoredUser(user, accessCode);
  return JSON.stringify(storedUser);
};

export const parseStoredUser = (
  storedUserString: string,
  accessCode?: string,
): StoredUser | null => {
  try {
    const storedUser = JSON.parse(storedUserString) as StoredUser;

    // Try to decrypt the stored user string if an AC was passed in
    if (accessCode) {
      const decryptedUser = decryptPrependedIV(storedUser.user, accessCode);
      storedUser.user = decryptedUser;
    }

    return storedUser;
  } catch (error) {
    return null;
  }
};
